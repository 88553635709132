<div class="auth-layout d-flex">
    <aside>
        <div class="sidebar">
            <div class="sidebar-body">
                <div *ngIf="loadingService.loading">
                    <ngx-skeleton-loader appearance="circle" [theme]="{
                        height: '80px',
                        width: '200px',
                        'border-radius': '10px'
                      }">
                    </ngx-skeleton-loader>
                    <app-spacer [bottom]="56"></app-spacer>

                    <div class="sidebar-body-welcome">
                        <ngx-skeleton-loader [theme]="{
                            height: '50px'
                          }">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </div>

                    <app-spacer [bottom]="56"></app-spacer>

                    <ngx-skeleton-loader [theme]="{
                        height: '50px'
                    }">
                    </ngx-skeleton-loader>

                    <app-spacer [bottom]="8"></app-spacer>

                    <ngx-skeleton-loader></ngx-skeleton-loader>

                    <app-spacer [bottom]="32"></app-spacer>

                    <ngx-skeleton-loader [theme]="{
                        height: '50px'
                    }">
                    </ngx-skeleton-loader>
                </div>

                <div *ngIf="!loadingService.loading">
                    <img class="img-fluid"
                        [src]="UIDefaultTheme.brand.logoHorizontal">

                    <app-spacer [bottom]="56"></app-spacer>

                    <div class="sidebar-body-welcome">
                        <h2>Bem vindo</h2>
                        <p>Informe seus dados para continuar...</p>
                    </div>

                    <app-spacer [bottom]="56"></app-spacer>

                    <div>
                        <form [formGroup]="form">
                            <app-masked-input label="Informe seu CPF"
                                [group]="form" controlName="username"
                                placeholder="Ex.: 123.456.789-00"
                                errorText="Verifique se seu CPF está correto"
                                mask="000.000.000-00"
                                autofocus></app-masked-input>

                            <app-spacer [bottom]="32"></app-spacer>


                            <app-button [showContent]="true" [full]="true"
                                [class.d-none]="valid"
                                [disabled]="form.invalid || loading"
                                [loading]="loading"
                                (onClick)="validateUsername()" [center]="true">
                                Continuar...
                            </app-button>
                            <app-spacer [bottom]="22"></app-spacer>
                        </form>
                    </div>

                    <ng-container *ngIf="valid">
                        <form [formGroup]="passwordForm">

                            <app-input label="Insira sua senha" type="password"
                                [group]="passwordForm" controlName="password"
                                placeholder="Mínimo de 8 caracteres"
                                errorText="Tente novamente com uma senha válida"
                                [button]="'Esqueceu sua senha?'"
                                (onButtonClick)="openModal()" autofocus>
                            </app-input>

                            <app-spacer [bottom]="20"></app-spacer>

                            <app-button tabindex="0" [showContent]="true"
                                [full]="true"
                                [disabled]="passwordForm.invalid || form.invalid || loading"
                                [loading]="loading"
                                (onClick)="validatePassword()" [center]="true">
                                Acessar minha conta
                            </app-button>
                        </form>
                    </ng-container>

                    <!-- <app-button (onClick)="rapidAccess()" [center]="true">
                        entrar direto
                    </app-button> -->
                </div>
            </div>

            <div class="sidebar-footer">
                <ng-container *ngIf="loadingService.loading">
                    <ngx-skeleton-loader appearance="circle" [theme]="{
                    height: '80px',
                    width: '200px',
                    'border-radius': '10px'
                  }">
                    </ngx-skeleton-loader>
                </ng-container>

                <ng-container *ngIf="!loadingService.loading">
                    <span>
                        Powered by Prosperità Inovação e Tecnologia Ltda ©
                        2018 - 2022
                    </span><br>
                    <span>Versão
                        <a class="branch" *ngIf="debugForm"
                            [href]="gitInfo.url">{{gitInfo.branch}} -
                            {{gitInfo.commit}} - {{gitInfo.date}}</a>
                        <span class="version"
                            *ngIf="!debugForm">{{gitInfo.commit}}</span>
                    </span>
                </ng-container>
            </div>


        </div>
    </aside>

    <div class="login-background">
    </div>
</div>

<app-warning-modal [active]="forgotModal" (onClose)="closeModal()">
    <app-forgot (onForgotClose)="closeModal()"></app-forgot>
</app-warning-modal>

<app-basic-modal *ngIf="codeModal">
    <app-validate (onDigitsValidated)="onValidateCode($event)"></app-validate>
</app-basic-modal>