import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import fakerbr from 'faker-br';

import { MaskPipe } from 'ngx-mask';
import DDIOptions from 'src/app/data/ddi';
import Utils, { GetHelper, UtilsValidators } from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import {
  BasicInfo,
  SignupService,
} from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-signup-unified-person-complementary-general',
  templateUrl: './signup-unified-person-complementary-general.component.html',
  styleUrls: ['./signup-unified-person-complementary-general.component.scss'],
})
export class SignupUnifiedPersonComplementaryGeneralComponent
  implements OnInit, OnDestroy
{
  @Input() userRole: string;

  DDIOptions = DDIOptions;

  form = this.formBuilder.group({
    document: [null, [Validators.required, UtilsValidators.cpf]],
    full_name: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    short_name: [null, [Validators.required]],
    interest_fund: [null, []],

    birth_date: [
      null,
      [Validators.required, Utils.validateMinDate, Utils.validateLegalAge],
    ],
    marital_status: [null, []],
    joint_obligation: [false, []],
    gender: [null, [Validators.required]],
    revenues: [0, [Validators.required, Validators.min(1)]],
    mother_name: [null, [Validators.required]],
    father_name: [null, []],

    spouse_document: [null, []],
    spouse_name: [null, []],
    marriage_bond: [null, []],

    naturalness: [null, [Validators.required]],

    type: [null, [Validators.required]],
    rg: [null, [Validators.required]],
    dispatching_agency: [null, [Validators.required]],
    uf: [null, [Validators.required]],
    expedition_date: [
      null,
      [Validators.required, Utils.validateMaxMinToday, Utils.validateMinDate],
    ],

    cellphone_ddi: ['55', []],
    cellphone_number: [
      null,
      [
        Validators.required,
        Utils.validateWhenNotEmpty(UtilsValidators.celular),
      ],
    ],
    telephone_ddi: ['55', []],
    telephone_number: [
      null,
      [Utils.validateWhenNotEmpty(UtilsValidators.telefone)],
    ],

    site: [null, []],
    authorization_bacen_queries: [false, []],

    relationship_with_other_institutions: [false, []],
    institution_relationship: [null, []],
    pep_relationship: [false, []],
    pepRelationshipDocument: [null, []],
    pepRelationshipName: [null, []],
    pepBond: [null, []],
  });

  today = Utils.todayString();

  isForeign = false;

  items = [
    { value: '1', label: 'Sim' },
    { value: '2', label: 'Não' },
  ];

  haveBasicInfo: boolean = false;

  invitedInfo: BasicInfo = {
    document: null,
    name: null,
    email: null,
  };

  data = null;

  event: any;
  foundPerson = false;
  foundSpouse = false;
  foundRelationPerson = false;

  fundOptions: SelectItem[] = [];

  loadingContent = false;

  constructor(
    private formBuilder: FormBuilder,
    public signupService: SignupService,
    private maskPipe: MaskPipe,
    private authService: AuthService,
    private api: ApiService,
    private toast: ToastService
  ) {}

  ngOnDestroy(): void {
    this.event.unsubscribe();
  }

  ngOnInit(): void {
    this.getDataAndFillForm();
    this.getFunds();

    this.form.controls.pepRelationshipDocument.valueChanges.subscribe(
      async (value) => {
        if (!value) {
          this.foundRelationPerson = false;
        }

        const document = Utils.onlyNumbers(value);

        if (document.length === 11) {
          const sameDocument =
            Utils.onlyNumbers(this.form.controls.document.value) === document;

          if (sameDocument) {
            this.form.controls.pepRelationshipDocument.setErrors({
              sameDocument: true,
            });
          } else {
            this.form.controls.document.setErrors(null);
            this.form.controls.pepRelationshipDocument.setErrors(null);
          }

          const { name, foundPerson } = await this.api.getPersonName(value);

          this.foundRelationPerson = foundPerson;

          if (name) this.form.controls.pepRelationshipName.setValue(name);
        }
      }
    );

    this.form.controls.spouse_document.valueChanges.subscribe(async (value) => {
      if (!value) {
        this.foundSpouse = false;
      }

      if (value && value.length === 14) {
        const { name, foundPerson } = await this.api.getPersonName(value);

        this.foundSpouse = foundPerson;

        if (name) this.form.controls.spouse_name.setValue(name);
      }
    });

    this.form.controls.document.valueChanges.subscribe(async (value) => {
      if (!value) {
        this.foundPerson = false;
      }

      const document = Utils.onlyNumbers(value);
      if (document.length === 11) {
        const { name, foundPerson } = await this.api.getPersonName(value);
        this.foundPerson = foundPerson;

        if (name) this.form.controls.full_name.setValue(name);
      }
    });

    this.form.controls.marital_status.valueChanges.subscribe(() => {
      this.setMarried();
    });

    this.event = this.signupService.sendPersonData.subscribe((value) => {
      if (value === 'complementaryGeneral') {
        this.sendData();
      }
    });

    if (this.userRole !== 'provider') {
      this.form.controls.relationship_with_other_institutions.valueChanges.subscribe(
        (values) => {
          if (values) {
            this.form.controls.institution_relationship.setValidators([
              Validators.required,
            ]);
          } else {
            this.form.controls.institution_relationship.setValidators([]);
          }
          this.form.controls.institution_relationship.updateValueAndValidity();
        }
      );
    }

    if (this.signupService.basicInfo !== null) {
      this.haveBasicInfo = true;

      this.invitedInfo = this.signupService.basicInfo;

      this.form.patchValue({
        document: this.invitedInfo.document ?? null,
        full_name: this.invitedInfo.name ?? null,
        email: this.invitedInfo.email,
      });
    }

    this.form.valueChanges.subscribe(() => {
      Utils.getErrors(this.form);

      this.signupService.setPersonFormStatus(
        'complementaryGeneral',
        this.form.valid
      );
    });

    this.form.controls.birth_date.valueChanges.subscribe((value) => {
      this.form.controls.expedition_date.setValidators([
        Validators.required,
        Utils.validateMaxMinToday,
        Utils.validateMinDate,
        (control: FormControl) => {
          if (!control.value || !value) return null;

          const birthDateArr = value.split('/');

          const birthDateDay = birthDateArr[0];
          const birthDateMonth = birthDateArr[1];
          const birthDateYear = birthDateArr[2];

          const birthDate = new Date(
            birthDateYear,
            birthDateMonth - 1,
            birthDateDay
          );

          const expedition_dateArr = control.value.split('/');

          const shippingDateDay = expedition_dateArr[0];
          const shippingDateMonth = expedition_dateArr[1];
          const shippingDateYear = expedition_dateArr[2];

          const shippingDate = new Date(
            shippingDateYear,
            shippingDateMonth - 1,
            shippingDateDay
          );

          if (shippingDate < birthDate) {
            return { minDate: true };
          }

          return null;
        },
      ]);

      this.form.controls.expedition_date.updateValueAndValidity();
    });

    this.signupService.fillFormEvent.subscribe(() => {
      const pepRelationship = fakerbr.random.boolean();

      const haveRelation = fakerbr.random.boolean();

      const birthdate_date = new Date();
      birthdate_date.setFullYear(birthdate_date.getFullYear() - 25);

      this.form.patchValue({
        full_name: this.invitedInfo.name
          ? this.form.controls.full_name.value
          : fakerbr.name.findName(undefined, undefined, 'male'),
        short_name: fakerbr.name.findName(undefined, undefined, 'male'),
        document: this.invitedInfo.document
          ? this.form.controls.document.value
          : this.maskPipe.transform(fakerbr.br.cpf(), '000.000.000-00'),
        birth_date: birthdate_date.toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        email: this.invitedInfo.email
          ? this.form.controls.email.value
          : fakerbr.internet.email(),
        marital_status: '2',
        joint_obligation: false,
        gender: '1',
        spouse_document: this.maskPipe.transform(
          fakerbr.br.cpf(),
          '000.000.000-00'
        ),
        spouse_name: fakerbr.name.findName(undefined, undefined, 'female'),
        marriage_bond: '1',
        revenues: fakerbr.random.number({ min: 500000, max: 2000000 }),
        naturalness: 'São Paulo',
        type: '1',
        mother_name: fakerbr.name.findName(undefined, undefined, 'female'),
        father_name: fakerbr.name.findName(undefined, undefined, 'male'),
        rg: fakerbr.br.rg(),

        dispatching_agency: 'SSP - Secretaria de Segurança Pública',
        uf: 'SP',
        expedition_date: fakerbr.date.past(8).toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),

        cellphone_ddi: '55',
        cellphone_number: this.maskPipe.transform(
          '139' +
            String(fakerbr.random.number({ min: 11111111, max: 99999999 })),
          '(00) 00000-0000'
        ),
        telephone_ddi: '55',
        telephone_number: this.maskPipe.transform(
          '139' + String(fakerbr.random.number({ min: 1111111, max: 9999999 })),
          '(00) 0000-0000'
        ),

        site: fakerbr.internet.url(),

        authorization_bacen_queries: true,
      });

      if (this.userRole !== 'provider') {
        this.form.patchValue({
          pep_relationship: pepRelationship,
          pepRelationshipDocument: pepRelationship
            ? this.maskPipe.transform(fakerbr.br.cpf(), '000.000.000-00')
            : null,
          pepRelationshipName: pepRelationship ? fakerbr.name.findName() : null,
          pepBond: pepRelationship ? 'Sócio' : null,
          relationship_with_other_institutions: haveRelation,
          institution_relationship: haveRelation
            ? fakerbr.company.companyName()
            : '',
        });
      }
    });

    if (this.userRole !== 'provider') {
      this.form.controls.authorization_bacen_queries.setValidators([
        Validators.requiredTrue,
      ]);

      this.form.controls.authorization_bacen_queries.updateValueAndValidity();

      this.form.controls.pep_relationship.valueChanges.subscribe(() =>
        this.verifyPepRelationshipFields()
      );
    }
  }

  verifyPepRelationshipFields() {
    if (this.form.controls.pep_relationship.value) {
      this.form.controls.pepRelationshipDocument.setValidators([
        Validators.required,
        UtilsValidators.cpf,
      ]);
      this.form.controls.pepRelationshipName.setValidators([
        Validators.required,
      ]);
      this.form.controls.pepBond.setValidators([Validators.required]);
    } else {
      this.form.controls.pepRelationshipDocument.setValidators([]);
      this.form.controls.pepRelationshipName.setValidators([]);
      this.form.controls.pepBond.setValidators([]);
    }

    this.form.controls.pepRelationshipDocument.updateValueAndValidity();
    this.form.controls.pepRelationshipName.updateValueAndValidity();
    this.form.controls.pepBond.updateValueAndValidity();
  }

  setMarried() {
    if (
      this.form.controls['marital_status'].value === '2' ||
      this.form.controls['marital_status'].value === '3'
    ) {
      this.form.controls['spouse_name'].setValidators([Validators.required]);
      this.form.controls['spouse_document'].setValidators([
        Validators.required,
        UtilsValidators.cpf,
        (control: FormControl) => {
          const removeSpecialCharacters = control.value
            ? control.value.replace(/[^0-9]/g, '')
            : '';
          const userDocument =
            this.form.controls['document'].value &&
            this.form.controls['document'].value.replace(/[^0-9]/g, '');
          return userDocument === removeSpecialCharacters
            ? { invalid: true }
            : null;
        },
      ]);
      this.form.controls['marriage_bond'].setValidators([Validators.required]);
    } else {
      this.form.controls['spouse_name'].setValidators([]);
      this.form.controls['spouse_document'].setValidators([]);
      this.form.controls['marriage_bond'].setValidators([]);
    }

    this.form.controls.spouse_name.updateValueAndValidity();
    this.form.controls.spouse_document.updateValueAndValidity();
    this.form.controls.marriage_bond.updateValueAndValidity();
  }

  async getDataAndFillForm() {
    this.loadingContent = true;

    try {
      const { data } = await this.api.get<ApiResponse<Person>>({
        route: 'api/registration/pf_general/me/',
        token: true,
      });

      let parsedData: Partial<PersonComplementaryGeneralValues> = {
        ...data,
        birth_date: data.birth_date.split('-').reverse().join('/'),
        type: String(data.secondary_document.type.id),
        rg: data.secondary_document.number,
        dispatching_agency: data.secondary_document.dispatching_agency,
        uf: data.secondary_document.uf,
        expedition_date: data.secondary_document.expedition_date
          .split('-')
          .reverse()
          .join('/'),
        document: this.maskPipe.transform(
          data.document.number,
          '000.000.000-00'
        ),

        gender: String(data.gender),
        marital_status: String(data.marital_status),
        cellphone_number: this.maskPipe.transform(
          data.cellphone.number,
          '(00) 00000-0000'
        ),
        joint_obligation:
          data.assignor && data.assignor.joint_obligation === 'true',
        cellphone_ddi: String(data.cellphone.code),
        telephone_number: data.phone
          ? this.maskPipe.transform(data.phone.number, '(00) 0000-0000')
          : null,
        telephone_ddi: data.phone ? String(data.phone.code) : '55',
        pep_relationship: data.pep_relationship ? true : false,
        authorization_bacen_queries: data !== null,
      };

      if (data.pep_relationship) {
        parsedData.pepRelationshipDocument = data.pep_relationship.document;
        parsedData.pepRelationshipName = data.pep_relationship.name;
        parsedData.pepBond = data.pep_relationship.bound;
      }

      if (data.spouse) {
        parsedData.spouse_name = data.spouse.name;
        parsedData.spouse_document = this.maskPipe.transform(
          data.spouse.document,
          '000.000.000-00'
        );
        parsedData.marriage_bond = String(data.spouse.marriage_bond);
      }

      this.form.patchValue(parsedData);

      this.data = data;
    } catch (error) {
      if (error.status !== 404) {
        console.log(error);
        this.toast.show('error', 'Erro', error.error.message);
      }
    }

    this.loadingContent = false;
  }

  async sendData() {
    this.signupService.loading = true;

    try {
      if (this.form.invalid) {
        this.toast.show(
          'error',
          'Erro',
          'Preencha todos os campos obrigatórios'
        );
        return;
      }

      const valuesHelper = new GetHelper(this.form.value);

      const payload = {
        document: {
          number: Utils.onlyNumbers(valuesHelper.get('document')),
          type: 'CPF',
        },
        full_name: valuesHelper.get('full_name'),
        email: valuesHelper.get('email'),
        birth_date: valuesHelper
          .get('birth_date')
          .split('/')
          .reverse()
          .join('-'),
        naturalness: valuesHelper.get('naturalness'),
        gender: valuesHelper.get('gender'),
        marital_status: valuesHelper.get('marital_status'),
        mother_name: valuesHelper.get('mother_name'),
        father_name: valuesHelper.get('father_name'),
        secondary_document: {
          number: valuesHelper.get('rg'),
          type: valuesHelper.get('type'),
          dispatching_agency: valuesHelper.get('dispatching_agency'),
          uf: valuesHelper.get('uf'),
          expedition_date: valuesHelper
            .get('expedition_date')
            .split('/')
            .reverse()
            .join('-'),
        },

        revenues: valuesHelper.get('revenues'),
        site: valuesHelper.get('site'),
        institution_relationship: valuesHelper.get('institution_relationship'),
      };

      if (
        this.form.controls['marital_status'].value === '2' ||
        this.form.controls['marital_status'].value === '3'
      ) {
        payload['spouse'] = {
          document: valuesHelper.get('spouse_document'),
          marriage_bond: valuesHelper.get('marriage_bond'),
          name: valuesHelper.get('spouse_name'),
        };
      }

      if (valuesHelper.get('pep_relationship')) {
        payload['pep_relationship'] = {
          name: valuesHelper.get('pepRelationshipName'),
          document: valuesHelper.get('pepRelationshipDocument'),
          bound: valuesHelper.get('pepBond'),
        };
      }

      if (
        valuesHelper.get('telephone_number') !== '' &&
        valuesHelper.get('telephone_number') !== null
      ) {
        payload['phone'] = {
          type: 'residential',
          code: Number(valuesHelper.get('telephone_ddi')),
          number:
            valuesHelper.get('telephone_number') !== ''
              ? Utils.onlyNumbers(valuesHelper.get('telephone_number'))
              : null,
          branch: 0,
        };
      }

      if (
        valuesHelper.get('cellphone_number') !== '' &&
        valuesHelper.get('cellphone_number') !== null
      ) {
        payload['cellphone'] = {
          type: 'phone',
          code: Number(valuesHelper.get('cellphone_ddi')),
          number: Utils.onlyNumbers(valuesHelper.get('cellphone_number')),
          branch: 0,
        };
      }

      if (
        this.authService.user.active_register.register.role.applicable ===
        'assignor'
      ) {
        payload['assignor'] = {
          authorization_bacen_queries: valuesHelper.get(
            'authorization_bacen_queries'
          ),
          relationship_with_other_institutions: valuesHelper.get(
            'relationship_with_other_institutions'
          ),
          relationship_institution: valuesHelper.get(
            'relationship_institution'
          ),
          joint_obligation: String(valuesHelper.get('joint_obligation')),
        };
      }

      if (this.data) {
        const { data } = await this.api.put<ApiResponse<Person>>({
          route: 'api/registration/pf_general/me/',
          token: true,
          body: payload,
        });
        this.data = data;
      } else {
        const { data } = await this.api.post<ApiResponse<Person>>({
          route: 'api/registration/pf_general/',
          token: true,
          body: payload,
        });

        this.data = data;
      }

      this.toast.show('info', 'Sucesso', 'Dados salvos com sucesso!');

      this.signupService.changeBasicInfoEvent.emit(payload.full_name);

      this.signupService.setPersonStep('complementaryAddress');
      this.signupService.changePersonStepEvent.emit('address');
    } catch (error) {
      console.error(error);
      this.toast.show(
        'info',
        'Erro',
        'Ocorreu um erro ao salvar os dados, tente novamente mais tarde!'
      );
    }

    this.signupService.loading = false;
  }

  async getFunds() {
    try {
      const res = await this.api.get({
        route: 'api/registration/fund',
        token: false,
      });

      const filteredArr = Utils.filterRepeatedValues(res.funds, 'id');

      const funds = filteredArr.map((fund: any) => {
        return {
          label: fund.name,
          value: fund.id,
        };
      });

      this.fundOptions = funds;
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao carregar fundos de interesse');
    }
  }
}
