<ng-container *ngIf="loading">
    <div class="row">
        <div class="col-10">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
    </div>

    <div class="row">
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
    </div>
</ng-container>

<form [formGroup]="form" *ngIf="!loading && !provider">
    <div class="row">
        <div class="col-12 col-lg-10">
            <app-select [items]="data" label="Selecione o gestor do fundo"
                [group]="form" controlName="manager">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-6">
            <app-button (onClick)="sendData()" [loading]="sending"
                [disabled]="getDisabled()" class="add-button" [outline]="true">
                <i class="fas fa-plus-circle mr-2"></i>
                Adicionar gestor
            </app-button>
        </div>
    </div>
</form>


<ng-container *ngIf="!loading && provider">
    <div class="row">
        <div class="col-12 mb-4">
            <h5>Gestor do fundo</h5>
            <app-spacer [bottom]="8"></app-spacer>
            <p>{{provider.label}}</p>
            <small>{{formatDocument(provider.extra)}}</small>
        </div>
        <div class="col-12">
            <app-button (onClick)="removeProvider()" [outline]="true">
                <i class="fas fa-trash mr-2"></i>
                Remover gestor
            </app-button>
        </div>
    </div>

    <app-spacer [bottom]="48"></app-spacer>

    <div class="row">
        <div class="col-12">
            <h5>Representantes</h5>
            <hr />
        </div>
        <div class="col-12">
            <ng-container *ngIf="representativesData.length > 0">
                <app-table-list [columns]="columns"
                    [data]="representativesData"></app-table-list>
            </ng-container>
        </div>
    </div>
    <app-spacer [bottom]="24"></app-spacer>

    <div class="row">
        <div class="col-12">
            <h5>Grupos</h5>
            <hr />
        </div>
        <div class="col-12">
            <app-table-list [columns]="groupColumns"
                [data]="groupData"></app-table-list>
        </div>
    </div>
    <app-spacer [bottom]="24"></app-spacer>

    <div class="row">
        <div class="col-12">
            <h5>Poderes</h5>
            <hr />
        </div>
        <div class="col-12">
            <app-table-list [columns]="powerGroupColumns"
                [data]="powerGroupData">
            </app-table-list>
        </div>
    </div>
</ng-container>

<app-side-modal [active]="powerGroupDetails" title="Detalhes do grupo de poder"
    (onClose)="closeModal()" paddingBottom="30">
    <div class="container">
        <div class="row" *ngIf="selectedPowerGroup">
            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Tipo de poder
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedPowerGroup.powers.join(', ')}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <!-- <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Poder padrão
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedPowerGroup.default_power_type &&
                    selectedPowerGroup.default_power_type.length > 0?
                    formatPower(selectedPowerGroup.default_power_type[0].power_type[0],
                    true):'-'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div> -->

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Grupo principal
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedPowerGroup.main_group}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Grupo secundário
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedPowerGroup.secondary_group ?
                    selectedPowerGroup.secondary_group.join(', ') : '-'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Qt. mín. de assinaturas necessárias
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedPowerGroup.necessary_signs_quantity}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Assinantes necessários
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedPowerGroup.necessary_signs.join(', ')}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Qt. mín. de assinaturas obrigatórias
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedPowerGroup.required_signs_quantity}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Assinantes obrigatórios
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedPowerGroup.required_signs.join(', ')}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column"
                *ngIf="selectedPowerGroup && selectedPowerGroup.min_sign_amount">
                <span class="dash-details-card-item-subtitle">
                    Valor min. para assinatura
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedPowerGroup.min_sign_amount | currency:'BRL'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column"
                *ngIf="selectedPowerGroup && selectedPowerGroup.min_sign_amount">
                <span class="dash-details-card-item-subtitle">
                    Valor máx. para assinatura
                </span>

                <span class="dash-details-card-item-title">
                    {{formatMaxValue(selectedPowerGroup)}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column"
                *ngIf="selectedPowerGroup && selectedPowerGroup.description">
                <span class="dash-details-card-item-subtitle">
                    Descrição dos poderes
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedPowerGroup.description}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>
    </div>
</app-side-modal>

<app-side-modal [active]="representativeDetails"
    [title]="selectedRepresentative?.full_name ?? '-'" (onClose)="closeModal()"
    paddingBottom="30">
    <div class="container" *ngIf="selectedRepresentative">
        <div class="row">
            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    cpf
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedRepresentative.document_number ?
                    formatCpf(selectedRepresentative.document_number) : '-'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-badged"
                    *ngIf="selectedRepresentative.pep">
                    Declarado PEP
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Avalista
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedRepresentative.guarantor ?
                    'Sim' : 'Não'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Devedor solidário
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedRepresentative.solidarity_debtor
                    ? 'Sim' : 'Não'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <ng-container *ngIf="!isContribuitor">
                <div class="col-12 d-flex flex-column"
                    *ngIf="selectedRepresentative.birth_date">
                    <span class="dash-details-card-item-subtitle">
                        DATA DE NASCIMENTO
                    </span>

                    <span class="dash-details-card-item-title">
                        {{formatDate(selectedRepresentative.birth_date)}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <ng-container *ngIf="selectedRepresentative.secondary_document">
                    <div div class="col-12 col-md-6 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            TIPO DE DOCUMENTO
                        </span>

                        <span class="dash-details-card-item-title">
                            {{
                            selectedRepresentative.secondary_document.type
                            }}
                        </span>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>

                    <div class="col-12 col-md-6 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            DOCUMENTO
                        </span>

                        <span class="dash-details-card-item-title">
                            {{selectedRepresentative.secondary_document.number}}
                        </span>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>

                    <div class="col-12 col-md-6 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            ÓRGÃO EXPEDITOR
                        </span>

                        <span class="dash-details-card-item-title">
                            {{selectedRepresentative.secondary_document.uf}}/
                            {{selectedRepresentative.secondary_document.dispatching_agency}}
                        </span>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>

                    <div class="col-12 col-md-6 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            DATA DE EXPEDIÇÃO
                        </span>

                        <span class="dash-details-card-item-title">
                            {{selectedRepresentative.secondary_document.expedition_date
                            |
                            date: 'dd/MM/yyyy'}}
                        </span>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>
                </ng-container>

                <div class="col-12 d-flex flex-column"
                    *ngIf="selectedRepresentative.occupation">
                    <span class="dash-details-card-item-subtitle">
                        Ocupação profissional
                    </span>

                    <span class="dash-details-card-item-title">
                        {{
                        selectedRepresentative.occupation
                        }}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column"
                    *ngIf="selectedRepresentative.naturalness">
                    <span class="dash-details-card-item-subtitle">
                        NACIONALIDADE
                    </span>

                    <span class="dash-details-card-item-title">
                        {{
                        getNacionality(selectedRepresentative.naturalness)?.label
                        }}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <ng-container *ngIf="selectedRepresentative.naturalness_state">
                    <div class="col-12 col-md-6 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            NATURALIDADE
                        </span>

                        <span class="dash-details-card-item-title">
                            <ng-container
                                *ngIf="selectedRepresentative.naturalness_city">
                                {{selectedRepresentative.naturalness_city.name}}
                                /
                            </ng-container>
                            {{selectedRepresentative.naturalness_state.name}}
                        </span>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>
                </ng-container>

                <div class="col-12 d-flex flex-column"
                    [class.col-md-6]="selectedRepresentative.spouse"
                    *ngIf="selectedRepresentative.marital_status_display">
                    <span class="dash-details-card-item-subtitle">
                        estado civil
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedRepresentative.marital_status_display}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </ng-container>


            <ng-container *ngIf="selectedRepresentative.spouse">
                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        regime de casamento
                    </span>

                    <span class="dash-details-card-item-title">
                        {{getChoicesName('marital_statutes',selectedRepresentative.spouse.marriage_bond)?.label
                        ?? '-'}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        cpf do cônjuge
                    </span>

                    <span class="dash-details-card-item-title">
                        {{formatCpf(selectedRepresentative.spouse.document)}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        nome do cônjuge
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedRepresentative.spouse.name}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        Possui outorga uxória
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedRepresentative.spousal_consent
                        ? 'Sim' : 'Não'}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </ng-container>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    celular
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedRepresentative.phone
                    ?
                    formatNumber(selectedRepresentative.phone.number)
                    : '-'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>


            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    e-mail
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedRepresentative.email
                    ??
                    '-'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    início do mandato
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedRepresentative.min_term_validity ?
                    formatDate(selectedRepresentative.min_term_validity) :
                    '-'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    validade do mandato
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedRepresentative.expiration_date ?
                    formatDate(selectedRepresentative.expiration_date) :
                    'indeterminado'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <ng-container *ngIf="!isContribuitor">
                <div class="col-12 col-md-6 d-flex flex-column"
                    *ngIf="selectedRepresentative.address">
                    <span class="dash-details-card-item-subtitle">
                        endereço completo
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedRepresentative.address
                        ?
                        formatAddress(selectedRepresentative.address)
                        : '-'}}<br>
                        {{selectedRepresentative.address
                        ?
                        selectedRepresentative.address.cep
                        :
                        '-'}}
                    </span>
                    <app-spacer [bottom]="32"></app-spacer>
                </div>
            </ng-container>
        </div>
    </div>
</app-side-modal>