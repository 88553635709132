import { Component, OnInit } from '@angular/core';
import { FundService } from 'src/app/services/fund/fund.service';

@Component({
  selector: 'app-dashboard-fund-approval-transfers',
  templateUrl: './dashboard-fund-approval-transfers.component.html',
  styleUrls: ['./dashboard-fund-approval-transfers.component.scss'],
})
export class DashboardFundApprovalTransfersComponent implements OnInit {
  constructor(public fundService: FundService) {}

  ngOnInit(): void {}

  formatPhone(ddi: string, number: string) {
    return `+${ddi} ${number}`;
  }
}
