import { Component, OnInit } from '@angular/core';
import { FundService } from 'src/app/services/fund/fund.service';

@Component({
  selector: 'app-dashboard-fund-approval-complementary',
  templateUrl: './dashboard-fund-approval-complementary.component.html',
  styleUrls: ['./dashboard-fund-approval-complementary.component.scss'],
})
export class DashboardFundApprovalComplementaryComponent implements OnInit {
  constructor(public fundService: FundService) {}

  creditors_rights_items = [
    { value: '0', label: 'Escriturais' },
    { value: '1', label: 'Físicos' },
    { value: '2', label: 'N/A' },
  ];

  ngOnInit(): void {}

  getChoicesName(object: string, value: any) {
    const choices = this.fundService.choices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice: any) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  fromatCreditorsRight(value: any) {
    const creditor = this.creditors_rights_items.find(
      (_creditor) => _creditor.value == value
    );

    return creditor.label;
  }
}
