import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import DDIOptions from 'src/app/data/ddi';
import Utils from 'src/app/helpers/utils';
import { FundService, FundValues } from 'src/app/services/fund/fund.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import UIDefaultTheme from 'src/themes';
import { distinctUntilChanged } from 'rxjs/operators';
import fakerbr from 'faker-br';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ModalFilesUrlsProps } from '../../dashboard-approval/dashboard-approval-attachments/dashboard-approval-attachments.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard-fund-approval-register-quotas',
  templateUrl: './dashboard-fund-approval-register-quotas.component.html',
  styleUrls: ['./dashboard-fund-approval-register-quotas.component.scss'],
})
export class DashboardFundApprovalRegisterQuotasComponent implements OnInit {
  @ViewChild('modalRoot') modalRoot;

  DDIOptions = DDIOptions;

  form = this.formBuilder.group({
    quota_types: [null, [Validators.required]],
    initial_quota: [null, [Validators.required, Utils.maxDigits(12)]],
    min_initial_application: [null, [Utils.maxDigits(12)]],
    min_additional_application: [null, [Utils.maxDigits(12)]],
    min_redemption: [null, [Utils.maxDigits(12)]],
    min_permanence: [null, [Utils.maxDigits(12)]],
    application_conversion: [null, [Validators.required]],
    application_conversion_unit: [null, [Validators.required]],
    application_sale_off: [null, [Validators.required]],
    application_sale_off_unit: [null, [Validators.required]],
    rescue_conversion: [null, [Validators.required]],
    rescue_conversion_unit: [null, [Validators.required]],
    rescue_sale_off: [null, [Validators.required]],
    rescue_sale_off_unit: [null, [Validators.required]],
  });

  quotaForm = this.formBuilder.group({
    quota_offer_type: [null, [Validators.required]],
    benchmark: [null, []],
    quota_class: [null, [Validators.required]],
    emission: [null, [Validators.required]],
    amortization_grace_period: [null, [Validators.required]],
    quota_order: [null, [Validators.required]],
    quota_quantity: [
      null,
      [Validators.required, Validators.min(1), Utils.maxDigits(12)],
    ],
    quota_serie_amount: [null, [Validators.required]],
    quota_amount: [
      null,
      [Validators.required, Validators.min(1), Utils.maxDigits(12)],
    ],
    balance: [null, [Validators.required]],
    quota_status: [null, [Validators.required]],
    quota_cvm_register_date: [
      null,
      [Validators.required, Utils.validateMaxDate, Utils.validateMinDate],
    ],
    quote_offer_final_date: [
      null,
      [Validators.required, Utils.validateMaxDate, Utils.validateMinDate],
    ],
    amortization_frequency: [null, [Validators.required]],
    amortization_initial_date: [
      null,
      [Validators.required, Utils.validateMaxDate, Utils.validateMinDate],
    ],
    amortization_information: [null, []],
    quota_initial_date: [
      null,
      [Validators.required, Utils.validateMaxDate, Utils.validateMinDate],
    ],
    quota_final_date: [
      null,
      [Validators.required, Utils.validateMaxDate, Utils.validateMinDate],
    ],
  });

  quotaAttachments: FilePicked[] = [];
  newQuotaAttachments: FilePicked[] = [];

  clearAttachments: boolean = false;
  showQuotaDetails = false;

  choices: { [Key in any]: any } = {};

  quotas: any[] = [];

  UIDefaultTheme = UIDefaultTheme;

  errorText = 'Campo inválido';
  modalFiles: any = null;
  modalFilesUrls: ModalFilesUrlsProps[] | null = null;
  selectedFile: ModalFilesUrlsProps | null = null;

  quota_status_items: SelectItem[] = [
    { label: 'Ativa', value: 'actived' },
    { label: 'Encerrada', value: 'finished' },
  ];

  selectedQuota: number | null = null;
  showAttachmentsDetails: boolean = false;

  quota_order_items: SelectItem[] = [{ label: 'A definir', value: '1' }];

  constructor(
    private formBuilder: FormBuilder,
    public fundService: FundService,
    public signupService: SignupService,
    public toast: ToastService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((status) => {
      Utils.getErrors(this.form);
    });

    this.quotaForm.valueChanges.subscribe((status) => {
      Utils.getErrors(this.quotaForm);
    });

    if (this.UIDefaultTheme.brand.simplifiedFund) {
      this.form.controls.benchmark.setValidators([]);
      this.form.controls.quota_types.setValidators([]);
      this.form.controls.quota_offer_type.setValidators([]);
      this.form.controls.application_conversion.setValidators([]);
      this.form.controls.application_conversion_unit.setValidators([]);
      this.form.controls.application_sale_off.setValidators([]);
      this.form.controls.application_sale_off_unit.setValidators([]);
      this.form.controls.rescue_conversion.setValidators([]);
      this.form.controls.rescue_conversion_unit.setValidators([]);
      this.form.controls.rescue_sale_off.setValidators([]);
      this.form.controls.rescue_sale_off_unit.setValidators([]);
      this.form.controls.amortization_frequency.setValidators([]);

      this.form.updateValueAndValidity();
    }

    this.choices = this.fundService.choices;

    this.setData(this.fundService.data);
    // this.checkSeriesArr();

    this.form.valueChanges.subscribe((values: Partial<FundValues>) => {
      this.fundService.setFundFormData({
        ...this.fundService.data,
        ...values,
      });
    });

    this.form.statusChanges.subscribe((status) => {
      const isSeriesNecessary = this.UIDefaultTheme.brand.simplifiedFund
        ? true
        : this.quotas.length > 0;

      this.fundService.setFundFormStatus(
        'complementary',
        status === 'VALID' && isSeriesNecessary
      );
    });

    this.quotaForm.controls.quota_quantity.valueChanges.subscribe((value) => {
      const quota_amount = this.quotaForm.controls.quota_amount.value;

      const quota_serie_amount = parseFloat(value) * parseFloat(quota_amount);

      this.quotaForm.controls.balance.setValue(value);
      this.quotaForm.controls.quota_serie_amount.setValue(quota_serie_amount);
      this.quotaForm.controls.balance.updateValueAndValidity();
      this.quotaForm.controls.quota_serie_amount.updateValueAndValidity();
    });

    this.quotaForm.controls.quota_amount.valueChanges.subscribe((value) => {
      const quota_quantity = this.quotaForm.controls.quota_quantity.value;

      const quota_serie_amount = parseFloat(quota_quantity) * parseFloat(value);

      this.quotaForm.controls.quota_serie_amount.setValue(quota_serie_amount);
      this.quotaForm.controls.quota_serie_amount.updateValueAndValidity();
    });

    this.quotaForm.controls.quote_offer_final_date.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        this.quotaForm.controls.quote_offer_final_date.setValidators([
          Validators.required,
          Utils.validateMinDate,
          Utils.validateMaxDate,
          (control: FormControl) => {
            if (
              !value ||
              !this.quotaForm.controls.quota_cvm_register_date.value
            )
              return null;

            this.errorText = 'Campo inválido';

            const offerDateArr = value.split('/');

            const offerDateDay = offerDateArr[0];
            const offerDateMonth = offerDateArr[1];
            const offerDateYear = offerDateArr[2];

            const offerDate = new Date(
              offerDateYear,
              offerDateMonth - 1,
              offerDateDay
            );

            const cvmRegisterDateArr =
              this.quotaForm.controls.quota_cvm_register_date.value.split('/');

            const cvmRegisterDay = cvmRegisterDateArr[0];
            const cvmRegisterMonth = cvmRegisterDateArr[1];
            const cvmRegisterYear = cvmRegisterDateArr[2];

            const cvmRegister = new Date(
              cvmRegisterYear,
              cvmRegisterMonth - 1,
              cvmRegisterDay
            );

            if (cvmRegister > offerDate) {
              this.errorText =
                'Data de encerramento da oferta não pode ser menor que a data de registro na CVM';
              return { minDate: true };
            }

            return null;
          },
        ]);

        this.quotaForm.controls.quote_offer_final_date.updateValueAndValidity();
      });

    this.quotaForm.controls.quota_cvm_register_date.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        this.quotaForm.controls.quote_offer_final_date.setValidators([
          Validators.required,
          Utils.validateMinDate,
          Utils.validateMaxDate,
          (control: FormControl) => {
            if (!control.value || !value) return null;

            this.errorText = 'Campo inválido';

            const offerDateArr = control.value.split('/');

            const offerDateDay = offerDateArr[0];
            const offerDateMonth = offerDateArr[1];
            const offerDateYear = offerDateArr[2];

            const offerDate = new Date(
              offerDateYear,
              offerDateMonth - 1,
              offerDateDay
            );

            const cvmRegisterDateArr = value.split('/');

            const cvmRegisterDay = cvmRegisterDateArr[0];
            const cvmRegisterMonth = cvmRegisterDateArr[1];
            const cvmRegisterYear = cvmRegisterDateArr[2];

            const cvmRegister = new Date(
              cvmRegisterYear,
              cvmRegisterMonth - 1,
              cvmRegisterDay
            );

            if (cvmRegister > offerDate) {
              this.errorText =
                'Data de encerramento da oferta não pode ser menor que a data de registro na CVM';
              return { minDate: true };
            }

            return null;
          },
        ]);

        this.quotaForm.controls.quote_offer_final_date.updateValueAndValidity();
      });

    this.quotaForm.controls.quota_final_date.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        if (!value) {
          this.quotaForm.controls.quota_final_date.setValidators([]);
        } else {
          this.quotaForm.controls.quota_final_date.setValidators([
            Validators.required,
            Utils.validateMinDate,
            Utils.validateMaxDate,
          ]);
        }
        this.quotaForm.controls.quota_final_date.updateValueAndValidity();
      });

    this.setRandomData();
  }

  setRandomData() {
    this.signupService.fillFormEvent.subscribe(() => {
      this.form.patchValue({
        benchmark: fakerbr.random.words(1),
        quota_offer_type: String(fakerbr.random.number({ min: 1, max: 2 })),
        initial_quota: fakerbr.random.number({ min: 500000, max: 2000000 }),
        quota_types: String(fakerbr.random.number({ min: 1, max: 2 })),
        min_initial_application: fakerbr.random.number({
          min: 500000,
          max: 2000000,
        }),
        min_additional_application: fakerbr.random.number({
          min: 500000,
          max: 2000000,
        }),
        min_redemption: fakerbr.random.number({ min: 500000, max: 2000000 }),
        min_permanence: fakerbr.random.number({ min: 500000, max: 2000000 }),
        application_conversion: fakerbr.random.number({ min: 1, max: 10 }),
        application_conversion_unit: String(
          fakerbr.random.number({ min: 1, max: 2 })
        ),
        application_sale_off: fakerbr.random.number({ min: 1, max: 10 }),
        application_sale_off_unit: String(
          fakerbr.random.number({ min: 1, max: 2 })
        ),
        rescue_conversion: fakerbr.random.number({ min: 1, max: 10 }),
        rescue_conversion_unit: String(
          fakerbr.random.number({ min: 1, max: 2 })
        ),
        rescue_sale_off: fakerbr.random.number({ min: 1, max: 10 }),
        rescue_sale_off_unit: String(fakerbr.random.number({ min: 1, max: 2 })),

        amortization_frequency: String(
          fakerbr.random.number({ min: 1, max: 5 })
        ),
        amortization_information: fakerbr.random.words(3),
      });

      //   for (let i = 0; i < fakerbr.random.number({ min: 1, max: 5 }); i++) {
      //     const serieObject = {
      //       quota_order: fakerbr.random.number({ min: 1, max: 11 }),
      //       quota_class: fakerbr.random.number({ min: 1, max: 10 }),
      //       quota_quantity: fakerbr.random.number({ min: 1, max: 10 }) * 1000,
      //       quota_serie_amount: fakerbr.random.number({ min: 1, max: 10 }) * 1000000,
      //     };

      //     this.quotaForm.patchValue(serieObject);
      //     this.addQuota();
      //   }
    });
  }

  //   checkSeriesArr() {
  //     if (this.quotas.length === 0 && !this.UIDefaultTheme.brand.simplifiedFund) {
  //       this.quotaForm.controls.quota_order.setValidators([Validators.required]);
  //       this.quotaForm.controls.quota_class.setValidators([Validators.required]);
  //       this.quotaForm.controls.quota_quantity.setValidators([
  //         Validators.required,
  //         Utils.maxDigits(12),
  //       ]);
  //       this.quotaForm.controls.quota_serie_amount.setValidators([
  //         Validators.required,
  //         Utils.maxDigits(12),
  //       ]);
  //     } else {
  //       this.quotaForm.controls.quota_order.setValidators([]);
  //       this.quotaForm.controls.quota_class.setValidators([]);
  //       this.quotaForm.controls.quota_quantity.setValidators([
  //         Utils.maxDigits(12),
  //       ]);
  //       this.quotaForm.controls.quota_serie_amount.setValidators([Utils.maxDigits(12)]);
  //     }

  //     this.quotaForm.controls.quota_order.updateValueAndValidity();
  //     this.quotaForm.controls.quota_class.updateValueAndValidity();
  //     this.quotaForm.controls.quota_quantity.updateValueAndValidity();
  //     this.quotaForm.controls.quota_serie_amount.updateValueAndValidity();
  //   }

  setUndeterminatedDate(value: boolean) {
    if (value) {
      this.quotaForm.controls.quota_final_date.setValue(null);
      this.quotaForm.controls.quota_final_date.setValidators([]);
    }

    this.quotaForm.controls.quota_final_date.updateValueAndValidity();
  }

  addQuota() {
    const formValues = this.quotaForm.value;

    const sameOrders = this.quotas.filter(
      (item, idx) =>
        item.quota_order === formValues.quota_order &&
        idx !== this.selectedQuota
    );

    if (sameOrders.length > 0) {
      const haveSameClass = sameOrders.find(
        (item, idx) =>
          item.quota_class === formValues.quota_class &&
          idx !== this.selectedQuota
      );

      if (haveSameClass) {
        this.toast.show(
          'error',
          'Erro!',
          'Não é possível adicionar duas séries com a mesma classe e ordem.'
        );
        return;
      }
    }

    const obj: any = {
      ...formValues,
      attachments: this.quotaAttachments ?? [],
      quota_class: formValues.quota_class,
      quota_order: formValues.quota_order,

      quota_initial_date: formValues.quota_initial_date
        .split('/')
        .reverse()
        .join('-'),
      quota_final_date: formValues.quota_final_date
        .split('/')
        .reverse()
        .join('-'),
      quote_offer_final_date: formValues.quote_offer_final_date
        .split('/')
        .reverse()
        .join('-'),
      quota_cvm_register_date: formValues.quota_cvm_register_date
        .split('/')
        .reverse()
        .join('-'),
      amortization_initial_date: formValues.amortization_initial_date
        .split('/')
        .reverse()
        .join('-'),
    };

    if (!this.getSeriesFormValid() && this.quotaForm.status === 'VALID') {
      if (this.selectedQuota !== null) {
        this.quotas[this.selectedQuota] = obj;
      } else {
        this.quotas.push(obj);
      }

      this.quotaForm.reset();
      this.resetQuotaForm();
      this.clearAttachments = true;
      //   this.checkSeriesArr();

      const serviceObj = [
        {
          fund: this.fundService.data ? this.fundService.data.id : null,
          ...obj,
        },
      ];

      this.quotaAttachments = [];

      const quota_series_arr_aux =
        this.fundService.data && this.fundService.data.quota_series
          ? this.fundService.data.quota_series
          : [];

      if (this.selectedQuota) {
        quota_series_arr_aux[this.selectedQuota] = serviceObj[0];
      }

      const quota_series_arr =
        this.selectedQuota !== null
          ? quota_series_arr_aux
          : [...quota_series_arr_aux, ...serviceObj];

      this.fundService.setFundFormData({
        ...this.fundService?.data,
        quota_series: quota_series_arr,
      });

      const isSeriesNecessary = this.UIDefaultTheme.brand.simplifiedFund
        ? true
        : this.quotas.length > 0;

      this.fundService.setFundFormStatus(
        'complementary',

        this.form.status === 'VALID' && isSeriesNecessary
      );
    }
  }

  removeQuota(index: number) {
    this.quotas.splice(index, 1);

    const arr =
      this.fundService.data && this.fundService.data.quota_series
        ? this.fundService.data.quota_series
        : [];

    const newArr = arr.filter((item, i) => i !== index);

    this.fundService.setFundFormData({
      ...this.fundService.data,
      quota_series: newArr,
    });

    const isSeriesNecessary = this.UIDefaultTheme.brand.simplifiedFund
      ? true
      : this.quotas.length > 0;

    this.fundService.setFundFormStatus(
      'complementary',

      this.form.status === 'VALID' && isSeriesNecessary
    );

    // this.checkSeriesArr();
  }

  getSeriesFormValid() {
    const formValues = this.quotaForm.value;

    const required_fields = [
      'quota_order',
      'quota_class',
      'quota_quantity',
      'quota_serie_amount',
    ];

    if (formValues.quota_initial_date) {
      required_fields.push('quota_final_date');
    } else {
      required_fields.filter((item) => item !== 'quota_final_date');
    }

    const veryfiedValues = required_fields.some((key) => {
      return formValues[key] === null || formValues[key] === '';
    });

    return veryfiedValues || this.quotaForm.status !== 'VALID';
  }

  setData(values: FundValues) {
    if (values) {
      this.form.patchValue({
        ...values,
        quota_types: values.quota_types ? String(values.quota_types) : null,
        quota_offer_type: values.quota_offer_type
          ? String(values.quota_offer_type)
          : null,
        application_conversion_unit: values.application_conversion_unit
          ? String(values.application_conversion_unit)
          : null,
        application_sale_off_unit: values.application_sale_off_unit
          ? String(values.application_sale_off_unit)
          : null,
        rescue_conversion_unit: values.rescue_conversion_unit
          ? String(values.rescue_conversion_unit)
          : null,
        rescue_sale_off_unit: values.rescue_sale_off_unit
          ? String(values.rescue_sale_off_unit)
          : null,
        amortization_frequency: values.amortization_frequency
          ? String(values.amortization_frequency)
          : null,
      });

      this.quotas = values.quota_series.map((serie: any) => {
        const obj = {
          ...serie,
          //   quota_order: serie.quota_order,
          //   quota_class: serie.quota_class,
          //   quota_quantity: serie.quota_quantity,
          //   quota_serie_amount: serie.quota_amount,
          //   quota_undetermined: serie.quota_undetermined,
          //   quota_final_date: serie.quota_final_date,
        };

        return obj;
      });
    }

    const isSeriesNecessary = this.UIDefaultTheme.brand.simplifiedFund
      ? true
      : this.quotas.length > 0;

    this.fundService.setFundFormStatus(
      'complementary',
      this.form.status === 'VALID' && isSeriesNecessary
    );
  }

  async setFiles(files: FilePicked[]) {
    this.clearAttachments = false;

    const res = await Promise.all(
      files.map((item) => this.getFileUrl(item.file))
    );

    const mappedFiles = files.map((item, idx) => ({
      ...item,
      fileUrl: res[idx],
    }));

    this.newQuotaAttachments = [...mappedFiles];
  }

  getFileUrl(file: File) {
    return new Promise<string>((resolve) => {
      const fileReader = new FileReader();

      fileReader.onload = (e: any) => {
        resolve(e.target.result);
      };

      fileReader.readAsDataURL(file);
    });
  }

  verifyChecked(field: string, value: string) {
    return this.form.get(field).value === value;
  }

  getQuotaDisabled() {
    return this.quotaForm.status !== 'VALID';
  }

  handleEdit(idx: number) {
    const quota = this.quotas[idx];
    this.selectedQuota = idx;
    // this.quotaAttachments = quota.attachments;

    this.quotaForm.patchValue({
      ...quota,
      amortization_grace_period: String(quota.amortization_grace_period),
      quota_offer_type: String(quota.quota_offer_type),
      quota_class: String(quota.quota_class),
      quota_order: String(quota.quota_order),
      quota_initial_date: quota.quota_initial_date
        .split('-')
        .reverse()
        .join('/'),
      quota_final_date: quota.quota_final_date.split('-').reverse().join('/'),
      quote_offer_final_date: quota.quote_offer_final_date
        .split('-')
        .reverse()
        .join('/'),
      quota_cvm_register_date: quota.quota_cvm_register_date
        .split('-')
        .reverse()
        .join('/'),
      amortization_initial_date: quota.amortization_initial_date
        .split('-')
        .reverse()
        .join('/'),
    });
  }

  resetQuotaForm() {
    this.quotaForm.reset();
    this.selectedQuota = null;
  }

  handleOpenQuota(i) {
    this.showQuotaDetails = true;
    this.selectedQuota = i;
  }

  closeModal() {
    this.selectedQuota = null;
    this.showQuotaDetails = false;
  }

  openFile(attachments: Partial<AttachmentListValues>[]) {
    this.modalFiles = attachments;

    const newModalFilesUrls = attachments.map(
      (attachment: Partial<any>) => attachment.fileUrl
    );

    this.modalFilesUrls = newModalFilesUrls;
    this.selectedFile = newModalFilesUrls[0];

    this.modalRoot.show();
  }

  getChoicesName(object: string, value: any) {
    const choices = this.fundService.choices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  setPrevAttachment() {
    const index = this.modalFilesUrls?.findIndex(
      (item) => item.url === this.selectedFile?.url
    );

    if (index === 0) return;

    this.selectedFile = this.modalFilesUrls[index - 1];
  }

  setNextAttachment() {
    const index = this.modalFilesUrls?.findIndex(
      (item) => item.url === this.selectedFile?.url
    );

    if (index === this.modalFilesUrls?.length - 1) return;

    this.selectedFile = this.modalFilesUrls[index + 1];

    // if (index === this.modalFilesUrls?.length - 1) {
    //   this.selectedFile = this.modalFilesUrls[0];
    //   return;
    // }

    // if (index !== undefined) {
    //   this.selectedFile = this.modalFilesUrls[index + 1];
    // }
  }

  getDisabled(direction) {
    const index = this.modalFilesUrls?.findIndex(
      (item) => item.url === this.selectedFile?.url
    );

    if (direction === 'prev') {
      return index === 0;
    } else {
      return index === this.modalFilesUrls?.length - 1;
    }
  }

  //   getImgUrl(attachment: Partial<any>) {
  //     let url: any = attachment.fileUrl;
  //     let type: string = '';

  //     const regex = /\.([0-9a-z]+)(?:[\?#]|$)/i;

  //     const resultado = regex.exec(attachment.fileUrl);

  //     if (resultado && resultado[1]) {
  //       type = resultado[1].toLowerCase();
  //     }

  //     if (type === 'pdf') {
  //       url = this.sanitizer.bypassSecurityTrustResourceUrl(attachment.file);
  //     }

  //     if (type === 'xls' || type === 'xlsx') {
  //       url = this.sanitizer.bypassSecurityTrustResourceUrl(
  //         `https://view.officeapps.live.com/op/embed.aspx?src=${attachment.file}`
  //       );
  //     }

  //     let auxExtensionTypes = ['pdf', 'xls', 'xlsx'];

  //     return {
  //       url,
  //       isImg: !auxExtensionTypes.includes(type),
  //       name: this.formatFileName(attachment.file),
  //     };
  //   }

  formatFileName(file: string) {
    return file.split('/').pop()?.split('.').shift();
  }

  getFileIcon(file: string) {
    if (file.includes('.pdf')) {
      return 'assets/images/pdf.png';
    } else if (file.includes('.doc') || file.includes('.docx')) {
      return 'assets/images/word.png';
    } else if (file.includes('.xls') || file.includes('.xlsx')) {
      return 'assets/images/xls.png';
    } else if (file.includes('.ppt') || file.includes('.pptx')) {
      return 'assets/images/powerpoint.png';
    } else {
      return file;
    }
  }

  getFileName(file: string) {
    let fileName = '';

    const regex = /uploads\/\d{4}\/\d{2}\/\d{2}\/(.*)\?Expires/;

    if (file.match(regex)) fileName = file.match(regex)[1];

    return fileName;
  }

  openAttachment(file: string) {
    window.open(file, '_blank');
  }

  removeFile(idx) {
    // this.quotaAttachments.splice(idx, 1);

    this.quotas[this.selectedQuota].attachments.splice(idx, 1);
  }

  handleAddAttachment(index) {
    this.selectedQuota = index;
    this.showAttachmentsDetails = true;
    this.quotaAttachments = this.quotas[index].attachments ?? [];
  }

  closeAttachmentModal() {
    this.showAttachmentsDetails = false;
    this.selectedQuota = null;
    this.quotaAttachments = [];
  }

  getAttachmentDisabled() {
    return this.newQuotaAttachments.length === 0;
  }

  addAttachmentsToQuota() {
    this.quotas[this.selectedQuota].attachments = [
      ...this.quotaAttachments,
      ...this.newQuotaAttachments,
    ];
    this.fundService.setFundFormData({
      ...this.fundService.data,
      quota_series: this.quotas,
    });
    this.closeAttachmentModal();
    this.newQuotaAttachments = [];
  }
}
