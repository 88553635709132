import { Component, OnInit } from '@angular/core';
import { FundService } from 'src/app/services/fund/fund.service';

@Component({
  selector: 'app-dashboard-fund-approval-quotas',
  templateUrl: './dashboard-fund-approval-quotas.component.html',
  styleUrls: ['./dashboard-fund-approval-quotas.component.scss'],
})
export class DashboardFundApprovalQuotasComponent implements OnInit {
  showQuotaDetails: boolean = false;
  selectedQuota: any = null;

  quotas: any[] = [];

  constructor(public fundService: FundService) {}

  ngOnInit(): void {
    this.quotas = this.fundService.data.quota_series;
  }

  getChoicesName(object: string, value: any) {
    const choices = this.fundService.choices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice: any) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  handleOpenQuota(i) {
    this.showQuotaDetails = true;
    this.selectedQuota = i;
  }

  closeModal() {
    this.showQuotaDetails = false;
    this.selectedQuota = null;
  }
}
