<div class="dashboard h-100">
    <div class="dashboard-card no-padding d-flex flex-column justify-content-between"
        style="max-width: 800px">
        <div class="row">
            <div class="col-12">
                <div class=" d-flex flex-column  padding-container">
                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                            height: '24px',
                            width: '30px',
                            margin:'0'
                          }">
                        </ngx-skeleton-loader>

                        <ngx-skeleton-loader [theme]="{
                                height: '27px',
                                width: '100px',
                            marginBottom:'30px'
                              }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <span class="card-info-number">{{documents.length > 9 ?
                            '' :
                            0}}{{documents.length}}</span>
                        <span class="card-info-title">Documentos</span>
                    </ng-container>
                </div>
            </div>

            <div class="col-12 dashboard-card-body">

                <ng-container *ngIf="loading">
                    <table #table>
                        <thead>
                            <tr>
                                <th>
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '125px',
                                    marginBottom:'0'                            
                                  }">
                                    </ngx-skeleton-loader>
                                </th>

                                <th class="text-right">
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '125px',
                                    marginBottom:'0'                            
                                  }">
                                    </ngx-skeleton-loader>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let number of [0,1,2,3,4,5,6]">
                                <td>
                                    <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '100px',
                                    marginBottom:'0'                            
                                      }">
                                    </ngx-skeleton-loader>
                                </td>
                                <td class="item-number">
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '30px',
                                    marginBottom:'0'
                                  }">
                                    </ngx-skeleton-loader>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <table *ngIf="!loading">
                    <thead>
                        <tr>
                            <th>DOCUMENTOS</th>
                            <th style="text-align:right;">VÍNCULOS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let document of visible_documents">
                            <td class="doc-item"
                                [style.max-width.px]="tableSize"
                                style="width: 100%;">
                                {{document.name}}</td>
                            <td class="item-number">{{document.roles.length}}

                                <div class="item-number-options">
                                    <button
                                        (click)="removeDocument(document.id)"
                                        *ngIf="permissionObj.canDelete">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                    <app-spacer [right]="19"></app-spacer>
                                    <button (click)="setDocument(document)"
                                        *ngIf="permissionObj.canChange">
                                        <i class="fas fa-pen"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-12">
                <div
                    class="padding-container d-flex flex-row justify-content-between">
                    <ng-container *ngIf="loading">
                        <td>
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                        width: '180px',
                            marginBottom:'0'                            
                              }">
                            </ngx-skeleton-loader>

                        <td>
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                width: '180px',
                                marginBottom:'0'                            
                                  }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <app-button [inverted]="true"
                            (onClick)="redirect('/app/settings/documents')">Ver
                            todos
                        </app-button>

                        <app-button (click)="addDocumentsToggle(true)"
                            class="add-button" *ngIf="permissionObj.canAdd"> <i
                                class="fas fa-plus-circle mr-2"></i>Adicionar
                            documento</app-button>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 " *ngIf="documents.length === 0 && !loading">
                <div class="empty-card">
                    <div class="img-container">
                        <img src="/assets/images/documents-icon.png"
                            alt="icone">
                    </div>

                    <ng-container *ngIf="!permissionObj.canAdd">
                        <h3>
                            Ainda não existem documentos cadastrados
                        </h3>
                    </ng-container>

                    <ng-container *ngIf="!permissionObj.canAdd">
                        <h3>Defina os documentos</h3>
                        <p>
                            Curabitur maximus ipsum non quam bibendum facilisis.
                            Ut
                            sollicitudin id erat vel
                        </p>

                        <app-button (click)="addDocumentsToggle(true)"
                            class="add-button">
                            <i class="fas fa-plus-circle mr-2"></i>Adicionar
                            documento
                        </app-button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<app-side-modal [active]="addDocuments" (onClose)="closeModal()"
    [title]="document ? 'Atualizar documento' : 'Adicionar documento'"
    paddingBottom="30">
    <ng-container *ngIf="addDocuments">
        <div class="row align-items-center" [formGroup]="documentsForm">
            <div class="col-12">
                <app-input label="Nome do documento" [group]="documentsForm"
                    controlName="name" helpText="Máximo de 200 caracteres"
                    [maxLength]="200">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Descrição" [rows]="3" [group]="documentsForm"
                    controlName="description"
                    helpText="Máximo de 210 caracteres" [maxLength]="210">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12">
                <app-select [items]="agentsOptions" [multiple]="true"
                    label="Defina para quais agentes este documento deve ser solicitado"
                    [group]="documentsForm" controlName="roles">
                </app-select>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12" *ngIf="showFrameworks">
                <app-select [items]="frameworkList" [multiple]="true"
                    label="Defina o enquadramento da empresa"
                    [group]="documentsForm" controlName="frameworks">
                </app-select>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12">
                <app-radio [multiple]="true" formControlName="required">
                    <span class="app-radio-text">Preenchimento
                        obrigatório</span>
                </app-radio>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-radio [multiple]="true" formControlName="have_due_date">
                    <span class="app-radio-text">Possui data de validade</span>
                </app-radio>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-radio [multiple]="true" formControlName="individual"
                    [disabled]="disableIndividual">
                    <span class="app-radio-text">Solicitar individualmente para
                        cada pessoa física</span>
                </app-radio>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12">
                <app-select [items]="cardsList" [multiple]="true"
                    label="Grupo de referência"
                    helpText="Defina o grupo onde o anexo ficara visível no detalhe do cadastro"
                    [group]="documentsForm" controlName="show_in"
                    [readOnly]="disableShowIn">
                </app-select>
                <app-spacer [bottom]="48"></app-spacer>
            </div>

            <div class="col-12 col-lg-6">
            </div>

            <div class=" col-12 col-lg-6 text-right">
                <app-button [disabled]="documentsForm.invalid"
                    (click)="addDocument()">
                    {{document ? 'Atualizar documento' : 'Adicionar documento'}}
                </app-button>
            </div>
        </div>
    </ng-container>

</app-side-modal>