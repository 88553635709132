<div class="dashboard h-100">
    <div class="dashboard-card no-padding d-flex flex-column justify-content-between"
        style="max-width: 800px">
        <div class="row">
            <div class="col-12">
                <div class="padding-container d-flex flex-column">
                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                            height: '24px',
                            width: '30px',
                            margin:'0'
                          }">
                        </ngx-skeleton-loader>

                        <ngx-skeleton-loader [theme]="{
                                height: '27px',
                                width: '100px',
                            marginBottom:'30px'
                              }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading && witnesses.length > 0">
                        <span class="card-info-number">{{witnesses.length
                            > 9 ? '' :
                            0}}{{witnesses.length}}</span>
                        <span class="card-info-title">Testemunhas</span>
                    </ng-container>
                </div>
            </div>

            <div class="col-12 dashboard-card-body">
                <ng-container *ngIf="loading">
                    <table #table>
                        <thead>
                            <tr>
                                <th>
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '125px',
                                    marginBottom:'0'                            
                                  }">
                                    </ngx-skeleton-loader>
                                </th>

                                <th class="text-right">
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '125px',
                                    marginBottom:'0'                            
                                  }">
                                    </ngx-skeleton-loader>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let number of [0,1,2,3]">
                                <td>
                                    <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '100px',
                                    marginBottom:'0'                            
                                      }">
                                    </ngx-skeleton-loader>
                                </td>
                                <td class="item-number">
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '30px',
                                    marginBottom:'0'
                                  }">
                                    </ngx-skeleton-loader>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <table #table *ngIf="witnesses.length > 0 && !loading">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th style="text-align:right;">Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let witness of witnesses.slice(0,10)">
                            <td>{{witness.name}}</td>
                            <td class="item-number">
                                {{witness.email}}
                                <div class="item-number-options">
                                    <ng-container
                                        *ngIf="permissionObj.canDelete">
                                        <button
                                            (click)="removeWitnesses(witness.id)">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                        <app-spacer [right]="19"></app-spacer>
                                    </ng-container>
                                    <button (click)="setWitnesses(witness)"
                                        *ngIf="permissionObj.canChange">
                                        <i class="fas fa-pen"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-12" *ngIf="!loading && witnesses.length > 0">
                <div
                    class="padding-container d-flex flex-row justify-content-between">
                    <app-button [inverted]="true"
                        (onClick)="redirect('/app/organizations/witnesses')">Ver
                        todos
                    </app-button>

                    <app-button (click)="addWitnessesToggle(true)"
                        class="add-button" *ngIf="permissionObj.canAdd">
                        <i class="fas fa-plus-circle mr-2"></i>
                        Adicionar testemunha
                    </app-button>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="witnesses.length === 0 && !loading">
            <div class="col-12">
                <div class="empty-card">

                    <div class="img-container">
                        <img src="/assets/images/collaborator-icon.png"
                            alt="icone">
                    </div>

                    <ng-container *ngIf="!permissionObj.canAdd">
                        <h3>
                            Ainda não existem testemunhas cadastradas
                        </h3>
                    </ng-container>

                    <ng-container *ngIf="permissionObj.canAdd">
                        <h3>Testemunhas</h3>
                        <p>
                            Adicione as testemunhas que você deseja que sejam
                            notificadas quando você enviar um link de um
                            documento.
                        </p>

                        <app-button (click)="addWitnessesToggle(true)"
                            class="add-button">
                            <i class="fas fa-plus-circle mr-2"></i>Adicionar
                            testemunha
                        </app-button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<app-side-modal [active]="addWitnesses" (onClose)="addWitnessesToggle(false)">
    <ng-container *ngIf="addWitnesses">
        <div class="row" [formGroup]="witnessForm">
            <div class="col-12">
                <span class="organization-form-title">{{witness ? 'Atualizar' :
                    'Nova'}} testemunha</span>
                <app-spacer [bottom]="8"></app-spacer>
            </div>

            <div class="col-12">
                <span class="organization-form-description">{{witness ?
                    'Atualize os dados da testemunha' : 'Adicione uma nova
                    testemunha'}}
                </span>
                <app-spacer [bottom]="48"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Nome" [group]="witnessForm"
                    controlName="name">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Email" [group]="witnessForm"
                    controlName="email">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12">
                <app-masked-input label="CPF" [group]="witnessForm"
                    controlName="document" mask="000.000.000-00">
                </app-masked-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 d-flex justify-content-between">
                <app-button [phantom]="true"
                    (onClick)="addWitnessesToggle(false)">
                    Cancelar
                </app-button>

                <app-button [disabled]="witnessForm.invalid"
                    (click)="addNewWitnesss()">{{witness ? 'Atualizar
                    testemunha' : 'Adicionar nova testemunha' }}
                </app-button>
            </div>
        </div>
    </ng-container>
</app-side-modal>