
const gitInfo = {
  branch: "master",
  commit: "3598032",
  origin: "https://bitbucket.org/prosperitainova/dataengine-web-angular",
  url: "https://bitbucket.org/prosperitainova/dataengine-web-angular/commits/3598032cbd02f6db1e7af0c5d3a7b3e3e3ab1818",
  date: "25/07/2024",
};

export default gitInfo;
  