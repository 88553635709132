<main class="dashboard">
    <div class="row w-100 justify-content-center">
        <div class="col-12 ml-auto mr-auto">

            <div class="dash-card">

                <div class="row no-gutters">

                    <div *ngIf="accountsLoading" class="col-12">
                        <div class="skeleton-padding">
                            <ngx-skeleton-loader [theme]="{
                            height: '27px',
                            width: '125px'
                          }">
                            </ngx-skeleton-loader>
                        </div>

                        <table class="bordered skeleton-table">
                            <thead>
                                <tr>
                                    <th *ngFor="let number of [0,1,2,3]">
                                        <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '125px',
                                        marginBottom:'0'
                                      }">
                                        </ngx-skeleton-loader>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let number of [0,1,2]">
                                    <td>
                                        <div class="dash-card-item">
                                            <div
                                                class="dash-card-item-initials">
                                                <span>
                                                    <ngx-skeleton-loader
                                                        appearance="circle"
                                                        [theme]="{
                                                    height: '48px',
                                                    width: '48px'
                                                  }">
                                                    </ngx-skeleton-loader>
                                                </span>
                                            </div>

                                            <app-spacer
                                                [right]="16"></app-spacer>

                                            <div class="dash-card-item-content">
                                                <div
                                                    class="dash-card-item-content-title">
                                                    <ngx-skeleton-loader
                                                        [theme]="{
                                                        height: '21px',
                                                        width: '130px',
                                                        margin:'0'
                                                      }">
                                                    </ngx-skeleton-loader>
                                                </div>
                                                <div
                                                    class="dash-card-item-content-subtitle">
                                                    <ngx-skeleton-loader
                                                        [theme]="{
                                                        height: '18px',
                                                        width: '110px'
                                                      }">
                                                    </ngx-skeleton-loader>
                                                </div>
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <ngx-skeleton-loader [theme]="{
                                            height: '21px',
                                            width: '200px'
                                          }">
                                        </ngx-skeleton-loader>
                                    </td>

                                    <td>
                                        <ngx-skeleton-loader [theme]="{
                                            height: '21px',
                                            width: '100px'
                                          }">
                                        </ngx-skeleton-loader>
                                    </td>

                                    <td>
                                        <ngx-skeleton-loader [theme]="{
                                            height: '21px',
                                            width: '200px'
                                          }">
                                        </ngx-skeleton-loader>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <ng-container *ngIf="!accountsLoading">
                        <div class="col-12">
                            <div class="dash-card-header">
                                <span>Meus cadastros</span>
                            </div>

                            <div
                                class="header-button-container justify-content-end">
                                <div class="d-flex align-items-center">
                                    <app-button [inverted]="true"
                                        class="add-button"
                                        (onClick)="showFilterModal = true">
                                        <svg-icon
                                            src="assets/images/icon_filter.svg"></svg-icon>
                                        Aplicar filtros
                                    </app-button>
                                </div>
                            </div>
                            <app-spacer [bottom]="32"></app-spacer>
                        </div>

                        <div class="col-12 padding-container dash-card-body">

                            <table class="bordered"
                                *ngIf="accounts.length > 0 && !accountsLoading">
                                <thead>
                                    <tr>
                                        <th>Cadastro</th>
                                        <th>Função</th>
                                        <th>Permissão</th>
                                        <th>ESTÁGIO ATUAL</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr *ngFor="let item of accounts">
                                        <td>
                                            <div class="dash-card-item">
                                                <div
                                                    class="dash-card-item-initials">
                                                    <span>{{getInitials(item)}}</span>
                                                </div>

                                                <app-spacer
                                                    [right]="16"></app-spacer>

                                                <div
                                                    class="dash-card-item-content">
                                                    <div
                                                        class="dash-card-item-content-title">
                                                        {{getName(item)}}
                                                    </div>
                                                    <div
                                                        class="dash-card-item-content-subtitle">
                                                        {{getDisplayDocument(item)}}
                                                    </div>
                                                    <div
                                                        class="dash-card-item-content-subtitle">
                                                        Criado em
                                                        {{item.register.created_at
                                                        |
                                                        date:'dd/MM/yyyy'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="dash-card-item-content"
                                                *ngIf="item.register.role">
                                                <div
                                                    class="dash-card-item-content-title">
                                                    {{item.register.role.applicable
                                                    === 'provider' ?
                                                    renderRoles(item) :
                                                    item.register.role.applicable_display
                                                    }}
                                                </div>
                                                <div
                                                    class="dash-card-item-content-subtitle">
                                                    {{item.register.role.name}}
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <span class="status">
                                                {{item.status_display}}</span>
                                            <div class="status-aprovation">
                                            </div>
                                        </td>

                                        <td>
                                            <span
                                                class="status">{{getStatus(item)}}
                                            </span>
                                            <div class="status-aprovation">
                                                {{getStatusInfo(item)}}
                                            </div>
                                        </td>

                                        <td style="text-align: right;">
                                            <div
                                                class="d-flex justify-content-end">
                                                <ng-container
                                                    *ngIf="item.status_display === 'Aprovado'">
                                                    <button class="button mr-2"
                                                        title="Visualizar"
                                                        (click)="changeActiveRegister(item.id)">
                                                        <i
                                                            class="fas fa-eye"></i>
                                                    </button>
                                                    <button class="button mr-2"
                                                        title="Editar"
                                                        (click)="redirectWarn(item)">
                                                        <i
                                                            class="fas fa-pen"></i>
                                                    </button>
                                                    <button class="button mr-2"
                                                        title="Adicionar Papel"
                                                        *ngIf="item.register?.agent.length > 0"
                                                        (click)="handleEditRoleModal(item.id)">
                                                        <i
                                                            class="fas fa-link"></i>
                                                    </button>
                                                </ng-container>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr *ngIf="accounts.length === 0">
                                        <td colspan="4">
                                            <span
                                                class="dash-card-item-content-subtitle">
                                                Nenhum cadastro encontrado
                                            </span>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                            <div class="row"
                                *ngIf="accounts.length === 0 && !accountsLoading">
                                <div class="col-12">
                                    <app-empty-content [isVisible]="true"
                                        title="Você ainda não possuí cadastros adicionais"
                                        description="Não foi encontrado nenhum cadastro adicional nesse usuário"
                                        icon="/assets/images/forgot-password-icon2.png">
                                    </app-empty-content>
                                </div>

                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="dash-card-pagination" *ngIf="accounts.length > 0">
                <app-pagination [data]="accounts" [count]="count"
                    [show]="!accountsLoading" [offset]="offset"
                    (onPageChange)="changePageData($event)"
                    [dashPagination]="true">
                </app-pagination>
            </div>
        </div>
    </div>
</main>

<app-side-modal [active]="showFilterModal" title="Filtrar listagem"
    (onClose)="showFilterModal = false">
    <form [formGroup]="filterForm">
        <div class="row">
            <div class="col-12">
                <app-input [group]="filterForm" controlName="name" label="Nome"
                    placeholder="Digite">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-masked-input [group]="filterForm" [alwaysValid]="true"
                    controlName="document" label="Documento"
                    helpText="Se PF inserir CPF, caso PJ inserir CNPJ"
                    mask="CPF_CNPJ" placeholder="Insira seu documento">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-select [items]="primaryRoleOptions" [group]="filterForm"
                    controlName="role" placeholder="Selecione" label="Papel">
                </app-select>
                <app-spacer [bottom]="32"></app-spacer>
            </div>

            <div class="col-12" *ngIf="selectedApplicable === 'provider'">
                <app-select [items]="roleOptions" [group]="filterForm"
                    controlName="agent" [multiple]="true"
                    placeholder="Selecione" label="Agente">
                </app-select>
                <app-spacer [bottom]="32"></app-spacer>
            </div>

            <!-- <div class="col-12">
            <app-select [items]="statusOptions" [group]="filterForm"
                controlName="status" placeholder="Selecione"
                label="Status" [multiple]="true">
            </app-select>
            <app-spacer [bottom]="32"></app-spacer>
        </div> -->


            <div class="col-12">
                <h3>Por data de cadastro</h3>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-lg-6">
                <app-input type="date" [group]="filterForm"
                    controlName="created_from" label="De" [max]="today">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-lg-6">
                <app-input type="date" label="Até" [group]="filterForm"
                    controlName="created_to" placeholder="">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>


            <div class="col-12">
                <h3>Por data de atualização</h3>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-lg-6">
                <app-input type="date" label="De" [group]="filterForm"
                    controlName="updated_from">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-lg-6">
                <app-input type="date" label="Até" [group]="filterForm"
                    controlName="updated_to" placeholder="">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>

        <app-spacer [bottom]="32"></app-spacer>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="resetForm()" class="add-button"
                    [phantom]="true">
                    Limpar filtros
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="filterData()" class="add-button">
                        Aplicar filtros
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>


<app-side-modal [active]="roleModal" title="Adicionar papel"
    (onClose)="closeModal()">
    <form [formGroup]="roleForm" *ngIf="selectedAccount">
        <h3 class="title">Papeis atuais</h3>
        <p class="sub-title">{{renderRoles(selectedAccount)}}</p>
        <hr>

        <div class="row">
            <div class="col-12">
                <app-select [items]="filteredRoleOptions" [multiple]="true"
                    [group]="roleForm" controlName="role"
                    placeholder="Selecione" label="Papel">
                </app-select>
                <app-spacer [bottom]="32"></app-spacer>
            </div>
        </div>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="closeModal()" class="add-button"
                    [outline]="true">
                    Cancelar
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="addAgentRole()"
                        [disabled]="roleForm.invalid" class="add-button"
                        [loading]="sendingRequest">
                        Adicionar
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>