import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FundService } from 'src/app/services/fund/fund.service';

type ProviderProps = {
  label: string;
  value: string;
  role: string;
  extra: string;
};
@Component({
  selector: 'app-dashboard-approval-providers',
  templateUrl: './dashboard-approval-providers.component.html',
  styleUrls: ['./dashboard-approval-providers.component.scss'],
})
export class DashboardApprovalProvidersComponent implements OnInit {
  @Input() admin: ProviderProps;
  @Input() manager: ProviderProps;

  @Output() onClick = new EventEmitter<MouseEvent>();

  columns: TableColumnList<ProviderProps>[] = [
    {
      name: 'Nome',
      cell: (row) => {
        return `
    <div class="dash-card-item p-0">
        <div class="dash-card-item-initials mr-3">
            <span>${this.getInitials(row.label)}</span>
        </div>

        <div class="dash-card-item-content">
            <div class="dash-card-item-content-title">
                ${row.label}
            </div>
            <div class="dash-card-item-content-subtitle">
                ${row.extra ? this.formatDocument(row.extra) : '-'}
            </div>
        </div>
    </div>
        `;
      },
      size: 2,
    },
    {
      name: 'Função',
      cell: (row) => {
        const isAdmin = row.value === this.admin.value;
        return `
        <div class="dash-card-item-content">
      <div class="dash-card-item-content-title">
         ${isAdmin ? 'Administrador' : 'Gestor'}
      </div>
      <div class="dash-card-item-content-subtitle">
        ${row.role}
      </div>
  </div>`;
      },
    },
    {
      name: '',
      cell: (row) =>
        `<div class="d-flex align-items-center icon-container">
                <a href="/app/details/provider/${row.value}">
                    <i class="fas fa-eye mr-0"></i>
                </a>
            </div>`,
    },
  ];
  data: Partial<ProviderProps>[] = [];

  constructor(private fundService: FundService) {}

  ngOnInit(): void {
    this.data = this.admin !== null ? [this.admin, this.manager] : [];
  }

  formatDate(date: string) {
    const day = new Date(date).toLocaleDateString();
    const hours = new Date(date).toLocaleTimeString();
    return `${day} às ${hours}`;
  }

  formatDocument(document: string) {
    if (document.length < 14) {
      return (
        'CPF: ' +
        document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
      );
    }

    return (
      'CNPJ: ' +
      document.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        '$1.$2.$3/$4-$5'
      )
    );
  }

  getInitials(name: string) {
    return name
      .toUpperCase()
      .replace(/[^\w\s]/gi, '')
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word.charAt(0))
      .join('');
  }

  getChoicesName(object: string, value: any) {
    const choices = this.fundService.choices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice: any) => _choice.value == value);
    }

    return { label: '', value: '' };
  }
}
