<div class="row" *ngIf="fundService.data">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">
            <div class="dash-details-card-body">
                <div class="row">
                    <div class="col-12 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            ADMINISTRAÇÃO
                        </span>

                        <span class="dash-details-card-item-title">
                            {{fundService.data.administration_fee ?? '-'}}
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="16"></app-spacer>

                <div class="row">
                    <div class="col-12 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            CUSTÓDIA
                        </span>

                        <span class="dash-details-card-item-title">
                            {{fundService.data.custody_fee ?? '-'}}
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="16"></app-spacer>

                <div class="row">
                    <div class="col-12 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            CONTROLADORIA
                        </span>

                        <span class="dash-details-card-item-title">
                            {{fundService.data.controlling_fee ?? '-'}}
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="16"></app-spacer>

                <div class="row">
                    <div class="col-12 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            ESCRITURAÇÃO
                        </span>

                        <span class="dash-details-card-item-title">
                            {{fundService.data.bookkeeping_fee ?? '-'}}
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="16"></app-spacer>

                <div class="row">
                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            GERAL
                        </span>

                        <span class="dash-details-card-item-title">
                            {{fundService.data.general_fee |
                            currency:'BRL'}}
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            ESTRUTURAÇÃO
                        </span>

                        <span class="dash-details-card-item-title">
                            {{fundService.data.structuring_fee |
                            currency:'BRL'}}
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            IMPLANTAÇÃO SISTÊMICA
                        </span>

                        <span class="dash-details-card-item-title">
                            {{fundService.data.implantation_fee |
                            currency:'BRL'}}
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            GESTÃO
                        </span>

                        <span class="dash-details-card-item-title">
                            {{fundService.data.management_fee |
                            currency:'BRL'}}
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="16"></app-spacer>

                <div class="row">
                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            DISTRIBUIÇÃO
                        </span>

                        <span class="dash-details-card-item-title">
                            {{fundService.data.distribution_fee |
                            currency:'BRL' ??
                            '-'}}
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            VERIFICAÇÃO DE LASTRO
                        </span>

                        <span class="dash-details-card-item-title">
                            {{fundService.data.check_fee |
                            currency:'BRL'?? '-'}}
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            AGENTE DE COBRANÇA
                        </span>

                        <span class="dash-details-card-item-title">
                            {{fundService.data.agent_fee |
                            currency:'BRL' ?? '-'}}
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            Consultoria
                        </span>

                        <span class="dash-details-card-item-title">
                            {{fundService.data.consultancy_fee |
                            currency:'BRL'?? '-'}}
                        </span>
                    </div>
                </div>

                <app-spacer [bottom]="16"></app-spacer>

                <div class="row">
                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            TOTAL DE TAXAS
                        </span>

                        <span class="dash-details-card-item-title">
                            {{fundService.data.total_fee |
                            currency:'BRL' ??
                            '-'}}
                        </span>
                    </div>
                </div>

                <ng-container *ngIf="fundService.data.other_tax">
                    <app-spacer [bottom]="26"></app-spacer>
                    <div class="row">
                        <div class="col-12">
                            <app-label class="font-weight-bold"
                                label="Outras taxas">
                            </app-label>
                        </div>
                    </div>
                    <app-spacer [bottom]="8"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                PERFORMANCE
                            </span>

                            <span class="dash-details-card-item-title">
                                {{fundService.data.performance_tax ?? '-'}}
                            </span>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                ENTRADA
                            </span>

                            <span class="dash-details-card-item-title">
                                {{fundService.data.entry_tax}}%
                            </span>
                        </div>

                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                SAIDA
                            </span>

                            <span class="dash-details-card-item-title">
                                {{fundService.data.out_tax}}%
                            </span>
                        </div>

                        <div class="col-3 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                COBRANÇA DE OUTRAS SERÁ SOBRE
                            </span>

                            <span class="dash-details-card-item-title">
                                {{getChoicesName('charge_on_choices',
                                fundService.data.charge_type)?.label ?? '-'}}
                            </span>
                        </div>
                    </div>

                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-12 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                DESCRIÇÃO
                            </span>

                            <span class="dash-details-card-item-title">
                                {{fundService.data.other_tax_description ??
                                '-'}}
                            </span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>