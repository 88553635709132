import { EventEmitter, Injectable } from '@angular/core';

import { StorageService } from '../storage/storage.service';

export type UserTypes = 'PF' | 'PJ' | 'ADM' | '';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  token: string | undefined = undefined;
  _user: NewLoginResponse | undefined = undefined;

  activeUser: NewLoginResponse | undefined = undefined;

  userAuth: 'authStep' | 'recoveryStep' = 'authStep';
  code: string = '0000';

  userChange = new EventEmitter<NewLoginResponse | undefined>();

  forgotEmail: string = '';
  cpfAuthenticate: boolean = false;
  hidedForgotEmail: string = '';
  forgotValue: string = '';
  permissions: PermissionValue[] = [];
  accountsArr: RegisterUserResponse[] = [];

  finances: FinancePFResponse | undefined;
  financesFetched = new EventEmitter<FinancePFResponse>();

  registerRefetchEvent = new EventEmitter();

  get user() {
    return this._user;
  }

  set user(user: NewLoginResponse | undefined) {
    this._user = user;
    this.userChange.emit(user);
  }

  constructor(private storageService: StorageService) {
    const user = this.storageService.getUser();
    if (user) this.user = user;

    const token = this.storageService.getToken();
    if (token) this.token = token;
  }

  setToken(token: string) {
    this.storageService.setToken(token);
    this.token = token;
  }

  getToken(): string | undefined {
    if (this.token) {
      return this.token;
    }

    const storedToken = this.storageService.getToken();

    if (storedToken) {
      this.token = storedToken;
      return this.token;
    }

    return undefined;
  }

  setUser(user: NewLoginResponse) {
    this.storageService.setUser(user);
    this.user = user;
  }

  updateUser(user: Partial<NewLoginResponse>) {
    const newUser: NewLoginResponse = Object.assign(this.user, user);
    this.setUser(newUser);
  }

  getUser(): NewLoginResponse | undefined {
    if (this.user) {
      return this.user;
    }

    const storedUser = this.storageService.getUser();

    if (storedUser) {
      this.user = storedUser;
      return storedUser;
    }

    return undefined;
  }

  setActiveUser(user: NewLoginResponse) {
    this.activeUser = user;
  }

  getActiveUser(): NewLoginResponse | undefined {
    return this.activeUser;
  }

  setUserAuth(step: 'authStep' | 'recoveryStep', code: string) {
    this.userAuth = step;
    this.code = code;
  }

  getUserAuth() {
    return this.userAuth;
  }

  clearUser() {
    this.user = undefined;
    this.token = undefined;
    this.storageService.logout();
  }

  verifyPermission = (codiname: string | string[] = []) => {
    const search = typeof codiname === 'string' ? [codiname] : codiname;

    const mappedPerms = this.permissions
      ? this.permissions.map((permission) => permission.codename)
      : [];

    const hasPerm = search.every((perm) => mappedPerms.includes(perm));

    return hasPerm;
  };
}
