<div class="row no-gutters">
    <div class="col-12 offset-lg-1 col-lg-10">

        <form [formGroup]="form">

            <div class="row">
                <div class="col-12 col-lg-12">
                    <span class="basic-content-title">Atenção</span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-10">
                    <span class="basic-content-description">
                        As partes se comprometem a tratar os dados pessoais
                        envolvidos na confecção e necessários à
                        execução da
                        contratação, única e exclusivamente para cumprir com a
                        finalidade a que se destinam e em
                        respeito a toda a
                        legislação aplicável sobre segurança da informação,
                        privacidade e proteção de dados, inclusive,
                        mas não se
                        limitando à Lei Geral de Proteção de Dados (Lei Federal
                        n. 13.709/2018).
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </div>


            <div class="row">

                <div class="offset-1 col-9">
                    <app-radio [multiple]="true"
                        formControlName="authorized_attorney_representative">
                        <div class="flex-1 d-flex flex-column">
                            <span class="option-title">Autorizo a transmissão de
                                ordens por procurador ou
                                representante</span>
                            <app-spacer [bottom]="8"></app-spacer>
                            <span class="option-description">Em caso positivo
                                anexar a procuração específica,
                                comprometendo-se a
                                informar
                                por escrito à CM Capital no caso de eventual
                                revogação de mandato.</span>
                        </div>
                    </app-radio>
                    <app-spacer [bottom]="24"></app-spacer>


                    <app-radio [multiple]="true"
                        formControlName="politically_exposed">
                        <div class="flex-1 d-flex flex-column">
                            <span class="option-title">Sou pessoa politicamente
                                exposta</span>
                            <app-spacer [bottom]="8"></app-spacer>
                            <span class="option-description">Considera-se pessoa
                                exposta politicamente, nos termos da legislação
                                em vigor: (i) agente público que desempenha ou
                                tenha desempenhado, nos últimos cinco anos, no
                                Brasil ou em países, territórios e dependências
                                estrangeiras, cargos, empregos ou funções
                                públicas relevantes; ou (ii) representante,
                                familiar (parente na linha reta até primeiro
                                grau, cônjuge, companheiro(a) ou
                                enteado(a)).</span>
                        </div>
                    </app-radio>

                    <ng-container
                        *ngIf="form.controls.politically_exposed.value">
                        <div class="row ml-2">
                            <div class="offset-1 col-7">
                                <app-input [group]="form"
                                    controlName="description_politically"
                                    label="Esclareça"></app-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </div>
                    </ng-container>


                    <app-spacer [bottom]="24"></app-spacer>

                    <app-radio [multiple]="true"
                        formControlName="politically_exposed_person_relationship">
                        <div class="flex-1 d-flex flex-column">
                            <span class="option-title">Tenho relacionamento com
                                pessoa politicamente exposta</span>
                        </div>
                    </app-radio>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </div>


            <ng-container
                *ngIf="form.controls.politically_exposed_person_relationship.value">
                <div class="row ml-2">
                    <div class="offset-1 col-7">
                        <app-masked-input [group]="form"
                            controlName="document_number" label="CPF da pessoa"
                            placeholder="123.456.789-01"
                            mask="000.000.000-00"></app-masked-input>

                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>

                <div class="row ml-2">
                    <div class="offset-1 col-7">
                        <app-input [group]="form" controlName="name"
                            label="Nome da pessoa"></app-input>

                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>

                <div class="row ml-2">
                    <div class="offset-1 col-7">
                        <app-input [group]="form" controlName="bond"
                            label="Vínculo"></app-input>

                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>
            </ng-container>

            <div class="row">
                <div class="col-12 col-lg-12">
                    <span class="basic-content-subtitle">Declarações e
                        Autorizações do Cliente</span>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </div>

            <div class="row">

                <div class="offset-1 col-9">
                    <app-radio [multiple]="true"
                        formControlName="third_party_operation">
                        <div class="flex-1 d-flex flex-column">
                            <span class="option-title">
                                Opero por Conta de Terceiros
                            </span>
                        </div>
                    </app-radio>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

            </div>

            <ng-container *ngIf="form.controls.third_party_operation.value">
                <div class="row ml-2">
                    <div class="offset-1 col-7 ">

                        <app-masked-input [group]="form"
                            controlName="document_number_person_or_company"
                            label="CPF / CNPJ" mask="CPF_CNPJ"
                            placeholder="000.000.000-00 | 00.000.000/0000-00">
                        </app-masked-input>

                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>


                <div class="row ml-2">
                    <div class="offset-1 col-7">
                        <app-input [group]="form"
                            controlName="name_or_company_name"
                            label="Nome da pessoa ou Razão Social">
                        </app-input>

                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>
            </ng-container>

            <div class="row">

                <div class="offset-1 col-9">
                    <app-radio [multiple]="true"
                        formControlName="linked_cm_capital">
                        <div class="flex-1 d-flex flex-column">
                            <span class="option-title">A empresa, seus sócios
                                e/ou representantes é pessoa vinculada à CM
                                Capital</span>
                            <app-spacer [bottom]="8"></app-spacer>
                            <span class="option-description">Considera-se pessoa
                                vinculada, nos termos da legislação em
                                vigor: (i)
                                administradores, funcionários, operadores,
                                prestadores de serviços, incluindo Agente
                                Autônomo de
                                Investimentos e demais prepostos da CM CAPITAL
                                que desempenham atividades de
                                intermediação ou de suporte
                                operacional; (ii) cônjuge ou companheiro e
                                filhos menores das pessoas mencionadas no
                                item anterior;
                                (iii)
                                pessoas naturais e jurídicas que, diretamente ou
                                indiretamente, sejam controladoras ou
                                participem do
                                controle societário da CM CAPITAL ; (iv) clubes
                                e fundos de investimentos cuja maioria
                                das coras
                                pertença
                                a pessoas vinculadas, salvo se geridos
                                discricionariamente por terceiros não
                                vinculados.</span>
                        </div>
                    </app-radio>
                    <app-spacer [bottom]="72"></app-spacer>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-10">
                    <span class="basic-content-subtitle">Declaro e autorizo
                        que</span>
                    <app-spacer [bottom]="16"></app-spacer>
                    <span class="basic-content-description">
                        (I) Sou o beneficiário final efetivo de todos os valores
                        oriundos em decorrência das operações e
                        ou serviços
                        contratados (ou sou o representante legal autorizado a
                        assinar pelo titular);<br><br> (II) As
                        informações
                        fornecidas são verdadeiras e eventuais alterações serão
                        comunicadas, por escrito, no prazo de 10
                        (dez) dias.
                        Até que seja comunicada qualquer alteração de dados,
                        declaro concordar com quaisquer atos ou
                        eventos que
                        venham a ocorrer para os quais a CM CAPITAL leve em
                        consideração os dados cadastrais até então
                        informados,
                        inclusive eventual revogação de mandato, procuradores
                        houver;<br><br> (III) Estou ciente das
                        disposições da
                        Lei no
                        9.613, de 3 de março
                        de 1998, e demais normativos relacionados à prevenção ao
                        crime de lavagem de dinheiro e combate
                        ao
                        financiamento do terrorismo (“PLD/FT”), bem como,
                        concordo que a CM CAPITAL proceda e atue de
                        acordo com as
                        disposições constantes da referida lei e da
                        regulamentação aplicável;<br><br> (IV) Não possuo
                        qualquer
                        impedimento
                        para operar no mercado de valores mobiliários e mercado
                        de capitais;<br><br> (V) As ordens
                        emitidas devem
                        ser
                        transmitidas nos termos das Regras e Parâmetros de
                        Atuação da CM CAPITAL, disponível no site.
                        Estou ciente
                        ainda que toda transmissão de ordem por meio digital
                        está sujeita a interrupções ou atrasos,
                        podendo impedir
                        ou prejudicar o envio de ordens ou a recepção de
                        informações atualizadas;<br><br> (VI) Autorizo
                        a CM
                        Capital,
                        caso
                        existam débitos em meu nome, a liquidar os contratos,
                        direitos e ativos adquiridos, bem como a
                        executar bens
                        e
                        direitos dados em garantia de suas operações ou que
                        estejam em poder da CM Capital, aplicando o
                        produto da
                        venda no pagamento dos débitos pendentes,
                        independentemente de notificação judicial ou
                        extrajudicial;<br><br>
                        (VII) Se
                        o propósito dessa ficha, conforme indicado no campo
                        “Tipo de Cadastro”, for de Renovação ou
                        Alteração de
                        dados
                        cadastrais, entendo e concordo que os campos em branco
                        ou tachados representam informações já
                        contidas na
                        Ficha Cadastral Original (1o Cadastro) ou atualizações
                        posteriores, portanto, declaro que não
                        houve
                        alterações
                        nas informações reportadas anteriormente.<br><br> (VIII)
                        Caso seja escolhida a opção de
                        assinaturas digitais
                        reconheço
                        como válida e verdadeira a assinatura dessa Ficha
                        Cadastral e dos demais documentos assinados
                        dessa forma,
                        ainda que sejam firmados com assinatura digital ou
                        certificação fora dos padrões ICP – Brasil,
                        conforme
                        disposto pelo artigo 10, da Medida Provisória n,
                        2.200/2001.<br><br> (IX) Autorizo a CM Capital
                        a proceder à
                        análise e
                        a conferência dos dados cadastrais informados, inclusive
                        informações de Crédito, na forma da
                        legislação em
                        vigor, bem como a compartilhá-los com as empresas do
                        mesmo Grupo Econômico, às fontes pagadoras
                        de
                        rendimentos
                        ou aos depositários centrais ou agentes escrituradores,
                        às autoridades regulatórias conforme
                        exigido nos
                        termos da legislação em vigor, ficando todos autorizados
                        a examinar e a utilizar, no Brasil e no
                        exterior,
                        tais informações, no limite das finalidades previstas em
                        contrato e ou dispositivo
                        legal.<br><br>Todas as
                        minhas
                        declarações aqui feitas o foram com pleno conhecimento
                        de causa e de posse de todas as
                        informações
                        necessárias
                        para a manifestação de minha vontade.</span>

                    <app-spacer [bottom]="56"></app-spacer>
                </div>
            </div>

            <div class="row">

                <div class="offset-1 col-9">
                    <app-radio [multiple]="true"
                        formControlName="declare_and_authorize">
                        <div class="flex-1 d-flex flex-column">
                            <span class="option-title">Por ser expressão da
                                verdade, assino a presente</span>
                            <span class="option-description">Ao selecionar este
                                item, você estará assinando este
                                contrato</span>
                        </div>
                    </app-radio>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

            </div>

        </form>
    </div>
</div>