import { Component, OnInit } from '@angular/core';
import { FundService } from 'src/app/services/fund/fund.service';
import { SignupService } from 'src/app/services/signup/signup.service';

@Component({
  selector: 'app-dashboard-fund-approval-banking',
  templateUrl: './dashboard-fund-approval-banking.component.html',
  styleUrls: ['./dashboard-fund-approval-banking.component.scss'],
})
export class DashboardFundApprovalBankingComponent implements OnInit {
  fund: any = undefined;
  zeroingFund: any = undefined;

  constructor(
    private signupService: SignupService,
    public fundService: FundService
  ) {}

  ngOnInit(): void {
    this.fund = this.fundService.data;
    this.zeroingFund = this.fundService.data.zeroing_automatic;
  }

  getChoicesName(object: string, value: any) {
    const choices = this.signupService.signupChoices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice) => _choice.value == value);
    }

    return { label: '', value: '' };
  }
}
