import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import Utils from 'src/app/helpers/utils';
import { FundService, FundValues } from 'src/app/services/fund/fund.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import UIDefaultTheme from 'src/themes';
import fakerbr from 'faker-br';
@Component({
  selector: 'app-dashboard-fund-approval-register-tax',
  templateUrl: './dashboard-fund-approval-register-tax.component.html',
  styleUrls: ['./dashboard-fund-approval-register-tax.component.scss'],
})
export class DashboardFundApprovalRegisterTaxComponent implements OnInit {
  today = Utils.todayString();

  form = this.formBuilder.group({
    administration_fee: [null, [Validators.required]],
    custody_fee: [null, [Validators.required]],
    controlling_fee: [null, [Validators.required]],
    bookkeeping_fee: [null, [Validators.required]],
    general_fee: [null, [Validators.required, Utils.maxDigits(10)]],
    structuring_fee: [null, [Validators.required, Utils.maxDigits(10)]],
    check_fee: [null, [Validators.required, Utils.maxDigits(10)]],
    structuring_fee_date: [
      null,
      [Validators.required, Utils.validateMinDate, Utils.validateMaxDate],
    ],
    total_fee: [null, [Validators.required, Utils.maxDigits(10)]],
    implantation_fee: [null, [Validators.required, Utils.maxDigits(10)]],
    management_fee: [null, [Validators.required, Utils.maxDigits(10)]],
    distribution_fee: [null, [Validators.required, Utils.maxDigits(10)]],
    agent_fee: [null, [Validators.required]],
    consultancy_fee: [null, [Validators.required]],
    other_tax: [null, []],
    performance_tax: ['', [Validators.max(100), Validators.min(0)]],
    entry_tax: ['', [Validators.max(100), Validators.min(0)]],
    out_tax: ['', [Validators.max(100), Validators.min(0)]],
    other_tax_description: [null, []],
    charge_type: [null, []],
  });

  choices: { [Key in any]: any } = {};

  fund: FundValues = undefined;

  UIDefaultTheme = UIDefaultTheme;

  constructor(
    private formBuilder: FormBuilder,
    public fundService: FundService,
    private signupService: SignupService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((status) => {
      Utils.getErrors(this.form);
    });

    if (this.UIDefaultTheme.brand.simplifiedFund) {
      this.form.controls.administration_fee.setValidators([]);
      this.form.controls.custody_fee.setValidators([]);
      this.form.controls.controlling_fee.setValidators([]);
      this.form.controls.bookkeeping_fee.setValidators([]);
      this.form.controls.general_fee.setValidators([Utils.maxDigits(10)]);
      this.form.controls.structuring_fee.setValidators([Utils.maxDigits(10)]);
      this.form.controls.structuring_fee_date.setValidators([
        Utils.validateWhenNotEmpty(Utils.validateMinDate),
        Utils.validateWhenNotEmpty(Utils.validateMaxDate),
      ]);
      this.form.controls.agent_fee.setValidators([]);
      this.form.controls.consultancy_fee.setValidators([]);
      this.form.controls.total_fee.setValidators([Utils.maxDigits(10)]);

      this.form.controls.implantation_fee.setValidators([]);
      this.form.controls.management_fee.setValidators([]);
      this.form.controls.distribution_fee.setValidators([]);
      this.form.controls.check_fee.setValidators([Utils.maxDigits(10)]);

      this.form.updateValueAndValidity();
    }

    this.choices = this.fundService.choices;
    this.setData(this.fundService.data);

    this.form.controls.other_tax.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.performance_tax.setValidators([
          Validators.required,
          Validators.max(100),
          Validators.min(0),
        ]);
        this.form.controls.entry_tax.setValidators([
          Validators.required,
          Validators.max(100),
          Validators.min(0),
        ]);
        this.form.controls.out_tax.setValidators([
          Validators.required,
          Validators.max(100),
          Validators.min(0),
        ]);
      } else {
        this.form.controls.performance_tax.setValidators([]);
        this.form.controls.entry_tax.setValidators([]);
        this.form.controls.out_tax.setValidators([]);
      }

      this.form.controls.performance_tax.updateValueAndValidity();
      this.form.controls.entry_tax.updateValueAndValidity();
      this.form.controls.out_tax.updateValueAndValidity();
    });

    this.form.valueChanges.subscribe((values) => {
      this.fundService.setFundFormData({
        ...this.fundService.data,
        ...values,
      });
    });

    this.form.statusChanges.subscribe((status) =>
      this.fundService.setFundFormStatus('tax', status === 'VALID')
    );

    this.setRandomData();
  }

  setRandomData() {
    this.signupService.fillFormEvent.subscribe(() => {
      this.form.patchValue({
        administration_fee: fakerbr.random.words(3),
        custody_fee: fakerbr.random.words(3),
        controlling_fee: fakerbr.random.words(3),
        bookkeeping_fee: fakerbr.random.words(3),
        check_fee: fakerbr.random.number({ min: 500000, max: 2000000 }),
        structuring_fee: fakerbr.random.number({ min: 500000, max: 2000000 }),
        general_fee: fakerbr.random.number({ min: 500000, max: 2000000 }),
        structuring_fee_date: fakerbr.date
          .past(20)
          .toLocaleDateString('pt-BR', {
            timeZone: 'UTC',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
        implantation_fee: fakerbr.random.number({ min: 500000, max: 2000000 }),
        management_fee: fakerbr.random.number({ min: 500000, max: 2000000 }),
        distribution_fee: fakerbr.random.number({ min: 500000, max: 2000000 }),
        total_fee: fakerbr.random.number({ min: 500000, max: 2000000 }),
        other_tax: true,

        performance_tax: fakerbr.random.number({
          min: 0.01,
          max: 100,
        }),
        entry_tax: fakerbr.random.number({
          min: 0.01,
          max: 100,
        }),
        out_tax: fakerbr.random.number({
          min: 0.01,
          max: 100,
        }),

        charge_type: String(fakerbr.random.number({ min: 1, max: 3 })),
        agent_fee: fakerbr.random.words(3),
        consultancy_fee: fakerbr.random.words(3),
      });
    });
  }

  setData(values: FundValues) {
    this.fund = values;

    if (values) {
      this.form.patchValue({
        ...values,
        structuring_fee_date: values.structuring_fee_date
          ? values.structuring_fee_date.split('-').reverse().join('/')
          : null,

        charge_type: values.charge_type ? String(values.charge_type): null,
      });
    }

    this.fundService.setFundFormStatus('tax', this.form.status === 'VALID');
  }
}
