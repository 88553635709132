import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SignupService } from 'src/app/services/signup/signup.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {
  @Input() steps: Step[] = [];
  @Input() haveNavigation = false;

  @Input() activeIndex = 0;
  @Input() stepType = 'person';
  @Input() activeSubstepSlug: ComplementaryCompanySteps | PersonSteps =
    'complementaryGeneral';
  @Output() changeActiveStep = new EventEmitter<number>();
  isShareholder: boolean = false;
  isFund: boolean = false;

  constructor(
    private signupService: SignupService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.isShareholder = this.router.url.includes('shareholder');
    this.isFund =
      this.authService.user.active_register.register.role.slug.includes('-FI');
  }

  handlePassedStep(slug: string) {
    const activedIndex = this.steps[0].substeps.findIndex(
      (item) => item.slug === this.activeSubstepSlug
    );

    const slugIndex = this.steps[0].substeps.findIndex(
      (item) => item.slug === slug
    );

    if (activedIndex > slugIndex) {
      return true;
    }

    if (this.activeIndex >= 1) return true;

    return false;
  }

  handleChangeSubStep(substep) {
    const slug = substep.slug.replace('complementary', '');

    this.changeActiveStep.emit(0);

    if (this.stepType === 'person') {
      this.signupService.changePersonStepEvent.emit(slug.toLowerCase());
      this.signupService.setPersonStep(substep.slug);
    } else {
      this.signupService.changeCompanyStepEvent.emit(slug.toLowerCase());
      this.signupService.setComplementaryCompanyStep(substep.slug);
    }
  }

  handleChangeStep(step) {
    if (this.stepType === 'person') {
      if (this.isShareholder) {
        switch (step.title) {
          case 'Dados complementares':
            this.signupService.changePersonStepEvent.emit('general');
            this.signupService.setPersonStep('complementaryGeneral');

            this.changeActiveStep.emit(0);
            break;

          case 'Rendimentos & patrimônios':
            this.signupService.updateUserStep('register', 'income');

            this.changeActiveStep.emit(1);
            break;

          case 'Declarações & autorizações':
            this.signupService.updateUserStep('register', 'declarations');

            this.changeActiveStep.emit(2);
            break;

          case 'Análise de perfil de investidor':
            this.signupService.updateUserStep('register', 'analysis');

            this.changeActiveStep.emit(3);
            break;

          case 'Declaração de Condição de Investidor':
            this.signupService.updateUserStep('register', 'conditions');

            this.changeActiveStep.emit(4);
            break;

          case 'Declaração US Person / Non US Person (FATCA)':
            this.signupService.updateUserStep('register', 'tax');

            this.changeActiveStep.emit(5);
            break;

          case 'Anexos & comprovantes':
            this.signupService.updateUserStep('register', 'attachments');

            this.changeActiveStep.emit(6);
            break;

          default:
            break;
        }
      } else {
        switch (step.title) {
          case 'Dados complementares':
            this.signupService.changePersonStepEvent.emit('general');
            this.signupService.setPersonStep('complementaryGeneral');

            this.changeActiveStep.emit(0);
            break;

          case 'Anexos & comprovantes':
            this.signupService.updateUserStep('register', 'attachments');

            this.changeActiveStep.emit(1);
            break;

          default:
            break;
        }
      }
    } else {
      if (this.isShareholder) {
        if (this.isFund) {
          switch (step.title) {
            case 'Dados complementares':
              this.signupService.changeCompanyStepEvent.emit('general');
              this.signupService.setCompanyStep('complementaryGeneral');

              this.changeActiveStep.emit(0);
              break;

            case 'Declarações & autorizações':
              this.signupService.updateUserStep('register', 'declarations');

              this.changeActiveStep.emit(1);
              break;

            case 'Perfil de Investimento do Fundo Cotista':
              this.signupService.updateUserStep('register', 'analysis');

              this.changeActiveStep.emit(2);
              break;

            case 'Declaração US Person / Non US Person (FATCA)':
              this.signupService.updateUserStep('register', 'tax');

              this.changeActiveStep.emit(3);
              break;

            case 'Anexos & comprovantes':
              this.signupService.updateUserStep('register', 'attachments');

              this.changeActiveStep.emit(4);
              break;

            default:
              break;
          }
        } else {
          switch (step.title) {
            case 'Dados complementares':
              this.signupService.changeCompanyStepEvent.emit('general');
              this.signupService.setCompanyStep('complementaryGeneral');

              this.changeActiveStep.emit(0);
              break;

            case 'Rendimentos & patrimônios':
              this.signupService.updateUserStep('register', 'income');

              this.changeActiveStep.emit(1);
              break;

            case 'Declarações & autorizações':
              this.signupService.updateUserStep('register', 'declarations');

              this.changeActiveStep.emit(2);
              break;

            case 'Análise de perfil de investidor':
              this.signupService.updateUserStep('register', 'analysis');

              this.changeActiveStep.emit(3);
              break;

            case 'Declaração de Condição de Investidor':
              this.signupService.updateUserStep('register', 'conditions');

              this.changeActiveStep.emit(4);
              break;

            case 'Declaração US Person / Non US Person (FATCA)':
              this.signupService.updateUserStep('register', 'tax');

              this.changeActiveStep.emit(5);
              break;

            case 'Anexos & comprovantes':
              this.signupService.updateUserStep('register', 'attachments');

              this.changeActiveStep.emit(6);
              break;

            default:
              break;
          }
        }
      } else {
        switch (step.title) {
          case 'Dados complementares':
            this.signupService.changeCompanyStepEvent.emit('general');
            this.signupService.setComplementaryCompanyStep(
              'complementaryGeneral'
            );

            this.changeActiveStep.emit(0);
            break;

          case 'Resumo dos poderes':
            this.signupService.updateUserStep('register', 'powers');
            this.changeActiveStep.emit(1);

            break;

          case 'Anexos & comprovantes':
            this.signupService.updateUserStep('register', 'attachments');

            this.changeActiveStep.emit(2);

            break;

          default:
            break;
        }
      }
    }
  }
}
