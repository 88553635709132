<div class="row">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">
            <div class="list-container" *ngIf="data.length >0;else noResult">
                <app-table-list [columns]="columns" [data]="data"
                    size="16px"></app-table-list>
            </div>
            <ng-template #noResult>
                <span
                    class="dash-card-item-content-title text-center d-block pb-4 mb-0">
                    Nenhum prestador de serviço cadastrado
                </span>
            </ng-template>
        </div>
        <app-spacer [bottom]="24"></app-spacer>
    </div>
</div>