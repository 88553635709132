import { Component, Input, OnInit } from '@angular/core';
import Utils from 'src/app/helpers/utils';
import { PersonIncomeOthersValues } from '../../signup-unified-person/signup-unified-person-income/signup-unified-person-income-others/signup-unified-person-income-others.component';

export type PersonIncomeRealtyValues = {
  type: string;
  current_value: string;
  address: {
    cep: string;
    public_place: string;
    number: string;
    complement: string;
    district: string;
    city: string;
    uf: string;
    country: string;
  };
};

@Component({
  selector: 'app-dashboard-approval-income',
  templateUrl: './dashboard-approval-income.component.html',
  styleUrls: ['./dashboard-approval-income.component.scss'],
})
export class DashboardApprovalIncomeComponent implements OnInit {
  @Input() approval: any;

  columns: TableColumn<any>[] = [
    { name: 'Tipo do imóvel', cell: (row) => row.type },
    {
      name: 'Endereço completo',
      cell: (row) => `
          ${
            row.address && row.address.cep
              ? ` <span>
                ${row.address.public_place}, ${row.address.number}, ${
                  row.address.complement ?? ''
                } ${row.address.district}
                </span>
                <span class="table-subtitle">${row.address.city}, ${
                  row.address.uf
                } — ${row.address.cep}</span>`
              : ''
          }
    
          `,
    },
    {
      name: 'Valor atual',
      cell: (row) => Utils.formatBrl(Number(row.current_value)),
    },
  ];

  othersColumns: any[] = [
    { name: 'Tipo do imóvel', cell: (row) => row.type },
    {
      name: 'Descrição',
      cell: (row) => row.description,
    },
    {
      name: 'Valor atual',
      cell: (row) => Utils.formatBrl(Number(row.current_value)),
    },
  ];

  properties: PersonIncomeRealtyValues[] = [];

  footerData: Partial<PersonIncomeRealtyValues>[] = [];

  others: Partial<PersonIncomeOthersValues>[] = [];
  isPj: boolean = false;

  othersFooterData: Partial<PersonIncomeOthersValues>[] = [];
  wage: any;

  constructor() {}

  ngOnInit(): void {
    this.properties = this.approval.properties;
    this.others = this.approval.other_assets;
    this.isPj = this.approval?.register?.type === 'PJ';
    this.wage = this.approval?.wage[0];

    this.setFooterData();
  }

  setFooterData() {
    const total = String(
      this.properties.reduce(
        (prev, curr) => Number(prev) + Number(curr.current_value ?? 0),
        0
      )
    );

    const othersTotal = String(
      this.others.reduce(
        (prev, curr) => Number(prev) + Number(curr.current_value ?? 0),
        0
      )
    );

    this.footerData = [
      {
        type: '<span><strong>Total declarado<strong></span>',

        current_value: total,
      },
    ];

    this.othersFooterData = [
      {
        type: '<span><strong>Total declarado</strong></span>',
        current_value: othersTotal,
      },
    ];
  }

  formatBrl(amount: number | string) {
    const formatted = Utils.formatBrl(Number(amount));

    return formatted;
  }
}
