<ng-container *ngIf="loading">
    <div class="row">
        <div class="offset-1 col-11">
            <div class="row">
                <div class="col-12">
                    <ngx-skeleton-loader [theme]="{
        height: '40px',
        width: '200px',
        marginBottom:'0px'
    }">
                    </ngx-skeleton-loader>

                    <app-spacer [bottom]="32"></app-spacer>
                </div>

                <div class="col-4">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-8">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>

                <div class="col-4">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-4">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-4">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-6">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-12">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-6">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-6">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>

                <div class="col-2">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-4">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>

                <div class="col-2">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-4">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>

                <div class="col-6">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-6">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-4">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-4">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="col-12">
                    <ngx-skeleton-loader [theme]="{height: '40px'}">
                    </ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!loading">
    <div class="row no-gutters">
        <div class="col-12 offset-lg-1 col-lg-11">

            <div class="row">
                <div class="col-12 col-lg-12">
                    <h1 class="title">Grupos</h1>
                    <p class="description">Informe os grupos e seus respectivos
                        representantes conforme
                        contrato social</p>
                </div>
            </div>

            <app-spacer [bottom]="32"></app-spacer>

            <form [formGroup]="form">
                <div class="row">
                    <div class="col-12 col-lg-8">
                        <app-input [group]="form" controlName="group_name"
                            [maxLength]="30" label="Nome do grupo"
                            placeholder="Administração">
                        </app-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-4">
                        <app-select [items]="groupType" [group]="form"
                            controlName="representative_type"
                            label="Tipo do grupo" placeholder="Selecione">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12"
                        *ngIf="form.controls.representative_type.value !== null">
                        <app-select [items]="filteredRepresentativeData"
                            [multiple]="true" [group]="form"
                            controlName="members" [label]="membersLabel"
                            placeholder="Selecione">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 text-right mb-4">
                        <app-button [outline]="true" [inverted]="true"
                            [disabled]="getDisabled()"
                            (onClick)="sendGroupData()"
                            [loading]="sendingGroup">
                            Adicionar grupo
                        </app-button>
                    </div>
                </div>

                <ng-container *ngIf="groupsData.length > 0">
                    <table>
                        <thead>
                            <tr>
                                <th>Grupo</th>
                                <th>Tipo do grupo</th>
                                <th>{{isAssignor ? 'Representantes e
                                    Avalistas/Dev. Solidários' :'Representantes'
                                    }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of groupsData">
                                <td style="width:30%">
                                    {{item.name}}
                                </td>
                                <td style="width:20%">
                                    {{item.representative_type_display}}
                                </td>
                                <td>
                                    <div class="item-actions">
                                        {{item.members ?
                                        formatIntegrants(item.members):'-'}}
                                        <div class="item-actions-container">
                                            <button title="editar" type="button"
                                                (click)="handleOpenGroupEdit(item)">
                                                <i class="fas fa-pen"></i>
                                            </button>
                                            <app-spacer
                                                [right]="19"></app-spacer>
                                            <button title="excluir"
                                                type="button"
                                                (click)="handleOpenWarningModal(item)">
                                                <i class="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-spacer [bottom]="72"></app-spacer>
                </ng-container>
            </form>

            <app-spacer [bottom]="82"></app-spacer>

            <div class="row">
                <div class="col-12 col-lg-12">
                    <h1 class="title">Poderes</h1>
                    <p class="description">Informe os poderes que um ou mais
                        grupos
                        em conjunto podem ter
                        conforme contrato social</p>
                </div>
            </div>

            <app-spacer [bottom]="32"></app-spacer>

            <form [formGroup]="powerForm">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <app-select [items]="groupList"
                            [readOnly]="getPowerFormDisabled()"
                            [group]="powerForm" controlName="principal_group"
                            label="Grupo principal" placeholder="Selecione">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-6">
                        <app-select [items]="secondaryGroupList"
                            [multiple]="true"
                            [readOnly]="getPowerFormDisabled() || getGroupDisabled()"
                            [group]="powerForm" controlName="secondary_group"
                            label="Grupo secundário" placeholder="Selecione">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12">
                        <app-select [items]="filteredAuthorizationChoices"
                            [readOnly]="getPowerFormDisabled()"
                            [group]="powerForm" controlName="power_types"
                            label="Tipo de poder" placeholder="Selecione"
                            [multiple]="true">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12">
                        <app-select [items]="selectedAuthorizations"
                            [readOnly]="getPowerFormDisabled()"
                            [group]="powerForm" controlName="default_power_type"
                            label="Este poder será padrão para qual tipo de assinatura?"
                            placeholder="Selecione" [multiple]="true">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-4">
                        <app-masked-input [readOnly]="getPowerFormDisabled()"
                            type="text" [group]="powerForm"
                            controlName="necessary_signatures_quantity"
                            label="Qt. mín. de assinaturas necessárias"
                            mask="999999999999">
                        </app-masked-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-8">
                        <app-select [readOnly]="getPowerFormDisabled()"
                            [items]="filteredIntegrants" [multiple]="true"
                            [group]="powerForm"
                            controlName="necessary_signatures"
                            label="Assinantes necessários"
                            placeholder="Selecione">
                        </app-select>

                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-4">
                        <app-masked-input [readOnly]="getPowerFormDisabled()"
                            type="text" [group]="powerForm"
                            controlName="required_signatures_quantity"
                            label="Qt. mín. de assinaturas obrigatórias"
                            mask="999999999999">
                        </app-masked-input>

                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-8">
                        <app-select [readOnly]="getPowerFormDisabled()"
                            [items]="filteredIntegrants" [multiple]="true"
                            [group]="powerForm"
                            controlName="required_signatures"
                            label="Assinantes obrigatórios"
                            placeholder="Selecione">
                        </app-select>

                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-4">
                        <app-currency-input [readOnly]="getPowerFormDisabled()"
                            [group]="powerForm"
                            controlName="min_signature_value"
                            label="Valor min. para assinatura"
                            placeholder="R$ 9.000,00">
                        </app-currency-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-4">
                        <app-currency-input
                            [readOnly]="getPowerFormDisabled() || powerForm.controls.indefinite_signature_value.value"
                            [group]="powerForm"
                            controlName="max_signature_value"
                            label="Valor máx. para assinatura"
                            errorText="Valor máximo deve ser maior que o valor mínimo"
                            placeholder="R$ 90.000,00">
                        </app-currency-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-4">
                        <app-spacer [bottom]="36"></app-spacer>
                        <app-radio [multiple]="true"
                            formControlName="indefinite_signature_value">
                            Valor máx. para assinatura ilimitado
                        </app-radio>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12">
                        <app-input type="textarea"
                            [readOnly]="getPowerFormDisabled()"
                            [group]="powerForm" controlName="description"
                            [rows]="4" label="Descrição dos poderes"
                            placeholder="Descreva os poderes conforme contrato social"
                            [noResize]="true" [maxLength]="10000000">
                        </app-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div
                        class="col-12 text-right d-flex align-items-center justify-content-end">
                        <div class="mr-3">
                            <small class="text-danger"
                                *ngIf="!validPowersStatus() && isAssignor">
                                Para prosseguir à próxima etapa, é necessário
                                adicionar
                                ao menos<br>um
                                poder com o tipo "Assina Contrato de Cessão".
                            </small>
                        </div>
                        <app-button [outline]="true" [inverted]="true"
                            [disabled]="powerForm.invalid"
                            (onClick)="sendPowerData()"
                            [loading]="sendingPower">
                            Adicionar poder
                        </app-button>
                    </div>
                </div>

                <app-spacer [bottom]="32"></app-spacer>

                <ng-container *ngIf="powersData.length > 0">
                    <table>
                        <thead>
                            <tr>
                                <th>Poderes</th>
                                <th>Grupo principal</th>
                                <th>Grupo secundário</th>
                                <th>Assinaturas necessárias</th>
                                <th>Assinaturas obrigatórias</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of powersData">
                                <td style="width: 35%;">
                                    {{formatPowers(item.power_type)}}
                                </td>
                                <td>
                                    {{item.main_group.name}}
                                </td>
                                <td>
                                    {{item.secondary_group.length > 0 ?
                                    formatGroup(item): '-'}}
                                </td>
                                <td>
                                    {{formatNecessarySignatures(item)}}
                                </td>
                                <td>
                                    <div class="item-actions">
                                        {{formatRequiredSignatures(item)}}
                                        <div class="item-actions-container">
                                            <button type="button" title="editar"
                                                (click)="handleOpenPowerEdit(item)">
                                                <i class="fas fa-pen"></i>
                                            </button>
                                            <app-spacer
                                                [right]="19"></app-spacer>
                                            <button type="button"
                                                title="visualizar"
                                                (click)="handleOpenPowerDetails(item)">
                                                <i class="fas fa-eye"></i>
                                            </button>
                                            <app-spacer
                                                [right]="19"></app-spacer>
                                            <button type="button"
                                                title="remover"
                                                (click)="handleOpenPowerWarningModal(item)">
                                                <i class="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-spacer [bottom]="72"></app-spacer>
                </ng-container>
            </form>
        </div>
    </div>

    <app-side-modal [active]="editModal"
        [title]="selectedGroup ? selectedGroup.name : ''"
        (onClose)="closeModal()" paddingBottom="0">
        <form [formGroup]="editForm">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <app-input [group]="editForm" controlName="group_name"
                            [maxLength]="30" label="Nome do grupo"
                            placeholder="Administração">
                        </app-input>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12">
                        <app-select [items]="groupType" [group]="editForm"
                            controlName="representative_type"
                            label="Tipo do grupo" placeholder="Selecione">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12"
                        *ngIf="editForm.controls.representative_type.value !== null">
                        <app-select [items]="filteredRepresentativeData"
                            [multiple]="true" [group]="editForm"
                            controlName="members" [label]="membersLabel"
                            placeholder="Selecione">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>

                <div class="row button-sticky">
                    <div class="col-12 col-lg-6">
                        <app-button (onClick)="closeModal()" class="add-button"
                            [outline]="true">
                            Cancelar
                        </app-button>
                    </div>

                    <div class="col-12 col-lg-6">
                        <div class="text-right">
                            <app-button (onClick)="sendGroupData()"
                                class="add-button"
                                [disabled]="editForm.invalid">
                                Salvar alterações do grupo
                            </app-button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </app-side-modal>


    <app-side-modal [active]="editPowerModal" title="Editar Poder"
        (onClose)="closeModal()" paddingBottom="30">
        <form [formGroup]="editPowerForm">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <app-select [items]="groupList" [group]="editPowerForm"
                        controlName="principal_group" label="Grupo principal"
                        placeholder="Selecione">
                    </app-select>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-6">
                    <app-select [items]="secondaryGroupList" [multiple]="true"
                        [readOnly]="getEditGroupDisabled()"
                        [group]="editPowerForm" controlName="secondary_group"
                        label="Grupo secundário" placeholder="Selecione">
                    </app-select>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12">
                    <app-select [items]="filteredAuthorizationChoices"
                        [group]="editPowerForm" controlName="power_types"
                        label="Tipo de poder" placeholder="Selecione"
                        [multiple]="true">
                    </app-select>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12">
                    <app-select [items]="selectedAuthorizations"
                        [readOnly]="getPowerFormDisabled()"
                        [group]="editPowerForm" controlName="default_power_type"
                        label="Poder padrão" placeholder="Selecione"
                        [multiple]="true">
                    </app-select>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-6">
                    <app-masked-input [readOnly]="getPowerFormDisabled()"
                        type="text" [group]="editPowerForm"
                        controlName="necessary_signatures_quantity"
                        label="Qt. mín. de assinaturas necessárias"
                        mask="999999999999">
                    </app-masked-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-6">
                    <app-select [readOnly]="getPowerFormDisabled()"
                        [items]="filteredIntegrants" [multiple]="true"
                        [group]="editPowerForm"
                        controlName="necessary_signatures"
                        label="Assinantes necessários" placeholder="Selecione">
                    </app-select>

                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-6">
                    <app-masked-input [readOnly]="getPowerFormDisabled()"
                        type="text" [group]="editPowerForm"
                        controlName="required_signatures_quantity"
                        label="Qt. mín. de assinaturas obrigatórias"
                        mask="999999999999">
                    </app-masked-input>

                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-md-6">
                    <app-select [readOnly]="getPowerFormDisabled()"
                        [items]="filteredIntegrants" [multiple]="true"
                        [group]="editPowerForm"
                        controlName="required_signatures"
                        label="Assinantes obrigatórios" placeholder="Selecione">
                    </app-select>

                    <app-spacer [bottom]="24"></app-spacer>
                </div>

            </div>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <app-currency-input [group]="editPowerForm"
                        controlName="min_signature_value"
                        label="Valor min. para assinatura"
                        placeholder="R$ 9.000,00">
                    </app-currency-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-6">
                    <app-currency-input
                        [readOnly]="editPowerForm.controls.edit_indefinite_signature_value.value"
                        [group]="editPowerForm"
                        controlName="max_signature_value"
                        errorText="Valor máximo deve ser maior que o valor mínimo"
                        label="Valor máx. para assinatura"
                        placeholder="R$ 90.000,00">
                    </app-currency-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12">
                    <app-radio [multiple]="true"
                        formControlName="edit_indefinite_signature_value">
                        Valor máx. para assinatura ilimitado
                    </app-radio>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12">
                    <app-input type="textarea" [group]="editPowerForm"
                        controlName="description" [rows]="4"
                        label="Descrição dos poderes"
                        placeholder="Descreva os poderes conforme contrato social"
                        [noResize]="true" [maxLength]="10000000">
                    </app-input>
                    <app-spacer [bottom]="48"></app-spacer>
                </div>
            </div>

            <div class="row button-sticky">
                <div class="col-12 col-lg-6">
                    <app-button (onClick)="closeModal()" class="add-button"
                        [outline]="true">
                        Cancelar
                    </app-button>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="text-right">
                        <app-button (onClick)="sendPowerData()"
                            [disabled]="editPowerForm.invalid"
                            class="add-button">
                            Salvar alterações do poder
                        </app-button>
                    </div>
                </div>
            </div>
        </form>
    </app-side-modal>

    <app-side-modal [active]="powerDetails" title="Detalhes do Poder"
        (onClose)="closeModal()" paddingBottom="0">
        <div class="container">
            <div class="row">
                <div class="col-12 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        TIPO DE PODER
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedPower && selectedPower.power_type ?
                        formatPowers(selectedPower.power_type):'-'}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        Poder padrão
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedPower && selectedPower.default_power_type ?
                        formatPowers(selectedPower.default_power_type[0].power_type):'-'}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        GRUPO PRINCIPAL
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedPower && selectedPower.main_group ?
                        selectedPower.main_group.name :'-'}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        GRUPO SECUNDÁRIO
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedPower && selectedPower.secondary_group &&
                        selectedPower.secondary_group.length > 0
                        ?
                        formatGroup(selectedPower):
                        '-'}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        ASSINATURAS NECESSÁRIAS
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedPower &&
                        selectedPower.necessary_signs_quantity ?
                        selectedPower.necessary_signs_quantity :
                        '-'}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        ASSINANTES NECESSÁRIOS
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedPower && selectedPower.necessary_signs ?
                        formatIntegrants(selectedPower.necessary_signs) :
                        '-'}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>


                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        ASSINATURAS OBRIGATÓRIAS
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedPower &&
                        selectedPower.required_signs_quantity ?
                        selectedPower.required_signs_quantity :
                        '-'}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        ASSINANTES OBRIGATÓRIOS
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedPower && selectedPower.required_signs ?
                        formatIntegrants(selectedPower.required_signs) :
                        '-'}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        VALOR MIN. PARA ASSINATURA
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedPower && selectedPower.min_sign_amount ?
                        formatBrl(selectedPower.min_sign_amount) :
                        '-'}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        VALOR MÁX. PARA ASSINATURA
                    </span>

                    <span class="dash-details-card-item-title">
                        <ng-container>
                            {{selectedPower && selectedPower.max_sign_amount ?
                            formatBrl(selectedPower.max_sign_amount) :
                            '-'}}
                        </ng-container>
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        DESCRIÇÃO DOS PODERES
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedPower && selectedPower.description ?
                        selectedPower.description :
                        '-'}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </div>
        </div>
    </app-side-modal>


    <app-warning-modal [active]="removeModal" (onClose)="closeModal()">
        <h3>Atenção</h3>
        <app-spacer [bottom]="24"></app-spacer>

        <ng-container *ngIf="!groupIncluded">
            <p>Você tem certeza que deseja remover o grupo
                {{selectedGroup?.name ?? ''}}?</p>
        </ng-container>

        <ng-container *ngIf="groupIncluded">
            <p>Remova o grupo do poder antes de exclui-lo</p>
        </ng-container>

        <app-spacer [bottom]="56"></app-spacer>

        <ng-container *ngIf="!groupIncluded">
            <app-button (onClick)="removeGroupData()" class="add-button"
                [center]="true" [outline]="true">
                Sim, remover agora
            </app-button>
            <app-spacer [bottom]="8"></app-spacer>
            <app-button (onClick)="closeModal()" [center]="true"
                class="add-button">
                Não, quero manter
            </app-button>
        </ng-container>

        <ng-container *ngIf="groupIncluded">
            <app-button (onClick)="closeModal()" [center]="true"
                class="add-button">
                OK
            </app-button>
        </ng-container>
    </app-warning-modal>


    <app-warning-modal [active]="removePowerModal" (onClose)="closeModal()">
        <h3>Atenção</h3>
        <app-spacer [bottom]="24"></app-spacer>

        <p>Você tem certeza que deseja remover o poder “{{selectedPower &&
            selectedPower.power_type ?
            formatPowers(selectedPower.power_type):'-'}}"?</p>
        <app-spacer [bottom]="56"></app-spacer>

        <app-button (onClick)="removePowerData()" class="add-button"
            [center]="true" [outline]="true">
            Sim, remover agora
        </app-button>
        <app-spacer [bottom]="8"></app-spacer>
        <app-button (onClick)="closeModal()" [center]="true" class="add-button">
            Não, quero manter
        </app-button>
    </app-warning-modal>


    <app-warning-modal [active]="showGroupEditError" (onClose)="closeModal()">
        <h3>Atenção</h3>
        <app-spacer [bottom]="24"></app-spacer>

        <p>Você não editar o grupo
            {{selectedGroup?.name ?? ''}}
            porque ele está vinculado a um poder.
        </p>

        <p>Remova o grupo do poder antes de editalo</p>

        <app-spacer [bottom]="56"></app-spacer>

        <app-button (onClick)="closeModal()" [center]="true" class="add-button">
            OK
        </app-button>
    </app-warning-modal>
</ng-container>