<div class="row">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">

            <ng-container *ngIf="isLoading">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-4 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-4 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-3 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!isLoading">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                CNPJ
                            </span>

                            <span class="dash-details-card-item-title">
                                {{formatCnpj(fundService.data.document)}}
                            </span>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                GIIN
                            </span>

                            <span class="dash-details-card-item-title">
                                {{fundService.data.giin ?? '-'}}
                            </span>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-4 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                RAZÃO SOCIAL
                            </span>

                            <span class="dash-details-card-item-title">
                                {{fundService.data.name ?? '0*'}}
                            </span>
                        </div>
                        <div class="col-4 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                NOME FANTASIA
                            </span>

                            <span class="dash-details-card-item-title">
                                {{fundService.data.fantasy_name ?? '0*'}}
                            </span>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                1ª INTEGRALIZAÇÃO DE COTAS
                            </span>

                            <span class="dash-details-card-item-title">
                                {{
                                fundService.data.structuring_fee_date | date:
                                'dd/MM/yyyy' }}

                                {{fundService.data.structuring_fee_date ? '' :
                                '-'
                                }}
                            </span>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                PRAZO DE DURAÇÃO DO FUNDO
                            </span>

                            <span class="dash-details-card-item-title">
                                {{fundService.data.duration_period | date:
                                'dd/MM/yyyy'}}

                                {{fundService.data.duration_period ? '' : 'Prazo
                                indeterminado'
                                }}
                            </span>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                DATA DE CONSTITUIÇÃO
                            </span>

                            <span class="dash-details-card-item-title">
                                {{fundService.data.cbm_constitution_date | date:
                                'dd/MM/yyyy'}}

                                {{fundService.data.cbm_constitution_date ? '' :
                                '-'
                                }}
                            </span>
                        </div>

                        <div class="col-3 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                CLASSIFICAÇÃO CVM
                            </span>

                            <span class="dash-details-card-item-title">
                                {{getChoicesName('cvm_classification_choices',
                                fundService.data.cvm_classification)?.label}}
                            </span>
                        </div>

                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                CLASSIFICAÇÃO ANBIMA
                            </span>

                            <span class="dash-details-card-item-title">
                                {{getChoicesName('anbima_classification_choices',
                                fundService.data.anbima_classification)?.label}}
                            </span>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                PERFIL DO INVESTIDOR
                            </span>

                            <span class="dash-details-card-item-title">
                                {{getChoicesName('investor_profile_choices',
                                fundService.data.client_service?.profile)?.label}}
                            </span>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                PÚBLICO ALVO
                            </span>

                            <span class="dash-details-card-item-title">
                                {{getChoicesName('target_audience_choices',
                                fundService.data.target_audience)?.label ??
                                '-'}}
                            </span>
                        </div>

                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                EXERCÍCIO SOCIAL DO FUNDO
                            </span>

                            <span class="dash-details-card-item-title">
                                {{getChoicesName('fund_social_exercise_choices',
                                fundService.data.fund_social_exercise)?.label ??
                                '-'}}
                            </span>
                        </div>

                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                TIPO DE CONDOMÍNIO
                            </span>

                            <span class="dash-details-card-item-title">
                                {{
                                fundService.data.condominium_type_display ??
                                '-'}}
                            </span>
                        </div>

                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                ATIVOS FINANCEIROS
                            </span>

                            <span class="dash-details-card-item-title">
                                {{getChoicesName('financial_assets_choices',
                                fundService.data.financial_assets)?.label ??
                                '-'}}
                            </span>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                DATA DE ALTERAÇÃO
                            </span>

                            <span class="dash-details-card-item-title">
                                {{formatDate(fundService.data.updated)}}
                            </span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>