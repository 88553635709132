import { ApiService } from 'src/app/services/api/api.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { SignupService } from 'src/app/services/signup/signup.service';
import fakerbr from 'faker-br';
import { distinctUntilChanged } from 'rxjs/operators';
import Utils, { GetHelper, UtilsValidators } from 'src/app/helpers/utils';
import { ToastService } from 'src/app/services/toast/toast.service';
import { MaskPipe } from 'ngx-mask';

@Component({
  selector: 'app-signup-unified-person-complementary-bank',
  templateUrl: './signup-unified-person-complementary-bank.component.html',
  styleUrls: ['./signup-unified-person-complementary-bank.component.scss'],
})
export class SignupUnifiedPersonComplementaryBankComponent
  implements OnInit, OnDestroy
{
  @Input() userRole: string;

  form = this.formBuilder.group({
    bank: [null, []],
    agency: [null, []],
    account_number: [null, []],
    account_type: [null, []],
    account_digit: [null, []],
    joint_account: [false, []],

    main_account: [false, []],
    // not_owner: [false, []],
    // person_document_number: [null, []],
    // joint_holders_identification: [null, []],
  });

  accountTypes: SelectItem[] = [
    { label: 'Conta Corrente', value: '1' },
    { label: 'Conta Poupança', value: '2' },
  ];

  showForm = true;

  columns: TableColumn<PersonComplementaryBankValues>[] = [
    {
      name: 'Banco',
      cell: (row) => `
        <span>${row.bank ? this.formatBankName(row.bank.name) : ''}</span>
        <span class="table-subtitle">${
          row.main_account ? 'Conta Principal' : ''
        }</span>
      `,
    },
    { name: 'Agência', cell: (row) => row.agency },
    {
      name: 'Conta',
      cell: (row) =>
        `
      <span>
      ${
        row.account_digit
          ? row.account_number + '-' + row.account_digit
          : row.account_number
      }
      </span>
      <span class="table-subtitle">
      ${
        this.accountTypes.find(
          (item) => item.value === String(row.account_type)
        )?.label
      }
      </span>
         `,
    },
    // {
    //   name: 'Co-titular',
    //   cell: (row) => `
    //   <span>${row.joint_holders_identification ?? ''}</span>
    //   <span class="table-subtitle">${
    //     row.person_document_number
    //       ? this.formatDocument(row.person_document_number)
    //       : ''
    //   }</span>
    //   `,
    // },
  ];

  data: BankResponse[] = [];

  generalDocument: string | null = null;

  cpfErrorMessage = 'Documento inválido';

  banks: Bank[] = [];

  addAnotherModal = false;
  confirmMainAccountModal = false;

  removeModal = false;
  selectedIndex = 0;

  loading = true;

  sending = false;

  event: any;

  constructor(
    private formBuilder: FormBuilder,
    public signupService: SignupService,
    private api: ApiService,
    private toast: ToastService,
    private maskPipe: MaskPipe
  ) {}

  ngOnDestroy(): void {
    this.event.unsubscribe();
  }

  ngOnInit(): void {
    this.signupService.choicesFetchedEvent.subscribe((choices) => {
      this.loading = false;
    });

    this.event = this.signupService.sendPersonData.subscribe((value) => {
      if (value === 'complementaryBank') {
        this.signupService.setPersonStep('complementaryProfessional');
        this.signupService.changePersonStepEvent.emit('professional');
      }
    });

    document.onkeyup = (event) => {
      if (event.key === 'Enter' && !this.getDisabled()) {
        this.triggerForm();
      }
    };

    this.getDataAndFillForm();
    this.getGeneralData();

    this.banks = this.signupService.signupData.banks;

    this.form.controls.main_account.valueChanges.subscribe((value) => {
      if (value) {
        const hasMainAccount = this.data.some((item) => item.main_account);

        if (hasMainAccount) {
          this.confirmMainAccountModal = true;
        }
      }
    });

    // this.form.controls.not_owner.valueChanges.subscribe((value) => {
    //   if (value) {
    //     this.form.controls.person_document_number.setValidators([
    //       Validators.required,
    //       UtilsValidators.cpf,
    //     ]);
    //     this.form.controls.joint_holders_identification.setValidators([
    //       Validators.required,
    //     ]);
    //   } else {
    //     this.form.controls.person_document_number.setValidators([]);
    //     this.form.controls.person_document_number.setValue(null);
    //     this.form.controls.joint_holders_identification.setValidators([]);
    //     this.form.controls.joint_holders_identification.setValue(null);
    //   }

    //   this.form.controls.person_document_number.updateValueAndValidity();
    //   this.form.controls.joint_holders_identification.updateValueAndValidity();
    // });

    // this.form.controls.person_document_number.valueChanges
    //   .pipe(distinctUntilChanged((a: any, b: any) => a === b))
    //   .subscribe((value) => {
    //     this.cpfErrorMessage = 'Documento inválido';

    //     if (value && value.length === 14) {
    //       const formattedValue = Utils.onlyNumbers(value);

    //       if (formattedValue === this.generalDocument) {
    //         this.form.controls.person_document_number.setErrors({ cpf: true });

    //         this.cpfErrorMessage = 'CPF não pode ser igual ao seu documento';
    //       }
    //     }
    //   });

    this.form.controls.bank.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        if (value !== null) {
          this.form.controls.bank.setValidators([Validators.required]);
          this.form.controls.agency.setValidators([Validators.required]);
          this.form.controls.account_number.setValidators([
            Validators.required,
          ]);
          this.form.controls.account_type.setValidators([Validators.required]);

          if (this.userRole === 'provider') {
            this.signupService.setPersonFormStatus('complementaryBank', true);
          }
        } else {
          const validators =
            this.userRole !== 'provider' ? [Validators.required] : [];

          this.form.controls.bank.setValidators(validators);
          this.form.controls.agency.setValidators(validators);
          this.form.controls.account_number.setValidators(validators);
          this.form.controls.account_type.setValidators(validators);
          this.form.controls.account_digit.setValidators([]);

          this.form.controls.bank.setValue(null);
          this.form.controls.agency.setValue(null);
          this.form.controls.account_number.setValue(null);
          this.form.controls.account_digit.setValue(null);
          this.form.controls.account_type.setValue(null);

          if (this.userRole === 'provider') {
            this.signupService.setPersonFormStatus('complementaryBank', true);
          }
        }

        if (this.userRole !== 'provider') {
          this.form.statusChanges.subscribe((status) => {
            if (!this.addAnotherModal) {
              this.signupService.setPersonFormStatus(
                'complementaryBank',
                this.data.length > 0
              );
            }
          });
        }

        this.form.controls.bank.updateValueAndValidity();
        this.form.controls.agency.updateValueAndValidity();
        this.form.controls.account_number.updateValueAndValidity();
        this.form.controls.account_type.updateValueAndValidity();
        this.form.controls.account_digit.updateValueAndValidity();
      });

    this.signupService.fillFormEvent.subscribe(() => {
      this.form.patchValue({
        bank: String(fakerbr.random.number({ min: 1, max: 320 })),
        agency: String(fakerbr.random.number({ min: 1000, max: 9999 })),
        account_number: String(
          fakerbr.random.number({ min: 1000000, max: 9999999 }) +
            '-' +
            fakerbr.random.number({ min: 0, max: 9 })
        ),
        account_digit: String(fakerbr.random.number({ min: 1, max: 9 })),
        account_type: '1',
        joint_account: false,
        // person_document_number: '',
        // joint_holders_identification: '',
      });
    });

    // this.form.controls.joint_account.valueChanges.subscribe((value) => {
    //   if (value) {
    //     this.form.controls.cpf.setValidators([
    //       Validators.required,
    //       UtilsValidators.cpf,
    //     ]);
    //     this.form.controls.joint_holders_identification.setValidators([
    //       Validators.required,
    //     ]);
    //   } else {
    //     this.form.controls.cpf.setValidators([]);
    //     this.form.controls.joint_holders_identification.setValidators([]);
    //   }

    //   this.form.controls.cpf.updateValueAndValidity();
    //   this.form.controls.joint_holders_identification.updateValueAndValidity();
    // });
  }

  async getGeneralData() {
    try {
      const { data } = await this.api.get<ApiResponse<Person>>({
        route: 'api/registration/pf_general/me/',
        token: true,
      });

      this.generalDocument = data.document.number;
    } catch (error) {
      console.log(error);
    }
  }

  async getDataAndFillForm() {
    this.loading = true;
    try {
      const { data } = await this.api.get<ApiResponse<BankResponse[]>>({
        route: 'api/registration/bank_information/',
        token: true,
      });

      this.showForm = !(data.length > 0);

      this.signupService.setPersonFormStatus(
        'complementaryBank',
        data.length > 0
      );

      this.data = data;
    } catch (error) {
      if (error.status !== 404) {
        this.toast.show('error', 'Erro', error.error.message);
      }
    }
    this.loading = false;
  }

  async sendData() {
    this.sending = true;
    try {
      const valuesHelper = new GetHelper(this.form.value);

      const main_account = valuesHelper.get('main_account') ?? false;

      const filteredBanks = this.data.filter(
        (item) => item.bank.id === Number(valuesHelper.get('bank'))
      );

      const bankExists = filteredBanks.map((item) => {
        const agencyExistis =
          String(item.agency) === valuesHelper.get('agency');
        const checkingAccountExists =
          String(item.account_number) === valuesHelper.get('account_number');
        const checkingAccountDigitExists =
          item.account_digit === valuesHelper.get('account_digit');

        return (
          agencyExistis && checkingAccountExists && checkingAccountDigitExists
        );
      });

      if (bankExists.some((item) => item)) {
        this.toast.show('error', 'Erro', 'Banco já cadastrado');
        this.handleCloseModal();
        return;
      }

      if (main_account && this.data.length > 0) {
        const idx = this.data.findIndex((item) => item.main_account);

        await this.api.patch<ApiResponse<BankResponse>>({
          route: `api/registration/bank_information/${this.data[idx].id}/`,
          token: true,
          body: {
            main_account: false,
          },
        });
      }

      const payload = {
        bank: valuesHelper.get('bank'),
        agency: Number(Utils.onlyNumbers(valuesHelper.get('agency'))),
        account_number: Number(
          Utils.onlyNumbers(valuesHelper.get('account_number'))
        ),
        account_digit: valuesHelper.get('account_digit')
          ? Number(Utils.onlyNumbers(valuesHelper.get('account_digit')))
          : null,
        joint_account: valuesHelper.get('joint_account') ?? false,
        account_owner: true,
        // person_document_number: Utils.onlyNumbers(
        //   valuesHelper.get('person_document_number')
        // ),
        // joint_holders_identification: valuesHelper.get(
        //   'joint_holders_identification'
        // ),
        main_account: this.data.length > 0 ? main_account : true,
        account_type: '1',
      };

      console.table(valuesHelper.getRemainingObject());

      const response = await this.api.post<ApiResponse<BankResponse>>({
        route: 'api/registration/bank_information/',
        token: true,
        body: payload,
      });

      if (this.data.length > 0) {
        this.showForm = false;
      }

      this.handleCloseModal();

      this.toast.show('info', 'Sucesso', response.message);

      this.getDataAndFillForm();
    } catch (error) {
      console.error(error);

      this.toast.show('info', 'Erro', error.error.message);
    }
    this.sending = false;
  }

  handleOpenRemoveModal(index: number) {
    this.removeModal = true;
    this.selectedIndex = index;
  }

  handleRemoveCancel() {
    this.removeModal = false;
    this.confirmMainAccountModal = false;
    this.selectedIndex = 0;
  }

  handleConfirm(value: boolean) {
    this.confirmMainAccountModal = false;
    this.form.controls.main_account.setValue(value);

    this.form.controls.main_account.updateValueAndValidity();
  }

  async removeBank(index: number) {
    try {
      const findedBank = this.data[index];

      await this.api.delete({
        route: `api/registration/bank_information/${findedBank.id}/`,
        token: true,
      });

      this.removeModal = false;

      this.toast.show('info', 'Sucesso', 'Conta removida com sucesso');

      const filtered = this.data.filter((item) => item.id !== findedBank.id);
      const haveMainAccount = filtered.some((item) => item.main_account);

      if (filtered.length >= 1 && !haveMainAccount) {
        if (!filtered[0].main_account) {
          await this.api.patch<ApiResponse<BankResponse>>({
            route: `api/registration/bank_information/${filtered[0].id}/`,
            token: true,
            body: {
              main_account: true,
            },
          });
        }
      }

      this.getDataAndFillForm();
    } catch (error) {
      this.toast.show('error', 'Erro', error.error.message);
    }
  }

  getDisabled() {
    if (this.addAnotherModal) {
      return this.form.invalid || !this.form.controls.bank.value;
    }

    if (this.showForm) {
      if (this.form.controls.bank.value === null) {
        return true;
      } else {
        return this.form.invalid;
      }
    }

    return false;
  }

  triggerForm() {
    if (this.addAnotherModal) {
      this.sendData();
      this.addAnotherModal = false;
    } else {
      if (this.data.length === 0) {
        this.sendData();
      } else {
        this.addAnotherModal = true;
        this.signupService.setPersonFormStatus('complementaryBank', false);
      }
    }
  }

  closeModal() {
    this.addAnotherModal = false;
  }

  handleCloseModal() {
    this.addAnotherModal = false;
    this.signupService.setPersonFormStatus(
      'complementaryBank',
      this.data.length > 0
    );
    this.form.reset();
  }

  formatBankName(bankName: string) {
    if (bankName.length > 48) {
      const words = bankName.split(' ');
      const firstLine = words.slice(0, 6).join(' ');
      const secondLine = words.slice(6, words.length).join(' ');

      return secondLine.length > 0
        ? `${firstLine} <br> ${secondLine}`
        : `${firstLine}`;
    }
    return bankName;
  }

  formatDocument(value) {
    return this.maskPipe.transform(value, '000.000.000-00');
  }

  handleKeyEnter(event: any) {
    event.preventDefault();
    if (event.keyCode === 13 && this.form.valid) {
      this.triggerForm();
    }
  }
}
