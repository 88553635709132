<div class="row align-items-start">
    <div class="col-12 col-lg-6" *ngIf="canView.agent">
        <app-dashboard-settings-agents></app-dashboard-settings-agents>
        <app-spacer [bottom]="16"></app-spacer>
    </div>

    <div class="col-12 col-lg-6" *ngIf="canView.document">
        <app-dashboard-settings-documents></app-dashboard-settings-documents>
        <app-spacer [bottom]="16"></app-spacer>
    </div>

    <div class="col-12 col-lg-6" *ngIf="canView.zeroing">
        <app-dashboard-settings-zeroing-funds></app-dashboard-settings-zeroing-funds>
        <app-spacer [bottom]="16"></app-spacer>
    </div>

    <div class="col-12 col-lg-6" *ngIf="canView.audit">
        <app-dashboard-settings-audit></app-dashboard-settings-audit>
        <app-spacer [bottom]="16"></app-spacer>
    </div>

    <!-- <div class="col-12 col-lg-6">
      <app-dashboard-settings-drafts></app-dashboard-settings-drafts>
      <app-spacer [bottom]="16"></app-spacer>
    </div> -->

</div>