import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import Utils, { UtilsValidators } from 'src/app/helpers/utils';

import { ApiService } from 'src/app/services/api/api.service';
import { FundService, FundValues } from 'src/app/services/fund/fund.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import fakerbr from 'faker-br';

type CheckingBankValues = {
  checking_account_bank: number;
  checking_account_agency: string;
  checking_account_number: string;
  checking_account_number_digit: string;
};

type ChargeBankValues = {
  charge_account_bank: number;
  charge_account_agency: string;
  charge_account_number: string;
  charge_account_contract_number: string;
  charge_account_digit: string;
};

type ScrowBankValues = {
  escrow_account_bank: number;
};

type ZeroingBankValues = {
  zeroing_bank: number;
  zeroing_agency: string;
  zeroing_account: string;
  zeroing_fund: number;
};

type BankValues = {
  checking: CheckingBankValues[];
  charge: ChargeBankValues[];
  scrow: ScrowBankValues[];
  zeroing: ZeroingBankValues[];
};

type BankValuesKeys = keyof BankValues;

@Component({
  selector: 'app-dashboard-fund-approval-register-banking',
  templateUrl: './dashboard-fund-approval-register-banking.component.html',
  styleUrls: ['./dashboard-fund-approval-register-banking.component.scss'],
})
export class DashboardFundApprovalRegisterBankingComponent implements OnInit {
  form = this.formBuilder.group({
    checking_account_bank: [null, []],
    checking_account_agency: [null, []],
    checking_account_number: [null, []],
    checking_account_number_digit: [null, []],
    charge_account: [false, []],
    charge_account_bank: [null, []],
    charge_account_agency: [null, []],
    charge_account_number: [null, []],
    charge_account_contract_number: [null, []],
    charge_account_digit: [null, []],
    escrow_account: [false, []],
    escrow_account_bank: [null, []],
    zeroing_process: [null, []],
    zeroing_fund: [null, []],
    zeroing_bank: [null, []],
    zeroing_agency: [null, []],
    zeroing_account: [null, []],
    zeroing_committed: [null, []],
    manager_name: [null, []],
    manager_email: [null, []],
    manager_phone: [null, []],
    cblc: [false, []],
    cblc_contract_number: [null, []],
    cetip: [false, []],
    cetip_contract_number: [null, []],
    selic: [false, []],
    selic_contract_number: [null, []],
    guaranteed_cblc: [false, []],
    guaranteed_cblc_contract_number: [null, []],
    guaranteed_bmf: [false, []],
    guaranteed_bmf_contract_number: [null, []],
  });

  fund: FundValues = undefined;

  zeroingChoices = [];

  loading = true;

  bankAccountList: BankValues = {
    checking: [],
    charge: [],
    scrow: [],
    zeroing: [],
  };

  constructor(
    private formBuilder: FormBuilder,
    public fundService: FundService,
    public signupService: SignupService,
    private api: ApiService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((status) => {
      Utils.getErrors(this.form);
    });

    this.signupService.fetchDataEvent.emit();
    this.getZeroing();

    this.form.controls.zeroing_committed.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.manager_name.setValidators([Validators.required]);
        this.form.controls.manager_email.setValidators([
          Validators.required,
          Validators.email,
        ]);
        this.form.controls.manager_phone.setValidators([
          Validators.required,
          UtilsValidators.telefone,
        ]);
      } else {
        this.form.controls.manager_name.setValidators([]);
        this.form.controls.manager_email.setValidators([]);
        this.form.controls.manager_phone.setValidators([]);
      }

      this.form.controls.manager_name.updateValueAndValidity();
      this.form.controls.manager_email.updateValueAndValidity();
      this.form.controls.manager_phone.updateValueAndValidity();
    });

    this.form.valueChanges.subscribe((values) => {
      this.fundService.setFundFormData({
        ...this.fundService.data,
        zeroing_committed: values.zeroing_committed,
        manager_name: values.manager_name,
        manager_email: values.manager_email,
        manager_phone: values.manager_phone,
        client_service: {
          ...this.fundService?.data?.client_service,
          ...values,
          checking_account_bank: values.checking_account_bank
            ? Number(values.checking_account_bank)
            : null,
        },
        zeroing_automatic: {
          zeroing_fund: values.zeroing_fund,
          bank: values.zeroing_bank,
          account: values.zeroing_account,
          agency: values.zeroing_agency,
        },
      });
    });

    this.form.statusChanges.subscribe((status) =>
      this.fundService.setFundFormStatus('banking', status === 'VALID')
    );

    this.setRandomData();
  }

  setRandomData() {
    this.signupService.fillFormEvent.subscribe((value) => {
      this.form.patchValue({
        checking_account_bank: String(
          fakerbr.random.number({ min: 1, max: 320 })
        ),
        checking_account_agency: String(
          fakerbr.random.number({ min: 1000, max: 9999 })
        ),
        checking_account_number: String(
          fakerbr.random.number({ min: 1000000, max: 9999999 })
        ),
        checking_account_number_digit: String(
          fakerbr.random.number({ min: 1, max: 9 })
        ),
        account_digit: String(fakerbr.random.number({ min: 1, max: 9 })),
        charge_account: fakerbr.random.boolean(),
        escrow_account: fakerbr.random.boolean(),
        zeroing_process: fakerbr.random.boolean(),
        cblc: fakerbr.random.boolean(),
        cetip: fakerbr.random.boolean(),
        selic: fakerbr.random.boolean(),
        guaranteed_cblc: fakerbr.random.boolean(),
        guaranteed_bmf: fakerbr.random.boolean(),
      });
    });
  }

  setData(values: FundValues) {
    this.fund = values;

    if (values) {
      this.form.patchValue({
        ...values,
        ...values.client_service,
        checking_account_bank:
          values.client_service && values.client_service.checking_account_bank
            ? String(values.client_service.checking_account_bank)
            : null,
        charge_account_bank:
          values.client_service && values.client_service.charge_account_bank
            ? String(values.client_service.charge_account_bank)
            : null,
        escrow_account_bank:
          values.client_service && values.client_service.escrow_account_bank
            ? String(values.client_service.escrow_account_bank)
            : null,
        zeroing_fund:
          values.zeroing_automatic && values.zeroing_automatic.zeroing_fund
            ? values.zeroing_automatic.zeroing_fund
            : null,
        zeroing_bank:
          values.zeroing_automatic && values.zeroing_automatic.bank
            ? String(values.zeroing_automatic.bank)
            : null,
        zeroing_agency:
          values.zeroing_automatic && values.zeroing_automatic.agency
            ? values.zeroing_automatic.agency
            : null,
        zeroing_account:
          values.zeroing_automatic && values.zeroing_automatic.account
            ? values.zeroing_automatic.account
            : null,
        zeroing_process: values.zeroing_process,
      });
    }

    this.fundService.setFundFormStatus('banking', this.form.valid);
  }

  async getZeroing() {
    try {
      const res = await this.api.get({
        route: 'api/registration/zeroing_fund',
        token: true,
      });

      const zeroingOptions = res.map((_zeroing: any) => {
        return {
          label: _zeroing.corporate_name,
          value: _zeroing.id,
        };
      });

      this.zeroingChoices = zeroingOptions;
    } catch (error) {
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }

    this.setData(this.fundService.data);
    this.loading = false;
  }

  getFieldsValid(type: BankValuesKeys) {
    let valid = true;

    switch (type) {
      case 'checking':
        if (
          !this.form.controls.checking_account_bank.value ||
          !this.form.controls.checking_account_agency.value ||
          !this.form.controls.checking_account_number.value ||
          !this.form.controls.checking_account_number_digit.value
        ) {
          valid = false;
        }
        break;

      case 'charge':
        if (
          !this.form.controls.charge_account_bank.value ||
          !this.form.controls.charge_account_agency.value ||
          !this.form.controls.charge_account_number.value ||
          !this.form.controls.charge_account_digit.value ||
          !this.form.controls.charge_account_contract_number.value
        ) {
          valid = false;
        }
        break;

      case 'scrow':
        if (!this.form.controls.escrow_account_bank.value) {
          valid = false;
        }
        break;

      case 'zeroing':
        if (
          !this.form.controls.zeroing_fund.value ||
          !this.form.controls.zeroing_bank.value ||
          !this.form.controls.zeroing_agency.value ||
          !this.form.controls.zeroing_account.value
        ) {
          valid = false;
        }
        break;

      default:
        break;
    }

    return valid;
  }

  addBankAcccount(type: BankValuesKeys) {
    switch (type) {
      case 'checking':
        if (this.getFieldsValid(type)) {
          this.bankAccountList.checking.push({
            checking_account_bank:
              this.form.controls.checking_account_bank.value,
            checking_account_agency:
              this.form.controls.checking_account_agency.value,
            checking_account_number:
              this.form.controls.checking_account_number.value,
            checking_account_number_digit:
              this.form.controls.checking_account_number_digit.value,
          });

          this.form.controls.checking_account_bank.setValue(null);
          this.form.controls.checking_account_agency.setValue(null);
          this.form.controls.checking_account_number.setValue(null);
          this.form.controls.checking_account_number_digit.setValue(null);
        }

        break;

      case 'charge':
        if (this.getFieldsValid(type)) {
          this.bankAccountList.charge.push({
            charge_account_bank: this.form.controls.charge_account_bank.value,
            charge_account_agency:
              this.form.controls.charge_account_agency.value,
            charge_account_number:
              this.form.controls.charge_account_number.value,
            charge_account_digit: this.form.controls.charge_account_digit.value,
            charge_account_contract_number:
              this.form.controls.charge_account_contract_number.value,
          });

          this.form.controls.charge_account_bank.setValue(null);
          this.form.controls.charge_account_agency.setValue(null);
          this.form.controls.charge_account_number.setValue(null);
          this.form.controls.charge_account_digit.setValue(null);
          this.form.controls.charge_account_contract_number.setValue(null);
        }

        break;

      case 'scrow':
        if (this.getFieldsValid(type)) {
          this.bankAccountList.scrow.push({
            escrow_account_bank: this.form.controls.escrow_account_bank.value,
          });

          this.form.controls.escrow_account_bank.setValue(null);
        }

        break;

      case 'zeroing':
        if (this.getFieldsValid(type)) {
          this.bankAccountList.zeroing.push({
            zeroing_bank: this.form.controls.zeroing_bank.value,
            zeroing_agency: this.form.controls.zeroing_agency.value,
            zeroing_account: this.form.controls.zeroing_account.value,
            zeroing_fund: this.form.controls.zeroing_fund.value,
          });

          this.form.controls.zeroing_bank.setValue(null);
          this.form.controls.zeroing_agency.setValue(null);
          this.form.controls.zeroing_account.setValue(null);
          this.form.controls.zeroing_fund.setValue(null);
        }

        break;

      default:
        break;
    }
  }

  removeBankAccount(type: BankValuesKeys, index: number) {
    switch (type) {
      case 'checking':
        this.bankAccountList.checking.splice(index, 1);
        break;

      case 'charge':
        this.bankAccountList.charge.splice(index, 1);
        break;

      case 'scrow':
        this.bankAccountList.scrow.splice(index, 1);
        break;

      case 'zeroing':
        this.bankAccountList.zeroing.splice(index, 1);
        break;

      default:
        break;
    }
  }

  formatBankName(value: number) {
    const bank = this.signupService
      .choices()
      .banks.find((bank) => bank.value === String(value));
    return bank ? bank.label : '';
  }

  formatFundName(value: number) {
    const fund = this.zeroingChoices.find(
      (fund) => fund.value === String(value)
    );
    return fund ? fund.label : '';
  }
}
