import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SignupService } from 'src/app/services/signup/signup.service';
import { MaskPipe } from 'ngx-mask';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard-approval-representatives',
  templateUrl: './dashboard-approval-representatives.component.html',
  styleUrls: ['./dashboard-approval-representatives.component.scss'],
})
export class DashboardApprovalRepresentativesComponent implements OnInit {
  @Input() approval: UserApprovalResponse;
  @ViewChild('modalRoot') modalRoot;

  columns: TableColumnList<Representative>[] = [
    {
      name: 'Nome',
      cell: (row) => `
          <span>${row.full_name}
      </span>
          <span class="app-table-details-body-row-subtitle">
          ${this.formatCpf(row.document_number)}
          </span>
        `,
    },
    {
      name: 'Assinado',
      align: 'center',
      cell: (row) => {
        const sign = this.signs.find(
          (sign) => sign.register.id === row.representative_register.id
        );

        if (sign && sign.signed) {
          return '<i class="fas fa-check text-success"></i>';
        }

        return '<i class="fas fa-times text-danger"></i>';
      },
    },
    {
      name: 'Mandato',
      cell: (row) =>
        `<div>de ${this.formatDate(row.min_term_validity)}</div>
        <div>até ${
          row.expiration_date
            ? this.formatDate(row.expiration_date)
            : 'indeterminado'
        }</div>`,
    },
    {
      name: 'Representante',
      align: 'center',
      cell: (row) => {
        if (row.is_representative) {
          return '<i class="fas fa-check text-success"></i>';
        }

        return '<i class="fas fa-times text-danger"></i>';
      },
    },
    {
      name: 'Avalista',
      align: 'center',
      cell: (row) => {
        if (row.guarantor) {
          return '<i class="fas fa-check text-success"></i>';
        }

        return '<i class="fas fa-times text-danger"></i>';
      },
    },
    {
      name: 'Devedor Solidario',
      align: 'center',
      cell: (row) => {
        if (row.solidarity_debtor) {
          return '<i class="fas fa-check text-success"></i>';
        }

        return '<i class="fas fa-times text-danger"></i>';
      },
    },
    {
      name: '',
      cell: (row) =>
        `<div class="icon-container">
            <div class="representative-details row-${row.id}">
                <i class="fas fa-eye ml-auto"></i>
            </div>
        </div> `,
    },
  ];

  isContribuitor = false;

  representativeDetails = false;
  selectedRepresentative: Representative = null;

  data: Representative[] = [];

  pepColumns: TableColumnList<CompanyComplementaryPepListValues>[] = [
    {
      name: 'Nome',
      cell: (row) => `
    <span>${row.name}</span>
    <span class="app-table-details-body-row-subtitle">${row.document}</span>
  `,
      size: 2,
    },
    { name: 'Vínculo', cell: (row) => row.bound },
  ];

  pepData: CompanyComplementaryPepListValues[] = [
    {
      name: '0*',
      document: '0*',
      bound: '0*',
    },
    {
      name: '0*',
      document: '0*',
      bound: '0*',
    },
  ];

  showModal = false;
  file: Files = null;

  isPDF = false;
  url: any;
  isXLS = false;

  cities_options: SelectItem[] = [];

  signs: CompanySign[] = [];

  constructor(
    private signupService: SignupService,
    private maskPipe: MaskPipe,
    private api: ApiService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.data = this.approval.register.representatives;
    this.signs = this.approval.register.company?.signs || [];
  }

  ngAfterViewInit() {
    this.setHandlers();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.data = changes.approval.currentValue.representatives;
    setTimeout(() => {
      this.setHandlers();
    }, 1000);
  }

  setHandlers() {
    const representative = document.querySelectorAll('.representative-details');

    representative.forEach((button) => {
      button.addEventListener('click', (event) => {
        const el: any = event.currentTarget;
        const idClass: any = el.classList.item(1);
        const id = idClass.split('-')[1];

        const finded = this.data.find((item: any) => {
          if (item.id) {
            return Number(item.id) === Number(id);
          }

          return false;
        });

        if (finded) {
          this.openModal(finded);
        }
      });
    });
  }

  closeModal() {
    this.representativeDetails = false;
  }

  openModal(item: Representative) {
    this.representativeDetails = true;
    this.selectedRepresentative = item;

    this.isContribuitor = this.selectedRepresentative.representative_register
      .department
      ? true
      : false;
  }

  getChoicesName(object: string, value: any) {
    const choices = this.signupService.signupChoices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  getNacionality(value: any) {
    const choices = this.signupService.nationalityChocies;

    if (value) {
      return choices.find((_choice) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  getFullName(representative: any) {
    if (representative) {
      return `${representative.first_name} ${representative.last_name}`;
    }
    return '';
  }

  formatCpf(value: string) {
    const newValue = this.maskPipe.transform(value, '000.000.000-00');

    return newValue;
  }

  formatRg(value: string) {
    const newValue = this.maskPipe.transform(value, '00.000.000-0');

    return newValue;
  }

  formatDocument(document: any) {
    if (document.type === 'CPF') {
      return this.formatCpf(document.number);
    }

    if (document.type === 'RG') {
      return this.formatRg(document.number);
    }

    return document.number;
  }

  formatNumber(cellphone: any) {
    const numberWithoutDDI = cellphone.number.slice(-11);
    const ddi = numberWithoutDDI.slice(0, 2);
    const onlyNumber = numberWithoutDDI.slice(2);
    const formatPhone = this.maskPipe.transform(onlyNumber, '00000-0000');

    const formatedPhone = `+${cellphone.code} (${ddi}) ${formatPhone}`;
    return formatedPhone;
  }

  formatDate(date?: string) {
    return date ? date.split('-').reverse().join('/') : '-';
  }

  formatBrl(amount: string) {
    const formatted = Utils.formatBrl(Number(amount));

    return formatted;
  }

  formatAddress(address: any) {
    const formatedAddress = `
    ${address.public_place}, ${address.number}, ${address.complement ?? ''}\n${
      address.district
    }, ${address.city} - ${address.uf}

    `;

    return formatedAddress;
  }

  handleSelect(idx: any) {
    this.file = this.selectedRepresentative.files[idx];
    this.getImgUrl();
  }

  getImgUrl() {
    const regex = /\.([0-9a-z]+)(?:[\?#]|$)/i;

    const resultado = regex.exec(this.file.file);

    if (resultado && resultado[1]) {
      const extension = resultado[1].toLowerCase();

      switch (extension) {
        case 'pdf':
          this.isPDF = true;
          break;
        case 'xls':
        case 'xlsx':
          this.isXLS = true;
          break;
        default:
          this.isPDF = false;
          this.isXLS = false;
          break;
      }
    }

    let url: any = this.file.file;

    if (this.isPDF) {
      url = this.sanitizer.bypassSecurityTrustResourceUrl(this.file.file);
    }

    if (this.isXLS) {
      url = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://view.officeapps.live.com/op/embed.aspx?src=${this.file.file}`
      );
    }

    this.url = url;

    this.modalRoot.show();
  }
}
