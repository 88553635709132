import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

type ContributorsPermissions = {
  department: boolean;
  collaborator: boolean;
  witnesses: boolean;
};
@Component({
  selector: 'app-dashboard-contributors',
  templateUrl: './dashboard-contributors.component.html',
  styleUrls: ['./dashboard-contributors.component.scss'],
})
export class DashboardContributorsComponent implements OnInit {
  canView: ContributorsPermissions = {
    department: false,
    witnesses: false,
    collaborator: false,
  };

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.setPermissions();
  }

  setPermissions() {
    const permissionArr = [
      'de_can_view_department',
      'de_can_view_witnesses',
      'de_can_view_collaborator',
    ];

    permissionArr.forEach((permission) => {
      const label = permission.split('_')[3];

      this.canView[`${label}`] = this.authService.verifyPermission([
        permission,
      ]);
    });
  }
}
