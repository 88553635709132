<div class="dashboard h-100">
    <div class="dashboard-card no-padding d-flex flex-column justify-content-between"
        style="max-width: 800px">
        <div class="row">
            <div class="col-12">
                <div class="padding-container d-flex flex-column">
                    <ng-container *ngIf="loadingAudits">
                        <ngx-skeleton-loader [theme]="{
                            height: '24px',
                            width: '30px',
                            margin:'0'
                          }">
                        </ngx-skeleton-loader>

                        <ngx-skeleton-loader [theme]="{
                                height: '27px',
                                width: '100px',
                            marginBottom:'30px'
                              }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loadingAudits">
                        <span class="card-info-number">
                            {{audits.length > 9 ? '' :
                            0}}{{audits.length}}
                        </span>
                        <span class="card-info-title">Auditorias</span>
                    </ng-container>
                </div>
            </div>

            <div class="col-12 dashboard-card-body">
                <ng-container *ngIf="loadingAudits">
                    <table #table>
                        <thead>
                            <tr>
                                <th>
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '125px',
                                    marginBottom:'0'
                                  }">
                                    </ngx-skeleton-loader>
                                </th>

                                <th class="text-right">
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '125px',
                                    marginBottom:'0'
                                  }">
                                    </ngx-skeleton-loader>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let number of [0,1,2,3]">
                                <td>
                                    <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '100px',
                                    marginBottom:'0'
                                      }">
                                    </ngx-skeleton-loader>
                                </td>
                                <td class="item-number">
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '30px',
                                    marginBottom:'0'
                                  }">
                                    </ngx-skeleton-loader>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <table #table *ngIf="!loadingAudits">
                    <thead>
                        <tr>
                            <th>EMPRESA</th>
                            <th style="text-align:right;">Vínculos</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let audit of visible_audits">
                            <td>
                                {{audit.corporate_name}}
                            </td>
                            <td class="item-number">
                                {{audit.funds.length ?? '0*'}}
                                <div class="item-number-options">
                                    <ng-container
                                        *ngIf="audit.funds.length === 0">
                                        <button (click)="removeaudit(audit.id)"
                                            *ngIf="permissionObj.canDelete">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </ng-container>
                                    <app-spacer [right]="19"></app-spacer>
                                    <button (click)="setaudit(audit)"
                                        *ngIf="permissionObj.canChange">
                                        <i class="fas fa-pen"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-12">
                <div
                    class="padding-container d-flex flex-row justify-content-between">
                    <ng-container *ngIf="loadingAudits">
                        <td>
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                        width: '180px',
                            marginBottom:'0'
                              }">
                            </ngx-skeleton-loader>

                        <td>
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                width: '180px',
                                marginBottom:'0'
                                  }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container *ngIf="!loadingAudits">
                        <app-button [inverted]="true"
                            (onClick)="redirect('/app/settings/audits')">
                            Ver todos
                        </app-button>

                        <app-button (click)="addAuditToggle(true)"
                            class="add-button" *ngIf="permissionObj.canAdd">
                            <i class="fas fa-plus-circle mr-2"></i>Adicionar
                            Auditoria
                        </app-button>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 " *ngIf="audits.length === 0 && !loadingAudits">
                <div class="empty-card">
                    <div class="img-container">
                        <img src="/assets/images/audit-icon.png" alt="icone">
                    </div>

                    <ng-container *ngIf="!permissionObj.canAdd">
                        <h3>
                            Ainda não existem auditorias cadastradas
                        </h3>
                    </ng-container>

                    <ng-container *ngIf="permissionObj.canAdd">
                        <h3>Adicione Auditoria</h3>
                        <p>
                            Adicione uma nova empresa para auditoria no botão
                            Adicionar Auditoria
                        </p>

                        <app-button (click)="addAuditToggle(true)"
                            class="add-button">
                            <i class="fas fa-plus-circle mr-2"></i>Adicionar
                            Auditoria
                        </app-button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<app-side-modal [active]="addAudit" (onClose)="closeModal()"
    [title]="audit?'Atualizar Auditoria':'Adicionar Auditoria'"
    paddingBottom="30">
    <form [formGroup]="auditForm">
        <div class="row">
            <div class="col-12">
                <app-masked-input label="CNPJ" [group]="auditForm"
                    controlName="document" placeholder="12.345.678/0001-99"
                    mask="00.000.000/0000-00">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Razão social" [group]="auditForm"
                    controlName="corporate_name" [isUpperCase]="true"
                    placeholder="ACME AUDITORIA DE ZERAGEM LTDA">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6">
                <app-input type="date" label="Data de constituição"
                    [group]="auditForm" controlName="constitution_date"
                    placeholder="ACME AUDITORIA DE ZERAGEM LTDA">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6">
                <app-input label="E-mail" [group]="auditForm"
                    controlName="email" placeholder="email@mail.com.br">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <!-- <app-input label="Ramo de atividade" [group]="auditForm"
                    controlName="branch">
                </app-input> -->

                <app-select [items]="signupService.choices().cnaes"
                    [group]="auditForm" controlName="branch" label="CNAE">
                </app-select>
                <app-spacer [bottom]="56"></app-spacer>
            </div>

            <div class="col-12 col-md-6 cep-container">
                <app-masked-input [group]="auditForm" controlName="zipcode"
                    label="CEP" mask="00000-000" placeholder="99887-766"
                    (input)="handleSearchZipcode($event)">
                </app-masked-input>
                <i *ngIf="loading"
                    class="fas fa-circle-notch fa-spin app-button-icon"></i>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <ng-container *ngIf="zipcodeFetched">
                <div class="col-12 col-md-9">
                    <app-input [group]="auditForm" controlName="public_place"
                        label="Logradouro" [maxLength]="30"
                        [readOnly]="addressObj.public_place !== ''">
                    </app-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-3">
                    <app-input [group]="auditForm" controlName="number"
                        type="number" label="Número">
                    </app-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-6">
                    <app-input [group]="auditForm" controlName="complement"
                        [maxLength]="20" label="Complemento">
                    </app-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-6">
                    <app-input [group]="auditForm" controlName="district"
                        [maxLength]="30" label="Bairro"
                        [readOnly]="addressObj.district !== ''">
                    </app-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-9">
                    <app-input [group]="auditForm" controlName="city"
                        [maxLength]="30" label="Cidade"
                        [readOnly]="addressObj.city !== ''">
                    </app-input>
                    <app-spacer [bottom]="16"></app-spacer>

                </div>

                <div class="col-12 col-md-3">
                    <app-select [items]="signupService.ufs" [group]="auditForm"
                        controlName="uf" label="UF" placeholder="SP"
                        [readOnly]="addressObj.uf !== ''">
                    </app-select>
                    <app-spacer [bottom]="16"></app-spacer>

                </div>

                <div class="col-12 col-lg-4">
                    <app-select [items]="signupService.nationalityChocies"
                        [group]="auditForm" controlName="country"
                        [readOnly]="addressObj.country !== ''" label="País">
                    </app-select>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </ng-container>
        </div>

        <app-spacer [bottom]="48"></app-spacer>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="closeModal()" class="add-button"
                    [outline]="true" [bordless]="true">
                    Cancelar
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="addNewAudit()" class="add-button"
                        [disabled]="getDisabled()">
                        {{audit ?'Atualizar' :'Adicionar'}} Auditoria
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>