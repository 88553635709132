import { EventEmitter, Injectable } from '@angular/core';
import Utils from 'src/app/helpers/utils';

import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';

type BasicForms = {
  personal: BasicPersonalValues | null;
  password: BasicPasswordValues | null;
  profile: BasicProfileValues | null;
};

type BasicFormKeys = keyof BasicForms;

type PersonForms = {
  complementaryGeneral: PersonComplementaryGeneralValues | null;
  complementaryProfessional: PersonComplementaryProfessionalValues | null;
  complementaryRepresentatives: ComplementaryRepresentativesValues[] | null;
  complementaryAddress: ComplementaryAddressValues | null;
  complementaryBank: PersonComplementaryBankValues[];
  complementaryBankForm: PersonComplementaryBankValues | null;

  //   TODO arrumar isso dps
  incomeRealty: IncomeRealtyValues[];
  incomeRealtyForm: IncomeRealtyValues | null;
  incomeOthers: IncomeOthersValues[];
  incomeOthersForm: IncomeOthersValues | null;
  incomeSalary: PersonIncomeSalaryValues | null;
  income: IncomeValues | null;

  declarations: PersonDeclarationsValues | null;
  analysis: AnalysisValues | null;
  conditions: ConditionsValues | null;
  tax: TaxValues | null;
  attachments: Attachment[];
  haveAgent: boolean;
};

export type PersonFormKeys = keyof PersonForms;

type CompanyForms = {
  complementaryGeneral: CompanyComplementaryGeneralValues | null;
  complementaryComposition: CompanyComplementaryCompositionValues[] | null;
  complementaryRepresentatives: ComplementaryRepresentativesValues[] | null;
  complementaryRepresentativesForm: ComplementaryRepresentativesValues | null;
  complementaryEconomic: CompanyComplementaryEconomicValues[] | null;
  complementaryEconomicForm: CompanyComplementaryEconomicValues | null;
  complementaryAddress: ComplementaryAddressValues | null;
  complementaryBank: CompanyComplementaryBankValues[] | null;
  complementaryBankForm: CompanyComplementaryBankValues | null;
  complementaryBond: CompanyComplementaryBondValues[] | null;
  complementaryAdmin: CompanyComplementaryAdminValues | null;
  complementaryManager: CompanyComplementaryManagerValues | null;

  incomeRealty: IncomeRealtyValues[] | null;
  incomeRealtyForm: IncomeRealtyValues | null;
  incomeOthers: IncomeOthersValues[] | null;
  incomeOthersForm: IncomeOthersValues | null;
  incomeSalary: CompanyIncomeSalaryValues | null;
  income: any;

  declarations: CompanyDeclarationsValues | null;
  analysis: AnalysisValues | null;
  conditions: ConditionsValues | null;
  tax: TaxValues | null;
  attachments: Attachment[] | null;
  powers: RelevancePowerValues | null;
  relevancePowers: RelevanceGroupValues | null;
};

export type CompanyFormKeys = keyof CompanyForms;

type SignUpChoice = [number, string];

export type SignUpChoices = {
  choices_data: { [Key: string]: any };
};

export type BasicInfo = {
  email: string;
  name: string;
  document: string;
};

@Injectable({
  providedIn: 'root',
})
export class SignupService {
  // Basic Form

  basicFormData: BasicForms = {
    personal: null,
    password: null,
    profile: null,
  };

  basicFormValid: { [key in BasicFormKeys]: boolean } = {
    personal: false,
    password: false,
    profile: false,
  };

  // Person Form

  personFormData: PersonForms = {
    complementaryGeneral: null,
    complementaryProfessional: null,
    complementaryRepresentatives: [],
    complementaryAddress: null,
    complementaryBank: [],
    complementaryBankForm: null,

    incomeRealty: [],
    incomeRealtyForm: null,
    incomeOthers: [],
    incomeOthersForm: null,
    incomeSalary: {
      salary: '0',
      others_income: '0',
    },
    income: null,

    declarations: null,
    analysis: null,
    conditions: null,
    tax: null,
    attachments: [],

    haveAgent: false,
  };

  personFormValid: { [key in PersonFormKeys]: boolean } = {
    complementaryGeneral: false,
    complementaryProfessional: false,
    complementaryRepresentatives: true,
    complementaryAddress: false,
    complementaryBank: false,
    complementaryBankForm: true,

    incomeRealty: false,
    incomeRealtyForm: true,
    incomeOthers: false,
    incomeOthersForm: true,
    incomeSalary: true,
    income: false,

    declarations: false,
    analysis: false,
    conditions: false,
    tax: false,
    attachments: false,
    haveAgent: false,
  };

  // Company Form

  companyFormData: CompanyForms = {
    complementaryGeneral: null,
    complementaryComposition: [],
    complementaryRepresentatives: [],
    complementaryRepresentativesForm: null,
    complementaryEconomic: [],
    complementaryEconomicForm: null,
    complementaryAddress: null,
    complementaryBank: [],
    complementaryBankForm: null,
    complementaryBond: [],
    complementaryAdmin: null,
    complementaryManager: null,

    incomeRealty: [],
    incomeRealtyForm: null,
    incomeOthers: [],
    incomeOthersForm: null,
    incomeSalary: {
      patrimony: '0',
      revenues: '0',
      position: Utils.todayString(),
    },
    income: null,

    declarations: null,
    analysis: null,
    conditions: null,
    tax: null,
    attachments: [],
    powers: null,
    relevancePowers: null,
  };

  companyFormValid: { [key in CompanyFormKeys]: boolean } = {
    complementaryGeneral: false,
    complementaryComposition: false,
    complementaryRepresentatives: false,
    complementaryRepresentativesForm: false,
    complementaryEconomic: true,
    complementaryEconomicForm: true,
    complementaryAddress: false,
    complementaryBank: false,
    complementaryBankForm: true,
    complementaryBond: false,
    complementaryAdmin: false,
    complementaryManager: false,

    incomeRealty: true,
    incomeRealtyForm: true,
    incomeOthers: true,
    incomeOthersForm: true,
    incomeSalary: false,
    income: false,

    declarations: false,
    analysis: false,
    conditions: false,
    tax: false,
    attachments: false,
    powers: false,
    relevancePowers: true,
  };

  signupChoices: { [Key: string]: SelectItem[] } = {
    address_type_choises: [],
    banks: [],
    document_types: [],
    gender_choices: [],
    marital_choices: [],
    marital_statute: [],
    representative_link: [],
    requested_files: [],
    sign_power_choices: [],
  };

  complementarySteps = {};

  basicInfo: BasicInfo | null = null;

  nationalityChocies: SelectItem[] = [];

  documentsChoices: SelectItem[] = [];

  signPowerChoices: SelectItem[] = [];
  activeStep = 0;

  signatureAuthorizationChoices: SelectItem[] = [];

  roles: Role[] = [];

  complementaryData: any;

  naturalness: SelectItem[] = [];
  ufs: SelectItem[] = [];
  ufs_ids: SelectItem[] = [];
  ufs_names: SelectItem[] = [];
  issuingAgencies: SelectItem[] = [];

  _signupData: { [Key: string]: any } = {};

  loading = false;

  complementaryCompanyStep: ComplementaryCompanySteps = 'complementaryGeneral';
  personStep: PersonSteps = 'complementaryGeneral';

  get signupData(): { [Key: string]: any } {
    return this._signupData;
  }

  set signupData(value: { [Key: string]: any }) {
    this._signupData = value;

    if (value) {
      const newNaturalness: SelectItem[] = value.states.map(
        (_state: State) => _state.name
      );

      const newUfs = value.states.map((_state: State) => _state.initials);

      const newUfsNames = value.states.map((_state: State) => _state.name);

      const newUfsIds = value.states.map((_state: State) => ({
        label: _state.initials,
        value: _state.id,
      }));

      const newIssuingAgencies = value.issuing_agencies.map(
        (_agency: IssuingAgency) => _agency.full_name
      );

      this.naturalness = newNaturalness;
      this.ufs = newUfs;
      this.ufs_ids = newUfsIds;
      this.ufs_names = newUfsNames;
      this.issuingAgencies = newIssuingAgencies;
    }
  }

  choicesFetchedEvent = new EventEmitter();

  submitEvent = new EventEmitter();

  personStatusChangeEvent = new EventEmitter();

  companyStatusChangeEvent = new EventEmitter();

  fillFormEvent = new EventEmitter();

  fetchDataEvent = new EventEmitter();

  complementaryDataFetchedEvent = new EventEmitter();

  submitBasicForm = new EventEmitter();

  representativeAddedEvent = new EventEmitter();

  changeCompanyStepEvent = new EventEmitter<Partial<Substep>>();

  changeBasicInfoEvent = new EventEmitter<String>();

  changePersonStepEvent = new EventEmitter<Partial<Substep>>();

  sendComplementaryCompanyData = new EventEmitter();
  sendPersonData = new EventEmitter();
  sendCompanyData = new EventEmitter();

  constructor(private api: ApiService, private authService: AuthService) {}

  setSignupChoices(choices: { [Key: string]: SelectItem[] }) {
    this.signupChoices = choices;
    this.choicesFetchedEvent.emit();
  }

  getSignupChoices() {
    return this.signupChoices;
  }

  choices() {
    return this.signupChoices;
  }

  setNationalityChoices(choices: SelectItem[]) {
    this.nationalityChocies = choices;
  }

  getNationalityChoices() {
    return this.nationalityChocies;
  }

  setRoles(roles: Role[]) {
    this.roles = roles;
  }

  getRoles() {
    return this.roles;
  }

  setComplementaryCompanyStep(step: ComplementaryCompanySteps) {
    this.complementaryCompanyStep = step;
  }

  getComplementaryCompanyStep() {
    return this.complementaryCompanyStep;
  }

  setPersonStep(step: PersonSteps) {
    this.personStep = step;
  }

  setCompanyStep(step: ComplementaryCompanySteps) {
    this.complementaryCompanyStep = step;
  }

  getPersonStep() {
    return this.personStep;
  }

  /*
   *
   * Basic Form
   *
   */

  // Basic Form Data
  setBasicFormData(key: BasicFormKeys, value: any): void {
    this.basicFormData[key] = value;
  }

  getBasicFormData(key: BasicFormKeys): any {
    return this.basicFormData[key];
  }

  // Basic Form Status
  setBasicFormStatus(key: BasicFormKeys, status: boolean): void {
    if (this.basicFormValid[key] !== status) {
      this.basicFormValid[key] = status;

      this.companyStatusChangeEvent.emit();
    }
  }

  getBasicFormStatus(key: BasicFormKeys): boolean {
    return this.basicFormValid[key];
  }

  /*
   *
   * Person Form
   *
   */

  // Person Form Data
  setPersonFormData(key: PersonFormKeys, value: any): void {
    this.personFormData[key] = value;
  }

  getPersonFormData(key: PersonFormKeys): any {
    return this.personFormData[key];
  }

  // Person Form Status
  setPersonFormStatus(key: PersonFormKeys, status: boolean): void {
    if (this.personFormValid[key] !== status) {
      this.personFormValid[key] = status;

      this.personStatusChangeEvent.emit();
    }
  }

  getPersonFormStatus(key: PersonFormKeys): boolean {
    return this.personFormValid[key];
  }

  /*
   *
   * Company Form
   *
   */

  // Company Form Data
  setCompanyFormData(key: CompanyFormKeys, value: any): void {
    this.companyFormData[key] = value;
  }

  getCompanyFormData(key: CompanyFormKeys): any {
    return this.companyFormData[key];
  }

  // Company Form Status
  setCompanyFormStatus(key: CompanyFormKeys, status: boolean): void {
    if (this.companyFormValid[key] !== status) {
      this.companyFormValid[key] = status;

      this.companyStatusChangeEvent.emit();
    }
  }

  getCompanyFormStatus(key: CompanyFormKeys): boolean {
    return this.companyFormValid[key];
  }

  // Fill Form

  async getPersonData() {
    await this.api.post({
      route: 'https://www.4devs.com.br/ferramentas_online.php',
      params: {
        acao: 'gerar_pessoa',
        sexo: 'H',
        pontuacao: 'S',
        idade: 23,
        cep_estado: 'SP',
        txt_qtde: 1,
        cep_cidade: 9636,
      },
      external: true,
    });
  }

  async updateUserStep(step: StepValues, substep?: Substep) {
    const user = this.authService.getUser();
    if (user) {
      this.api.patch({
        route: 'api/registration/trace_step/me/',
        body: { substep, step },
        token: true,
      });
    }
  }
}
