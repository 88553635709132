<div class="row" *ngIf="fundService.data">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">
            <div class="dash-details-card-body">
                <div class="row">
                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            PRAZO
                        </span>

                        <span class="dash-details-card-item-title">
                            {{getChoicesName('tax_classification_deadline_choices',
                            fundService.data.tax_classification_deadline)?.label
                            ?? '-'}}
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            FORMA DE TRIBUTAÇÃO
                        </span>

                        <span class="dash-details-card-item-title">
                            {{getChoicesName('tax_classification_tax_choices',
                            fundService.data.tax_classification_tax)?.label ??
                            '-'}}
                        </span>
                    </div>

                    <div class="col-4 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            CLASSIFICAÇÃO DOS RISCOS
                        </span>

                        <span class="dash-details-card-item-title">
                            {{fundService.data.risk_classification ?? '-'}}
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="16"></app-spacer>

                <div class="row">
                    <div class="col-4 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            DIREITOS CREDITÓRIOS REGISTRADOS
                        </span>

                        <span class="dash-details-card-item-title">
                            {{fundService.data.credit_rights_will_be_granted ?
                            'Sim' : 'Não'}}
                        </span>
                    </div>

                    <div class="col-2 d-flex flex-column"
                        *ngIf="fundService.data.creditors_rights">
                        <span class="dash-details-card-item-subtitle">
                            TIPO DE DIREITOS CREDITÓRIOS
                        </span>

                        <span class="dash-details-card-item-title">
                            {{fromatCreditorsRight(fundService.data.creditors_rights)}}
                        </span>
                    </div>

                    <div class="col-4 d-flex flex-column"
                        *ngIf="fundService.data.analytical_description_of_credit_rights">
                        <span class="dash-details-card-item-subtitle">
                            DESCRIÇÃO ANALÍTICA DOS DIREITOS CREDITÓRIOS
                        </span>

                        <span class="dash-details-card-item-title">
                            {{fundService.data.analytical_description_of_credit_rights
                            }}
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="16"></app-spacer>

                <div class="row">
                    <div class="col-4 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            CESSÃO MAIOR QUE 400 RECEBÍVEIS POR TRIMESTRE
                        </span>

                        <span class="dash-details-card-item-title">
                            {{fundService.data.assignment_expectation_greater_than_400
                            ? 'Sim' : 'Não'}}
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="16"></app-spacer>

                <ng-container *ngIf="fundService.data.quota_fair_value">
                    <div class="row">
                        <div class="col-12">
                            <app-label class="font-weight-bold"
                                label="Emissão de cotas com entrega de ativos">
                            </app-label>
                        </div>
                    </div>
                    <app-spacer [bottom]="8"></app-spacer>

                    <div class="row">
                        <div class="col-4 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                PARÂMETRO PARA DEFINIÇÃO DO VALOR JUSTO DOS
                                ATIVOS
                            </span>

                            <span class="dash-details-card-item-title">
                                {{fundService.data.quota_fair_value |
                                currency:'BRL':true}}
                            </span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>