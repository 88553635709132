import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import fakerbr from 'faker-br';
import { MaskPipe } from 'ngx-mask';
import { distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';

const filter_dict = {
  search: 'search',
  roles: 'register__role__id',
  created_from: 'created_at__gte',
  created_to: 'created_at__lte',
  updated_from: 'updated_at__gte',
  updated_to: 'updated_at__lte',
};

@Component({
  selector: 'app-dashboard-provider',
  templateUrl: './dashboard-provider.component.html',
  styleUrls: ['./dashboard-provider.component.scss'],
})
export class DashboardProviderComponent implements OnInit {
  form = this.formBuilder.group({
    name: [null, [Validators.required]],
    role: [null, [Validators.required]],
    document: [null, [Validators.required, Utils.validateCpfCnpj]],
    email: [null, [Validators.required, Validators.email]],
    fund: [null, [Validators.required]],
  });

  filterForm = this.formBuilder.group({
    search: [null],
    roles: [null],
    created_from: [null],
    created_to: [null],
    updated_from: [null],
    updated_to: [null],
  });

  columns: TableColumnList<ExpiredAttachmentsProps>[] = [
    {
      name: 'Anexo',
      cell: (row) =>
        `
            <div class="dash-card-item-content">
                <div class="dash-card-item-content-title">
                ${row.label}
                </div>
            </div>
        `,
      size: 2,
    },
    {
      name: 'Representante',
      cell: (row) =>
        row.representative && row.representative.name
          ? row.representative.name
          : '',
    },
    {
      name: 'Data de vencimento',
      cell: (row) => row.end_date_due,
    },
  ];

  assignors: UserApprovalResponse[] = [];

  is_approver: boolean = false;
  applicable = 'assignor';

  filters: number = 0;

  loading = false;

  statistics = {
    homologated: 0,
    in_progress: 0,
    pending: 0,
  };

  filtered = false;

  pages: number[] = [];
  _page = 1;

  set page(value: number) {
    this._page = value;
    this.getUsers();
  }

  get page() {
    return this._page;
  }

  paginate = {
    current_page: 0,
    max_pages: 0,
    per_page: 0,
    total: 0,
    viewing: 0,
  };

  showExpiredModal = false;

  showFilterModal = false;

  roleOptions: SelectItem[] = [];

  expiredAttachmentsArr: ExpiredAttachmentsProps[] = [];

  newInvite = false;

  today = Utils.todayString();

  havePendentSigns = false;

  isConsultant = false;

  primaryRoleOptions: SelectItem[] = [];

  typeOptions: SelectItem[] = [
    { label: 'Pessoa física', value: 'PF' },
    { label: 'Pessoa jurídica', value: 'PJ' },
  ];

  roles: Role[] = [];

  requested_signs: CompanySign[] = [];

  constructor(
    private api: ApiService,
    private toast: ToastService,
    private router: Router,
    private formBuilder: FormBuilder,
    private signupService: SignupService,
    private authService: AuthService,
    private maskPipe: MaskPipe
  ) {}

  ngOnInit(): void {
    this.authService.userChange.subscribe(() => {
      this.setVariables();
      this.getRoles();
      this.getData();
      this.setData();
      this.setAgents();

      if (
        this.authService.user.active_register.register.role.applicable ===
        'representative'
      ) {
        this.getRequestedSigns();
      }
    });

    this.setVariables();
    this.getRoles();
    this.getData();
    this.setData();
    this.setAgents();

    if (
      this.authService.user.active_register.register.role.applicable ===
      'representative'
    ) {
      this.getRequestedSigns();
    }

    this.filterForm.controls.created_from.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((values) => {
        const date = new Date(values);
        if (date.toString() === 'Invalid Date') {
          this.filterForm.controls.created_from.setValue(null);
          this.filterForm.controls.created_from.setValidators([]);
        } else {
          this.filterForm.controls.created_from.setValidators([
            Validators.required,
            (control: FormControl) => {
              const selectedDate = new Date(control.value);
              selectedDate.setHours(selectedDate.getHours() + 3);
              const today = new Date();
              today.setHours(0, 0, 0, 0);
              return selectedDate <= today ? null : { max: true };
            },
          ]);
        }

        this.filterForm.controls.created_from.updateValueAndValidity();
      });

    this.filterForm.controls.updated_from.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((values) => {
        const date = new Date(values);
        if (date.toString() === 'Invalid Date') {
          this.filterForm.controls.updated_from.setValue(null);
          this.filterForm.controls.updated_from.setValidators([]);
        } else {
          this.filterForm.controls.updated_from.setValidators([
            Validators.required,
            (control: FormControl) => {
              const selectedDate = new Date(control.value);
              selectedDate.setHours(selectedDate.getHours() + 3);
              const today = new Date();
              today.setHours(0, 0, 0, 0);
              return selectedDate <= today ? null : { max: true };
            },
          ]);
        }

        this.filterForm.controls.updated_from.updateValueAndValidity();
      });

    this.filterForm.controls.created_to.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((values) => {
        const date = new Date(values);
        if (date.toString() === 'Invalid Date') {
          this.filterForm.controls.created_to.setValue(null);
          this.filterForm.controls.created_to.setValidators([]);
        } else {
          this.filterForm.controls.created_to.setValidators([
            Validators.required,
            (control: FormControl) => {
              const selectedDate = new Date(control.value);
              selectedDate.setHours(selectedDate.getHours() + 3);
              const today = new Date();
              today.setHours(0, 0, 0, 0);
              return selectedDate >= today ? null : { min: true };
            },
          ]);
        }

        this.filterForm.controls.created_to.updateValueAndValidity();
      });

    this.filterForm.controls.updated_to.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((values) => {
        const date = new Date(values);
        if (date.toString() === 'Invalid Date') {
          this.filterForm.controls.updated_to.setValue(null);
          this.filterForm.controls.updated_to.setValidators([]);
        } else {
          this.filterForm.controls.updated_to.setValidators([
            Validators.required,
            (control: FormControl) => {
              const selectedDate = new Date(control.value);
              selectedDate.setHours(selectedDate.getHours() + 3);
              const today = new Date();
              today.setHours(0, 0, 0, 0);
              return selectedDate >= today ? null : { min: true };
            },
          ]);
        }

        this.filterForm.controls.updated_from.updateValueAndValidity();
      });
  }

  redirectToDetails() {
    const applicable =
      this.authService.user.active_register.register.role.applicable;
    const id = this.authService.user.active_register.register.id;

    this.router.navigate([`/app/details/${applicable}/`, id]);
  }

  setVariables() {
    if (this.authService.user.active_register) {
      this.is_approver =
        this.authService.user.active_register.register.is_approver;
    }

    this.applicable =
      this.authService.user.active_register?.register.role.applicable;

    if (
      (this.applicable === 'assignor' ||
        this.applicable === 'provider' ||
        this.applicable === 'shareholder') &&
      !this.is_approver
    ) {
      this.redirectToDetails();
    }

    this.isConsultant =
      this.authService.user.active_register.register.role.applicable ===
        'provider' &&
      this.authService.user.active_register.register.agent.some(
        (item) => item.name === 'Consultor'
      );
  }

  async getRequestedSigns() {
    try {
      const { data } = await this.api.get<ApiResponse<CompanySign[]>>({
        route: 'api/registration/company_sign/',
        token: true,
      });

      this.requested_signs = data;
    } catch (error) {
      console.error(error);
    }
  }

  checkAttachmentsDueDate(userAttachments, representatives) {
    let expiredAttachments: ExpiredAttachmentsProps[] = [];

    userAttachments.forEach((attachment) => {
      if (attachment.date_due && attachment.attachment_date_eue) {
        const endDueDate = new Date(attachment.end_date_due);
        endDueDate.setHours(endDueDate.getHours() + 3);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (endDueDate < today) {
          expiredAttachments.push({
            label: attachment.type.name,
            end_date_due: attachment.end_date_due
              .split('-')
              .reverse()
              .join('/'),
          });
        }
      }
    });

    representatives.forEach((representative) => {
      representative.attachments.forEach((attachment) => {
        if (attachment.date_due && attachment.attachment_date_eue) {
          const endDueDate = new Date(attachment.end_date_due);
          endDueDate.setHours(endDueDate.getHours() + 3);
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          if (endDueDate < today) {
            expiredAttachments.push({
              label: attachment.type.name,
              end_date_due: attachment.end_date_due
                .split('-')
                .reverse()
                .join('/'),
              representative: {
                name:
                  representative.user.first_name +
                  ' ' +
                  representative.user.last_name,
                id: representative.id,
              },
            });
          }
        }
      });
    });

    this.expiredAttachmentsArr = expiredAttachments;
  }

  resetForm() {
    this.filterForm.reset();
    this.filterForm.controls.updated_from.setValidators([]);
    this.filterForm.controls.updated_to.setValidators([]);
    this.filterForm.controls.created_from.setValidators([]);
    this.filterForm.controls.created_to.setValidators([]);

    this.filterForm.controls.updated_from.updateValueAndValidity();
    this.filterForm.controls.updated_to.updateValueAndValidity();
    this.filterForm.controls.created_from.updateValueAndValidity();
    this.filterForm.controls.created_to.updateValueAndValidity();

    this.filters = 0;

    // this.showFilterModal = false;
  }
  async filterUsers() {
    this.filters = 0;

    try {
      const values = this.filterForm.value;

      this.filtered = Object.entries(values).some(([key, value]: any) => {
        return value !== null && value !== '';
      });

      const filters = {};

      Object.entries(values).forEach(([key, value]: any) => {
        if (value !== null && value !== '') {
          filters[key] = value;
        }
      });

      const { data } = await this.api.get<ApiResponse<UserApprovalResponse[]>>({
        route: 'api/approvals/user_approval/',
        token: true,
        params: filters,
      });

      this.setListData(data);
      this.showFilterModal = false;
    } catch (error) {
      console.error(error);
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar os dados de cedente.'
      );
    }
  }

  closeModal() {
    this.newInvite = false;
    this.showFilterModal = false;
    // this.form.reset();
  }

  setListData(data) {
    const register = this.authService.user?.active_register
      ? this.authService.user.active_register.register
      : this.authService.user;

    const pending = data
      .filter((item) => {
        if (item.current_department) {
          if (
            item.current_department.type === 'department' &&
            item.current_department.department.id === register.department?.id
          ) {
            return true;
          }

          if (item.current_department.type === 'agent') {
            return register.agent.some(
              (_agent) => item.current_department.agent.id === _agent.id
            );
          }
        }

        return false;
      })
      .sort((a, b) => {
        /* Sort by approval created_at */
        if (a.created_at < b.created_at) {
          return -1;
        }
        if (a.created_at > b.created_at) {
          return 1;
        }
        return 0;
      });

    this.statistics = {
      in_progress: data.filter((item) => item.step !== 0).length,
      pending: pending.length,
      homologated: data.filter((item) => item.completed).length,
    };

    const filteredData = data
      .filter((assignor) => {
        if (assignor.register) {
          return !assignor.register.is_simplified;
        } else {
          return true;
        }
      })
      .filter((assignor) => !(assignor.completed && !assignor.is_approved))
      .filter((assignor) => !assignor.can_sign);

    this.assignors = filteredData;
  }

  async getUsers() {
    try {
      const { data } = await this.api.get<ApiResponse<UserApprovalResponse[]>>({
        route: 'api/approvals/user_approval/',

        token: true,
      });

      this.setListData(data);
    } catch (error) {
      console.error(error);
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar os dados de cedente.'
      );
    }
  }

  toggleFilterModal() {
    this.showFilterModal = !this.showFilterModal;
  }

  getInitials(item: UserApprovalResponse) {
    if (!item.register) {
      return '-';
    }

    if (item.register.type.toUpperCase() === 'PF') {
      const full_name = item.register.person.full_name;

      return Utils.getInitials(full_name);
    }

    if (item.register.type.toUpperCase() === 'PJ') {
      const corporate_name = item.register.company.corporate_name;

      return Utils.getInitials(corporate_name);
    }

    return '';
  }

  getDisplayName(item: UserApprovalResponse) {
    if (item.register.type.toUpperCase() === 'PF') {
      return item.register.person.full_name;
    }

    if (item.register.type.toUpperCase() === 'PJ') {
      return item.register.company.corporate_name;
    }

    return '';
  }

  formatItemName(item: UserApprovalResponse) {
    const name = item.register.role.applicable.toUpperCase();

    switch (name) {
      case 'ASSIGNOR':
        return 'Cedente';
      case 'SHAREHOLDER':
        return 'Cotista';
      case 'PROVIDER':
        let providerLabel =
          item.register.agent.length > 0
            ? item.register.agent.map((_) => _.name).join(', ')
            : 'Prestador de serviço';
        return providerLabel;

      default:
        return 'Cedente';
    }
  }

  getStatus(item: UserApprovalResponse) {
    if (item.register.approval[0].completed) {
      if (item.register.approval[0].is_approved) {
        return 'Aprovado internamente';
      } else {
        return 'Cadastro rejeitado';
      }
    } else {
      if (item.register.approval[0].current_department !== null) {
        return item.register.approval[0].current_department?.type ===
          'department'
          ? item.register.approval[0].current_department.department.name
          : item.register.approval[0].current_department.agent?.name ??
              'Não definido';
      } else if (item.register.approval[0].step === 0) {
        return 'Edição';
      } else {
        return 'Departamento não definido';
      }
    }
  }

  getStatusInfo(item: UserApprovalResponse) {
    if (item.register.approval[0].completed) {
      if (item.register.approval[0].is_approved) {
        return 'Integração ao fundo';
      } else {
        if (item.register.approval[0].current_department !== null) {
          return item.register.approval[0].current_department.type ===
            'department'
            ? item.register.approval[0].current_department.department.name
            : item.register.approval[0].current_department.agent.name;
        } else if (item.register.approval[0].step === 0) {
          return 'Edição';
        } else {
          return 'Departamento não definido';
        }
      }
    } else if (item.register.approval[0].step === 0) {
      return 'Aguardando representantes';
    } else {
      return `Aguardando aprovação (${item.register.approval[0].step}/${item.register.approval[0].queue.departments.length})`;
    }
  }

  renderLink(applicable, id) {
    return [`/app/details/${applicable}/`, id];
  }

  nextPage() {
    if (this.paginate.current_page < this.paginate.max_pages) {
      this.page++;
    }
  }

  prevPage() {
    if (this.paginate.current_page > 1) {
      this.page--;
    }
  }

  changePage(page: number) {
    this.page = page;
  }

  async getData() {
    this.loading = true;
    await Promise.all([this.getUsers()]);
    this.loading = false;
  }

  setData() {
    this.signupService.fillFormEvent.subscribe(() => {
      this.form.patchValue({
        name: fakerbr.name.findName(),
        document: this.maskPipe.transform(
          fakerbr.br.cnpj(),
          '00.000.000/0000-00'
        ),
        email: fakerbr.internet.email(),
        fund: fakerbr.random.number({ min: 1, max: 3 }),
      });
    });
  }

  async setAgents() {
    try {
      const res = await this.api.get({
        route: 'api/registration/agent/',
        token: true,
      });

      this.roleOptions = res.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    } catch (error) {
      console.warn(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  redirectTo() {
    return this.router.navigateByUrl(
      `/app/status/pj?company=${this.authService.user.id}`
    );
  }

  triggerModal() {
    this.showExpiredModal = !this.showExpiredModal;
  }

  async getRoles() {
    const res = await this.api.get<ApiResponse<Role[]>>({
      route: 'role/',
    });

    const { data } = res;

    this.roles = data;

    const userRoles = data
      .filter(
        (_item) =>
          _item.applicable === 'assignor' || _item.applicable === 'provider'
      )
      .sort((a, b) => {
        if (a.applicable_display < b.applicable_display) {
          return -1;
        }
        if (a.applicable_display > b.applicable_display) {
          return 1;
        }
        return 0;
      })
      .map((item) => {
        return {
          label: `${item.applicable_display} - ${item.name}`,
          value: String(item.id),
        };
      });

    this.primaryRoleOptions = userRoles;
  }

  getSignLink(item: CompanySign) {
    this.router.navigateByUrl('/app/status/pj?company=' + item.company.id);
  }
}
